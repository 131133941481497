/**
 * Created by evgeny on 18.06.2024
 */

var SwitchableView = function (switchable, unitView) {
    this.switchable = switchable;
    this.unitView = unitView;

    this.showChoice(this.switchable.choice);
    switchable.onShowChoice = this.showChoice.bind(this);
    switchable.onAnimateChoice = this.animateChoice.bind(this);
    switchable.onAnimateHover = this.animateHover.bind(this);
    switchable.onStopAnimateHover = this.stopAnimateHover.bind(this);
};

SwitchableView.prototype.animateChoice = function (choice) {
    this.unitView.sprite.setAnimationAndIdleAfter("switch" + choice, "choice" + choice);
};

SwitchableView.prototype.animateHover = function () {
    this.hoverAnimation = this.unitView.sprite.runAction(new cc.RepeatForever(
        new cc.Sequence(
            new cc.MoveBy(0.7, 0, 10),
            new cc.MoveBy(0.7, 0, -10)
        )
    ));

    cleverapps.UI.toggleFlickering(this.unitView.sprite.spine, {
        flickSpeed: 0.7
    });
};

SwitchableView.prototype.stopAnimateHover = function () {
    if (this.hoverAnimation) {
        this.unitView.sprite.stopAction(this.hoverAnimation);
        this.unitView.sprite.setColor(new cc.Color(255, 255, 255));
        delete this.hoverAnimation;

        if (this.unitView.sprite.spine.normalShader) {
            cleverapps.UI.toggleFlickering(this.unitView.sprite.spine);
        }
    }
};

SwitchableView.prototype.showChoice = function (choice) {
    this.unitView.sprite.setAnimation(0, "choice" + choice, true);
};