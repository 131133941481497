/**
 * Created by Aleksandr on 30.07.2024
 */

var TravelMapItemView = cc.Node.extend({
    ctor: function (options) {
        this._super();

        var styles = cleverapps.styles.TravelMapItemView;

        this.setContentSize(styles);
        var building = this.building = new cleverapps.Spine(bundles.travel_map.jsons[options.name + "_json"]);
        building.setPositionRound(styles.building);
        building.setAnimation(0, options.state);
        this.addChild(building);

        var titleBg = this.titleBg = new cc.Scale9Sprite(options.state === TravelMapItemView.STATES.NEXT ? bundles.travel_map.frames.title_bg_blocked : bundles.travel_map.frames.title_bg);
        titleBg.setPositionRound(styles.titleBg);
        titleBg.setContentSize(styles.titleBg.size);
        this.addChild(titleBg);

        var title = this.title = cleverapps.UI.generateTTFText("TravelMapWindow.item." + options.name, cleverapps.styles.FONTS.TRAVEL_MAP_ITEM_NAME_TEXT);

        var stateView;

        switch (options.state) {
            case TravelMapItemView.STATES.PASSED:
                stateView = this.addCheckmark();
                break;
            case TravelMapItemView.STATES.CURRENT:
                this.addProgressBar();
                break;
            case TravelMapItemView.STATES.NEXT:
                stateView = this.addLock();
                break;
        }

        cleverapps.UI.fitToBox(title, styles.titleSize);
        var titleLayout = new cleverapps.Layout([title, stateView], {
            direction: cleverapps.UI.HORIZONTAL,
            margin: styles.titleLayout.margin,
            padding: styles.padding
        });

        titleLayout.setPositionRound(styles.titleLayout.pos);
        titleBg.addChild(titleLayout);
    },

    addCheckmark: function () {
        return new cc.Sprite(bundles.travel_map.frames.checkmark_png);
    },

    addLock: function () {
        var lock = this.lock = new cleverapps.Spine(bundles.travel_map.jsons.lock_json);
        lock.setAnimation(0, "closed");

        return lock;
    },

    addProgressBar: function () {
        var styles = cleverapps.styles.TravelMapItemView;

        var progressBar = this.progressBar = new PrizeBarComponent({
            progressBar: {
                width: styles.bar.width,
                progressImage: styles.bar.type.progress,
                progressBgImage: styles.bar.type.background,
                barText: {
                    text: " "
                }
            }
        });

        progressBar.setPositionRound(styles.bar);

        this.addChild(progressBar);
    },

    setPassed: function () {
        this.building.setAnimation(0, "passed");
        this.progressBar.removeFromParent();
        this.addCheckmark();
    },

    setCurrent: function () {
        this.lock.setAnimation(0, "open");
        this.lock.setCompleteListenerOnce(function () {
            this.building.setAnimation(0, "current");
            this.addProgressBar();
        }.bind(this));
    }
});

TravelMapItemView.STATES = {
    PASSED: "passed",
    CURRENT: "current",
    NEXT: "next"
};

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    TRAVEL_MAP_ITEM_NAME_TEXT: {
        size: 40,
        color: cleverapps.styles.COLORS.LIGHT_TEXT_COLOR,
        stroke: {
            color: cleverapps.styles.COLORS.LIGHT_TEXT_STROKE_COLOR,
            size: 3
        },
        shadow: {
            color: cleverapps.styles.COLORS.LIGHT_TEXT_SHADOW_COLOR,
            direction: cc.size(2, -2),
            blur: 3
        }
    },

    TRAVEL_MAP_ITEM_PROGRESS_BAR_TEXT: {
        size: 22,
        color: cleverapps.styles.COLORS.WHITE,
        stroke: cleverapps.styles.DECORATORS.IMAGE_FONT_STROKE
    }
});

cleverapps.styles.TravelMapItemView = {
    width: 300,
    height: 350,

    building: {
        x: { align: "center" },
        y: { align: "center" }
    },

    status: {
        icon: {
            x: { align: "center" },
            y: { align: "center" }
        }
    },

    titleBg: {
        x: { align: "center" },
        y: { align: "bottom", dy: -40 },
        size: {
            width: 370,
            height: 70
        }
    },

    titleSize: {
        width: 266,
        height: 64
    },

    titleLayout: {
        padding: 0,
        margin: 10,
        pos: {
            x: { align: "center" },
            y: { align: "center", dy: 3 }
        }
    },

    bar: {
        width: 220,
        x: { align: "center" },
        y: { align: "bottom", dy: -110 },
        type: {
            progress: bundles.travel_map.frames.bar_progress,
            background: bundles.travel_map.frames.bar_background
        },
        text: {
            font: cleverapps.styles.FONTS.TRAVEL_MAP_ITEM_PROGRESS_BAR_TEXT,
            text: "%from%%",
            dy: 2
        },
        reward: {
            x: { align: "right", dx: 40 },
            y: { align: "center", dy: 10 }
        }
    }
};