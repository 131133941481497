/**
 * Created by mac on 12/23/20
 */

var GeneratorComponent = function (unit, options) {
    UnitComponent.call(this, unit);

    this.levels = unit.getData().generator.levels;

    this.level = options.level || 0;
    this.changedLevels = options.changedLevels || 0;

    if (this.levels && this.level >= this.levels) {
        setTimeout(this._gotoNextStage.bind(this), 0);
    }
};

GeneratorComponent.prototype = Object.create(UnitComponent.prototype);
GeneratorComponent.prototype.constructor = GeneratorComponent;

GeneratorComponent.prototype.beforeUpdateStage = function () {
    if (this.view) {
        this.view.beforeUpdateStage();
    } 
};

GeneratorComponent.prototype.afterUpdateStage = function () {
    if (this.view) {
        this.view.afterUpdateStage();
    }
};

GeneratorComponent.prototype.save = function (data) {
    data.level = this.level;
    data.changedLevels = this.changedLevels;
};

GeneratorComponent.prototype.destructor = function () {
    if (this.timeout) {
        clearTimeout(this.timeout);
        delete this.timeout;
    }
};

GeneratorComponent.prototype._gotoNextStage = function () {
    this.beforeUpdateStage();

    this.timeout = setTimeout(function () {
        delete this.timeout;
        var newUnit = {
            code: this.unit.code,
            stage: this.unit.stage + 1,
            level: 0,
            changedLevels: this.changedLevels
        };
        newUnit = Game.currentGame.replace(this.unit, newUnit, true);
        newUnit.findComponent(GeneratorComponent).afterUpdateStage();
    }.bind(this), 1000);
};

GeneratorComponent.prototype.gotoNextLevel = function () {
    if (this.levels && this.level + 1 >= this.levels) {
        this._gotoNextStage();
        return;
    }

    this.timeout = setTimeout(function () {
        delete this.timeout;
        var newUnit = {
            code: this.unit.code,
            stage: this.unit.stage,
            level: this.levels ? this.level + 1 : 0,
            changedLevels: this.changedLevels + 1
        };

        Game.currentGame.replace(this.unit, newUnit, true);
    }.bind(this), this.levels ? 600 : 0);
};

GeneratorComponent.prototype.getGeneratorInfo = function () {
    if (this.levels) {
        return {
            progress: this.level + 1,
            total: this.levels
        };
    }
};