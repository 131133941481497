/**
 * Created by andrey on 19.11.19.
 */

var HomefixScene = MainScene.extend({
    ctor: function (options) {
        this._super(options);
    },

    onSceneLoaded: function () {
        this._super();

        var homefix = new Homefix(bundles["location_" + cleverapps.home.level].jsons.map_json.getJson());

        var map = this.map = new Map2dView(homefix.map);

        this.scroll = new cleverapps.UI.ScrollView(map, {
            childrenVisibility: cleverapps.UI.ScrollView.CHILDREN_VISIBILITY_FULLCHECK,
            childrenVisibilityPadding: Math.max(cleverapps.styles.Map2dView.cell.width, cleverapps.styles.Map2dView.cell.height),
            scrollBarEnabled: false,
            zoom: cleverapps.config.demoMode ? { minZoom: 0.2 } : true,
            outOfBoundaryScale: cleverapps.skins.getSlot("outOfBoundaryScale"),
            inertialScrollFactor: 0.2
        });

        this.scroll.setClippingEnabled(false);
        this.scroll.isMapScroll = true;
        this.scroll.debugId = "Scroll";
        map.setScroll(this.scroll);

        this.controller = new HomeController(this.map);
        this.controller.onEnter();

        homefix.map.getScrollZoom = function () {
            return this.scroll.zoomHandler.normalizeZoom(this.scroll.zoom);
        }.bind(this);

        homefix.isScrollProcessing = this.createListener(function () {
            return this.scroll.scrollHandler.isProcessing();
        }.bind(this));

        var bgSize = cleverapps.resolution.getBgSize();
        this.scroll.setContentSize(bgSize.width, bgSize.height);
        this.scroll.setPositionRound(this.width / 2, this.height - bgSize.height / 2);
        this.scroll.setLocalZOrder(-1);
        this.addChild(this.scroll);

        this.scroll.onUpdatePositionListener = this.scroll.onUpdateZoomListener = this.createListener(function () {
            homefix.onUpdateScroll();
            this.applyParallaxChanges();
        }.bind(this));

        this.scroll.updateVisibility();

        var scrollCell = homefix.map.scrollCell || homefix.map.getMapCenterCell();
        scrollCell = this.map.getCell(scrollCell.x, scrollCell.y);
        scrollCell = scrollCell ? this.scroll.normalizePoint(this.scroll.targetToPoint(scrollCell)) : this.scroll.getContainerPosition();

        this.scroll.scrollToPoint(scrollCell);

        var zoom = homefix.getBasicZoom();
        this.setInitialZoom(cleverapps.silentIntro ? zoom : 0.8 * zoom);

        var orangery = Map2d.currentMap.orangery;
        if (orangery && !cleverapps.gameModes.noControls && !cleverapps.config.wysiwygMode) {
            var orangeryView = new OrangeryView(orangery);
            this.addChild(orangeryView);

            cleverapps.meta.registerControl(orangery.getControlName(), new HidingNode(orangeryView, cleverapps.UI.VERTICAL));
        }

        this.addPlayButton();

        this.addTasksIcon();
        this.map.animateNewTask = this.createListener(this.animateNewTask.bind(this));

        this.runAction(new cc.RepeatForever(new cc.Sequence(
            new cc.DelayTime(5),
            new cc.PlaySound(bundles.main.urls.city_loop_effect),
            new cc.DelayTime(5)
        )));

        cleverapps.meta.registerControl("finger_control", this.createListener(function (visible) {
            if (visible) {
                this.createFinger();
            }
        }.bind(this)));
    },

    setupChildren: function () {
        if (!this.isAllLoaded) {
            return;
        }
        this._super();

        var bgSize = cleverapps.resolution.getBgSize();
        this.scroll.setContentSize(bgSize.width, bgSize.height);
        this.scroll.setPositionRound(this.width / 2, this.height - bgSize.height / 2);
    },

    onExit: function () {
        this._super();
        this.controller.clear();
    },

    setInitialZoom: function (zoom) {
        this.scroll.zoomHandler.setZoom(zoom, true);
    },

    addPlayButton: function () {
        var playButton = this.playButton = new PlayButtonView({
            isSmall: true,
            hard: true
        });
        this.addChild(playButton);

        cleverapps.meta.registerControl("play_button", new HidingNode(playButton, cleverapps.UI.VERTICAL));
    },

    addTasksIcon: function () {
        var tasksIcon = new HomeTasksIcon(this);
        this.addChild(tasksIcon);

        this.tasksIcon = new HidingNode(tasksIcon, cleverapps.UI.VERTICAL);
        cleverapps.meta.registerControl("hometasks_icon", this.tasksIcon);
    },

    createFinger: function () {
        FingerView.hintClick(cleverapps.home.getFurnitureToUpgrade() ? this.tasksIcon : this.playButton, {
            scale: cleverapps.styles.HomefixScene.finger.scale
        });
    },

    animateNewTask: function (furniture) {
        var newTask = new NewTaskAnimation(furniture, this);
        this.addChild(newTask);
        newTask.setupChildren = function () {
            newTask.setPositionRound(cleverapps.styles.HomefixScene.newTask);
        };
        newTask.setupChildren();
    },

    playIntroActions: function (f) {
        this.controller.showUp(f);
    },

    applyParallaxChanges: function () {
        var styles = this.getBackgroundStyles();

        if (!styles.parallax || !this.background) {
            return;
        }

        var scale = this.scroll.zoom - this.scroll.zoomHandler.minZoom;
        var safePadding = cleverapps.resolution.getSafePadding();
        var normalizedScale = Math.max(1.1 * this.height / this.background.height, 1.25 + HomefixScene.PARALLAX_FACTOR * scale) + 0.001 * (safePadding.top + safePadding.bottom);
        this.background.setScale(normalizedScale);

        var scroll = this.scroll.getCurrentScroll();
        var normalizedScroll = {
            x: 2 * (scroll.x - 0.5),
            y: 2 * (scroll.y - 0.5)
        };

        var x = this.width / 2 + HomefixScene.PARALLAX_FACTOR * this.width * normalizedScroll.x * (1 + scale);
        var y = this.height / 2 + HomefixScene.PARALLAX_FACTOR * this.height * normalizedScroll.y * (1 + scale);

        if (styles.parallax.hideHorizon) {
            x = this.width * (0.5 + 0.1 * normalizedScroll.x);
            y = this.height * (1.2 + 0.9 * normalizedScroll.y);
        }

        this.background.setPositionRound(x - safePadding.left, y - safePadding.bottom);
    },

    getBackgroundStyles: function () {
        return {
            movableAnimation: bundles.map_background.jsons.map_background_json,
            parallax: {
                hideHorizon: true
            }
        };
    },

    getPreferredBundles: function () {
        return ["main"];
    },

    cachedBundles: function () {
        return ["homefix_windows"];
    },

    listBundles: function () {
        var level = cleverapps.home.level;

        var result = this._super();

        result.push("location_" + level);
        result.push(Map2d.getTilesTexture({ tiles: level }));
        result.push(Map2d.getUnitsTexture({ skins: level }));

        return result;
    }
});

HomefixScene.PARALLAX_FACTOR = 1;

cleverapps.styles.HomefixScene = {
    mergeBonus: {
        x: 0,
        y: 30
    },
    newTask: {
        x: { align: "right", dx: -50 },
        y: { align: "top", dy: -50 }
    },

    finger: {
        scale: {
            x: -0.7,
            y: 0.7
        }
    }
};
