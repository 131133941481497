/**
 * Created by andrey on 26.12.2020.
 */

var EditorFogTileView = FogTileView.extend({
    ctor: function () {
        this._super.apply(this, arguments);

        this.setCascadeColorEnabled(true);
        this.createTileBorders();
    },

    removeFromParent: function (cleanup) {
        this._super(cleanup);

        this.removeHead();
        this.removeBalloonParentDot();
    },

    getStyles: function () {
        return cleverapps.styles.FogTileView.medieval;
    },

    createTileBorders: function () {
        var y = this.fogTile.x;
        var x = this.fogTile.y;
        var layer = Map2d.currentMap.layers[Map2d.LAYER_FOG];

        if (layer[x][y + 1] && layer[x][y].fogBlock.id !== layer[x][y + 1].fogBlock.id) {
            this.drawBorder(210, false);
        }
        if (layer[x][y - 1] && layer[x][y].fogBlock.id !== layer[x][y - 1].fogBlock.id) {
            this.drawBorder(30, true);
        }
        if (layer[x + 1][y] && layer[x][y].fogBlock.id !== layer[x + 1][y].fogBlock.id) {
            this.drawBorder(150, false);
        }
        if (layer[x - 1][y] && layer[x][y].fogBlock.id !== layer[x - 1][y].fogBlock.id) {
            this.drawBorder(330, true);
        }
    },

    drawBorder: function (angle, position) {
        var sprite = cleverapps.UI.createScale9Sprite(bundles.buttons.frames.red_button, cleverapps.UI.Scale9Rect.TwoPixelY);
        var width = this.getContentSize().width;
        var height = this.getContentSize().height;
        var size = Math.sqrt(height * height + width * width) / 1.95;

        sprite.setAnchorPoint(0, 0);
        sprite.setContentSize2(size, 4);
        sprite.setRotation(angle);

        if (position) {
            sprite.setPositionRound(0, height / 2);
        } else {
            sprite.setPositionRound(width + 5, height / 2 + 5);
        }

        sprite.setColor(new cc.Color(255, 0, 0, 255));
        this.addChild(sprite);
    },

    onUpdateState: function () {
        this._super.apply(this, arguments);

        this.sprite.setOpacity(140);

        var color = cc.color(255, 255, 255, 255);
        var block = this.fogTile.fogBlock;
        var selected = Editor.currentEditor.map.fogs.getSelectedCode() === block.id;

        if (selected) {
            color = cc.color(0, 255, 0, 255);
        }

        if (this.fogTile.isHead && selected) {
            color = cc.color(100, 100, 100, 255);
        }

        if (block.balloonTile === this.fogTile && selected && !this.balloonParentDot) {
            this.balloonParentDot = new cc.Sprite(bundles.editor.frames.cell_center_png);
            this.balloonParentDot.setColor(cc.color(0, 0, 255, 255));
            this.balloonParentDot.setScale(2);

            var mapView = Map2d.currentMap.getView();
            mapView.alignViewInIsometricGrid(this.fogTile.x, this.fogTile.y, this.balloonParentDot);
            mapView.cloudsAbove.addChild(this.balloonParentDot);
        }

        if (block.balloonTile === this.fogTile && !selected) {
            this.sprite.setOpacity(0);
        }

        this.sprite.setColor(color);

        if (selected && !this.balloon && this.fogTile.balloon) {
            this.addBalloon();
            this.balloon.show();
        }

        this.addHead();
    },

    addHead: function () {
        if (this.head || !this.fogTile.isHead || !this.fogTile.fogBlock.editorHead) {
            return;
        }

        var mapView = Map2d.currentMap.getView();
        var head = this.head = new EditorFogHeadView(this.fogTile.fogBlock.editorHead);
        head.setPositionRound(mapView.alignInIsometricGrid(this.fogTile.x, this.fogTile.y));
        mapView.animations.addChild(head);
    },

    onOpen: function () {
        this._super();

        this.removeHead();
        this.removeBalloonParentDot();
    },

    removeHead: function () {
        if (this.head) {
            this.head.removeFromParent();
            delete this.head;
        }
    },

    removeBalloonParentDot: function () {
        [this.balloonParentDot].forEach(function (child) {
            if (child) {
                child.removeFromParent();
            }
        });

        delete this.balloonParentDot;
    }
});

cleverapps.styles.EditorFogTileView = {
    anchorX: 0.5,
    anchorY: 0.34,
    pointsOfInterest: {
        borders: true,
        center: true,
        animation: "idle_fog"
    },

    borders: {
        center: {
            x: { align: "center", dx: 1 },
            y: { align: "center", dy: -13 },
            zOrder: 0
        }
    }
};
