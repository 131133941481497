/**
 * Created by mac on 12/23/20
 */

var ResourceCollectibleView = function (component, unitView) {
    this.unitView = unitView;
};

ResourceCollectibleView.prototype.collectionAnimation = function (index, target, duration) {
    var map = Map2d.currentMap;
    var mapView = map.getView();
    var targetPosition = mapView.alignInIsometricGrid(target.x, target.y);

    Map2d.currentMap.getView().scroll.runAction(new cc.ScrollAction(cleverapps.clone(targetPosition), {
        skipFocusReport: true,
        duration: duration
    }).easing(cc.easeInOut(2)));

    var movingNode = cleverapps.scenes.getMovingNode(this.unitView);

    this.unitView.replaceParentSamePlace(movingNode, {
        keepScale: true
    });

    var distance = cc.pSub(targetPosition, this.unitView);

    this.unitView.runAction(new cc.Sequence(
        new cc.JumpAnimation(duration, this.unitView, distance, cleverapps.styles.UnitView.spawnHeight),
        new cc.RemoveSelf()
    ));
};