/**
 * Created by Aleksandr on 05.06.2024
 */

var TravelMapWindow = CleverappsWindow.extend({
    onWindowLoaded: function (options) {
        this.options = options || {};

        var styles = cleverapps.styles.TravelMapWindow;
        var content = this.createContent();

        this.currentLevel = this.options.moveNext ? cleverapps.home.level - 1 : cleverapps.home.level;

        this.options = {
            content: content,
            title: "TravelMapWindow.title",
            name: "travelmapwindow",
            foreground: bundles.travel_map.frames.travel_foreground_png,
            styles: styles
        };

        this._super(this.options);

        if (this.options.moveNext) {
            this.changeCurrent();
        }
    },

    setupChildren: function () {
        this._super();
        this.window.removeFromParent();
        this.onWindowLoaded(this.options);
        this.windowTitle.show();
    },

    createPage: function (startLevel, levels, backgroundBundle) {
        var styles = cleverapps.styles.TravelMapWindow;

        var bg = new cleverapps.Spine(backgroundBundle);
        bg.setAnimation(0, "animation");

        this.items = [];
        for (var i = startLevel; i < levels.length && i < startLevel + TravelMapWindow.LEVELS_COUNT_IN_PAGE; i++) {
            var state = TravelMapItemView.STATES.NEXT;

            if (i < this.currentLevel) {
                state = TravelMapItemView.STATES.PASSED;
            }
            if (i === this.currentLevel) {
                state = TravelMapItemView.STATES.CURRENT;
            }

            var item = new TravelMapItemView({
                name: levels[i],
                state: state
            });

            item.setPositionRound(styles.item[i % TravelMapWindow.LEVELS_COUNT_IN_PAGE]);
            bg.addChild(item);

            this.items.push(item);
        }

        return bg;
    },

    isHorizontal: function () {
        return cleverapps.resolution.mode === cleverapps.WideMode.HORIZONTAL;
    },

    createContent: function () {
        var styles = cleverapps.styles.TravelMapWindow;

        var levels = Home.LEVELS.concat(Home.LEVELS);
        var pages = [];

        var direction = this.isHorizontal() ? "" : "_vertical";

        for (var i = 0; i < TravelMapWindow.PAGES_COUNT; i++) {
            pages.push(this.createPage(
                i * TravelMapWindow.LEVELS_COUNT_IN_PAGE,
                levels,
                bundles.travel_map.jsons["tm_background_" + i + direction]
            ));
        }

        var contentLayer = new cleverapps.Layout(pages, {
            direction: this.isHorizontal() ? cleverapps.UI.HORIZONTAL : cleverapps.UI.VERTICAL
        });

        var scrollView = this.scrollView = new cleverapps.UI.ScrollView(contentLayer, {
            childrenVisibility: cleverapps.UI.ScrollView.CHILDREN_VISIBILITY_NONE,
            direction: this.isHorizontal() ? cleverapps.UI.ScrollView.DIR_HORIZONTAL : cleverapps.UI.ScrollView.Vertical,
            scrollBarEnabled: true,
            mouseScroll: true
        });

        scrollView.setContentSize(pages[0].width, pages[0].height);
        scrollView.setAnchorPoint(0.5, 0.5);
        scrollView.setPositionRound(styles.scrollView.position);
        scrollView.scrollToDefault();

        return scrollView;
    },

    changeCurrent: function () {
        var prev = this.items[cleverapps.home.level - 1];
        var current = this.items[cleverapps.home.level];

        var actions = [
            new cc.DelayTime(0.1),
            new cc.CallFunc(function () {
                prev.setPassed();
            }),
            new cc.DelayTime(0.5),
            new cc.CallFunc(function () {
                current.setCurrent();
            })
        ];

        this.runAction(new cc.Sequence(actions));
    },

    listBundles: function () {
        return ["travel_map"];
    }
});

TravelMapWindow.LEVELS_COUNT_IN_PAGE = 4;
TravelMapWindow.PAGES_COUNT = 2;

cleverapps.whenAllInitialized(function () {
    cleverapps.meta.getMainObject().on("moveNext", function (f) {
        new TravelMapWindow({ moveNext: true });
        cleverapps.meta.onceNoWindowsListener = f;
    });
});

cleverapps.styles.TravelMapWindow = {
    padding: {
        x: 26,
        top: 25,
        bottom: 35
    },
    scrollView: {
        position: {
            x: { align: "center" },
            y: { align: "center" }
        }
    },

    item: [
        [
            {
                x: { align: "center", dx: -196 },
                y: { align: "center", dy: 499 }
            },
            {
                x: { align: "center", dx: -196 },
                y: { align: "center", dy: 499 }
            },
            {
                x: { align: "center", dx: -606 },
                y: { align: "center", dy: -76 }
            }
        ],
        [
            {
                x: { align: "center", dx: 200 },
                y: { align: "center", dy: 200 }
            },
            {
                x: { align: "center", dx: 200 },
                y: { align: "center", dy: 200 }
            },
            {
                x: { align: "center", dx: -199 },
                y: { align: "center", dy: 187 }
            }
        ],
        [
            {
                x: { align: "center", dx: -190 },
                y: { align: "center", dy: -100 }
            },
            {
                x: { align: "center", dx: -190 },
                y: { align: "center", dy: -100 }
            },
            {
                x: { align: "center", dx: 217 },
                y: { align: "center", dy: -73 }
            }

        ],
        [
            {
                x: { align: "center", dx: 190 },
                y: { align: "center", dy: -436 }
            },
            {
                x: { align: "center", dx: 190 },
                y: { align: "center", dy: -436 }
            },
            {
                x: { align: "center", dx: 596 },
                y: { align: "center", dy: 187 }
            }
        ]
    ],

    path: {
        x: { align: "left", dx: -75 },
        y: { align: "top", dy: -75 },
        offsetY: -90,
        dx: 50,
        dy: 25
    }
};