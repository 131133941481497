/**
 * Created by evgeny on 07.06.2024
 */

var Homefix = function (location) {
    cleverapps.EventEmitter.call(this);

    this.level = location;
    this.level.families = Families.listExpeditionCodes(location.expedition);

    this.counter = new GameCounter();

    Game.currentGame = this;
    this.initialized = true;
    this.slot = cleverapps.GameSaver.getStoreSlot();

    this.map = new Map2d(location.map, {
        tiles: location.tiles,
        skins: location.skins,
        visibleBox: location.visibleBox,
        regions: location.regions,
        decorators: location.decorators,
        terrains: location.terrains,
        field: location.field
    });

    this.unitSaver = cleverapps.unitSavers.getInstance(this.slot);
    var saverUnits = this.unitSaver.loadUnits();
    if (!cleverapps.config.editorMode) {
        saverUnits.forEach(function (info) {
            this.map.loadUnit(info.x, info.y, info.data);
        }, this);
        this.map.initFogs();
    } else {
        Map2d.InsertDefaults(this.map, level.field);
    }

    cleverapps.config.gameInitialized = true;

    if (this.map.fogs.blocks.fog0) {
        this.map.fogs.blocks.fog0.open();
    }
};

Homefix.prototype = Object.create(cleverapps.EventEmitter.prototype);
Homefix.constructor = Homefix;

Homefix.prototype.getInfo = function () {

};

Homefix.prototype.playIntro = function (f) {
    f();
};

Homefix.prototype.getMode = function () {

};

Homefix.prototype.stop = function () {
    this.counter.turnOff();

    runCleaners(this);

    cleverapps.userStatus.destructor();
    cleverapps.menuBar.stopItems();
    cleverapps.exclamation.remove();

    this.map.stop();
};

Homefix.prototype.isMainGame = function () {
    return cleverapps.environment.isMainScene();
};

Homefix.prototype.onUpdateScroll = function () {
    if (this.initialized && this.showUpFinished) {
        this.map.onUpdateScroll();

        var questsGroupIcon = cleverapps.sideBar.findQuestsGroupIcon();
        if (questsGroupIcon) {
            questsGroupIcon.hideInfo();
        }
    }
};

Homefix.prototype.getBasicZoom = function () {
    var zoom = 1;

    if (this.map.zoom) {
        zoom = this.map.zoom;
    } else if (cleverapps.resolution.mode === cleverapps.WideMode.HORIZONTAL) {
        zoom = connector.info.isMobile ? 1.85 : 1.2;
    }

    if (cleverapps.skins.getSlot("basicZoom", zoom)) {
        zoom = cleverapps.skins.getSlot("basicZoom", zoom);
    }

    if (cleverapps.config.editorMode) {
        zoom = 1;
    }

    if (cleverapps.config.demoMode) {
        zoom = 0.4;
    }

    return zoom;
};

Homefix.prototype.onUnitAvailable = function (unit) {
    if (!cleverapps.unitsLibrary.isOpened(unit) && !cleverapps.unitsLibrary.isHidden(unit)) {
        this.map.fogs.wantsCalcFogStates = true;
    }

    cleverapps.unitsLibrary.openUnit(unit);

    if (unit.getData().feedableTarget || unit.findComponent(Feedable)) {
        Feedable.processFeedable();
    }

    if (this.map.fogs.wantsCalcFogStates) {
        this.counter.trigger();
    }
};