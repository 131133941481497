/**
 * Created by olga on 28.08.2024
 */

UnitSaver.prototype.initialize = function () {
    this.unitSyncer = new UnitSyncer(this, { furniture: true });

    this.mapSize = cc.size(80, 80);
    this.loadMap();
    cleverapps.dataLoader.save(this.getSizeKey(), this.mapSize);
};

UnitSaver.prototype.migrateUnits = function (units) {
    units = units.filter(function (unit) {
        return unit.data.code !== "intact" && Map2d.currentMap.fogs.getFakeUnit(unit.x, unit.y);
    });
    if (!cleverapps.home.oldItems) {
        return units;
    }
    Object.keys(cleverapps.home.oldItems).forEach(function (id) {
        var furniture = cleverapps.home.getFurnitureById(id);
        var fakeUnit = Map2d.currentMap.fogs.findFakeUnit({
            code: furniture.unitCode,
            type: "furniture",
            stage: 0
        });
        if (fakeUnit) {
            units = units.filter(function (unit) {
                return fakeUnit.code !== unit.code;
            });
            var item = cleverapps.home.oldItems[id];
            fakeUnit.stage = item.stage - 1 || 0;
            fakeUnit.choice = item.choice;
            units.push(fakeUnit);
        }
    });
    return units;
};
