/**
 * Created by r4zi4l on 21.01.2022
 */

var FieldComponentView = cc.Node.extend({
    ctor: function (field, unitView) {
        this._super();
        this.field = field;
        this.unitView = unitView;

        this.restoreState();
        cleverapps.bundleLoader.ensureSoundsLoaded([bundles.merge.urls.harvest_field_effect, bundles.merge.urls.plant_field_effect]);
    },

    restoreState: function (animated) {
        var state = this.field.getState();

        if (state === FieldComponent.STATE_EMPTY) {
            this.removeCrop(animated);
        } else {
            this.createCrop(animated);
        }

        if (state === FieldComponent.STATE_EARLY_GROWING) {
            this.createSign(animated);
        } else {
            this.removeSign(animated);
        }
    },

    createCrop: function (animated) {
        var state = this.field.getState();

        if (this.crop) {
            if (this.crop.state === state) {
                return;
            }
            this.removeCrop(animated);
        }

        var styles = cleverapps.styles.FieldComponentView;

        this.crop = new cc.Sprite(bundles["unit_" + this.field.unit.code].frames["view_" + this.field.recipe.prize.code + "_" + state]);
        this.crop.state = state;
        this.crop.setAnchorPoint(0.5, 0);
        this.crop.setPositionRound(styles.crop);
        this.unitView.addChild(this.crop);

        if (animated) {
            this.crop.setOpacity(0);
            this.crop.setScale(0);
            this.crop.runAction(new cc.Spawn(
                new cc.ScaleTo(0.05, 1, 1),
                new cc.FadeIn(0.05)
            ).easing(cc.easeBackOut()));
        }
    },

    removeCrop: function (animated) {
        if (!this.crop) {
            return;
        }

        if (!animated) {
            this.crop.removeFromParent();
            this.crop = undefined;
            return;
        }

        this.crop.runAction(new cc.Sequence(
            new cc.Spawn(
                new cc.ScaleTo(0.05, 0, 0),
                new cc.FadeOut(0.05)
            ),
            new cc.RemoveSelf()
        ));
        this.crop = undefined;
    },

    createSign: function (animated) {
        if (this.sign) {
            return;
        }

        var styles = cleverapps.styles.FieldComponentView;

        this.sign = new cc.Sprite(bundles["unit_" + this.field.unit.code].frames["view_" + this.field.recipe.prize.code + "_sign"]);
        this.sign.setAnchorPoint(0.5, 0);
        this.sign.setPositionRound(styles.sign);
        this.unitView.addChild(this.sign);

        if (animated) {
            this.sign.setOpacity(0);
            this.sign.runAction(new cc.Sequence(
                new cc.DelayTime(0.1),
                new cc.FadeIn(0.5)
            ));
        }
    },

    removeSign: function () {
        if (!this.sign) {
            return;
        }

        this.sign.removeFromParent();
        this.sign = undefined;
    },

    handleGrowthStart: function () {
        var styles = cleverapps.styles.FieldComponentView.seed;

        var seed = UnitView.getUnitImage(this.field.recipe.prize, {
            preferStatic: true,
            alignAnchorX: true
        });
        seed.setPositionRound(styles);
        this.unitView.addChild(seed);

        seed.setScale(0.8);
        seed.setOpacity(0);
        seed.runAction(new cc.Sequence(
            new cc.Spawn(
                new cc.FadeIn(0.10),
                new cc.ScaleTo(0.15, 1.3)
            ),
            new cc.DelayTime(0.1),
            new cc.ScaleTo(0.35, 0.3, 0.3)
        ));
        seed.runAction(new cc.Sequence(
            new cc.DelayTime(0.10),
            new cc.PlaySound(bundles.merge.urls.plant_field_effect),
            new cc.MoveBy(0.45, 0, styles.deltaY).easing(cc.easeBackIn()),
            new cc.CallFunc(this.restoreState.bind(this, true)),
            new cc.RemoveSelf()
        ));
    },

    handleHarvested: function () {
        this.restoreState(true);
        cleverapps.audio.playSound(bundles.merge.urls.harvest_field_effect);
    }
});

cleverapps.styles.FieldComponentView = {
    crop: {
        x: { align: "center", dx: 1 },
        y: { align: "bottom", dy: 15 }
    },

    sign: {
        x: { align: "center", dx: 4 },
        y: { align: "bottom", dy: 50 }
    },

    seed: {
        x: { align: "center" },
        y: { align: "bottom", dy: 90 },
        deltaY: -70
    },

    reward: {
        x: { align: "center" },
        y: { align: "bottom", dy: 30 },
        deltaY: 60
    }
};
