/**
 * Created by r4zi4l on 20.01.2022
 */

var FieldComponent = function (unit, options) {
    UnitComponent.call(this, unit);

    if (options.fresh) {
        this.recipe = FieldComponent.RECIPES[cleverapps.travelBook.getCurrentPage().id][0];
        this.time = 0;
        this.update();
    } else if (typeof options.recipe === "number") {
        this.recipe = FieldComponent.RECIPES[cleverapps.travelBook.getCurrentPage().id][options.recipe];
        if (this.recipe) {
            this.time = options.time;
        }
        this.update();
    } else {
        this.updateExclamation();
    }
};

FieldComponent.prototype = Object.create(UnitComponent.prototype);
FieldComponent.prototype.constructor = FieldComponent;

FieldComponent.prototype.onInfoOpened = function () {
    if ([FieldComponent.STATE_EMPTY, FieldComponent.STATE_READY].indexOf(this.getState()) !== -1) {
        Game.currentGame.advice.startInactiveHint({
            logic: Map2d.FIELD_HARVEST,
            once: false,
            delay: 1500
        });
    }
};

FieldComponent.prototype.onInfoClosed = function () {
    this.updateExclamation();
};

FieldComponent.prototype.onGrowthStart = function () {
    if (this.view) {
        this.view.handleGrowthStart();
    }
};

FieldComponent.prototype.onHarvested = function () {
    if (this.view) {
        this.view.handleHarvested();
    }
};

FieldComponent.prototype.onUpdateState = function () {
    if (this.view) {
        this.view.restoreState(true);
    }
};

FieldComponent.prototype.save = function (data) {
    if (this.recipe) {
        data.recipe = FieldComponent.RECIPES[cleverapps.travelBook.getCurrentPage().id].indexOf(this.recipe);
        data.time = this.time;
    }
};

FieldComponent.prototype.destructor = function () {
    clearTimeout(this.timeout);
    clearTimeout(this.changeCropsTimeout);
    cleverapps.timeouts.clearTimeout(this.exclamationTimeout);
};

FieldComponent.prototype.getTimeLeft = function () {
    if (this.recipe && this.time) {
        return Math.max(0, this.time + this.recipe.duration - Date.now());
    }
    return 0;
};

FieldComponent.prototype.getState = function () {
    if (!this.recipe) {
        return FieldComponent.STATE_EMPTY;
    }

    if (this.time) {
        return this.getTimeLeft() > this.recipe.duration / 2 ? FieldComponent.STATE_EARLY_GROWING : FieldComponent.STATE_LATE_GROWING;
    }

    return FieldComponent.STATE_READY;
};

FieldComponent.prototype.getInfo = function () {
    var state = this.getState();

    if (state === FieldComponent.STATE_READY) {
        return {
            actions: [{ type: ResourceView.ACTION_HARVEST }]
        };
    }

    if (state === FieldComponent.STATE_EARLY_GROWING || state === FieldComponent.STATE_LATE_GROWING) {
        var timeLeft = this.getTimeLeft();
        return {
            title: {
                code: this.recipe.prize.code,
                stage: this.recipe.prize.stage,
                icon: true
            },
            countdown: timeLeft,
            buttons: {
                speedup: {
                    unit: this.unit,
                    action: this.speedup.bind(this),
                    canCoins: false,
                    duration: timeLeft
                }
            }
        };
    }

    var actions = [];
    for (var i = 0; i < FieldComponent.RECIPES[cleverapps.travelBook.getCurrentPage().id].length; ++i) {
        var recipe = FieldComponent.RECIPES[cleverapps.travelBook.getCurrentPage().id][i];
        var action = {
            type: ResourceView.ACTION_PLANT,
            unit: recipe.prize
        };
        actions.push(action);

        if (recipe.fog && !Map2d.currentMap.fogs.isOpened(recipe.fog) || recipe.opened && !cleverapps.unitsLibrary.isOpened(recipe.prize)) {
            action.locked = true;
            break;
        }
    }

    if (actions.length) {
        return {
            actions: actions
        };
    }
};

FieldComponent.prototype.canAcceptAction = function (action) {
    switch (action.type) {
        case ResourceView.ACTION_PLANT: return this.getState() === FieldComponent.STATE_EMPTY;
        case ResourceView.ACTION_HARVEST: return this.getState() === FieldComponent.STATE_READY;
    }
};

FieldComponent.prototype.acceptAction = function (action) {
    switch (action.type) {
        case ResourceView.ACTION_PLANT: this.plant(FieldComponent.findRecipe(action.unit)); break;
        case ResourceView.ACTION_HARVEST: this.harvest(); break;
    }
};

FieldComponent.prototype.plant = function (recipe) {
    this.recipe = recipe;
    this.time = Date.now();
    this.unit.save();

    this.update();

    InfoView.CloseInfo();
    this.onGrowthStart();

    this.updateExclamation();

    Map2d.mapEvent(Map2d.FIELD_PLANT, { unit: this.unit });
};

FieldComponent.prototype.update = function () {
    clearTimeout(this.timeout);
    clearTimeout(this.changeCropsTimeout);

    if (!this.recipe) {
        return;
    }

    var timeLeft = this.getTimeLeft();
    if (timeLeft === 0) {
        this.time = undefined;
        this.unit.save();
        this.onUpdateState();
    } else {
        this.timeout = new cleverapps.LongTimeout(this.update.bind(this), timeLeft);
    }

    if (timeLeft > this.recipe.duration / 2) {
        this.changeCropsTimeout = new cleverapps.LongTimeout(function () {
            this.onUpdateState();
        }.bind(this), timeLeft - this.recipe.duration / 2);
    }
};

FieldComponent.prototype.speedup = function () {
    this.time = undefined;
    this.update();
};

FieldComponent.prototype.harvest = function () {
    var recipe = this.recipe;

    var units = [new Unit(recipe.prize)];
    var remains = Game.currentGame.spawn(units, this.unit, { radius: Map2d.RADIUS_BIG });
    if (remains.length) {
        return;
    }

    InfoView.CloseInfo(function () {
        cleverapps.timeouts.clearTimeout(this.exclamationTimeout);
        this.exclamationTimeout = cleverapps.timeouts.setTimeout(this.updateExclamation.bind(this), 2000);
    }.bind(this));

    this.recipe = undefined;
    this.onHarvested();
    this.unit.save();
    Map2d.mapEvent(Map2d.FIELD_HARVEST, { unit: this.unit });
};

FieldComponent.prototype.updateExclamation = function () {
    cleverapps.timeouts.clearTimeout(this.exclamationTimeout);

    if (this.getState() === FieldComponent.STATE_EMPTY) {
        this.unit.setPrizes({ sprite: bundles.merge.frames.prize_exclamation });
    } else {
        this.unit.setPrizes(undefined);
    }
};

FieldComponent.STATE_EMPTY = 0;
FieldComponent.STATE_EARLY_GROWING = 1;
FieldComponent.STATE_LATE_GROWING = 2;
FieldComponent.STATE_READY = 3;

FieldComponent.findRecipe = function (unit) {
    for (var i = 0; i < FieldComponent.RECIPES[cleverapps.travelBook.getCurrentPage().id].length; ++i) {
        var recipe = FieldComponent.RECIPES[cleverapps.travelBook.getCurrentPage().id][i];
        if (Unit.Equals(unit, recipe.prize)) {
            return recipe;
        }
    }
};

FieldComponent.RECIPES = {};
