/**
 * Created by evgeny on 17.06.2024
 */

Families.initialize = function () {
    Families.initializeBase();
    Families.afterInitialize();
};

if (typeof cc === "undefined") {
    module.exports = Families;
}