/**
 * Created by evgeny on 17.06.2024
 */

var getFurnitureByName = function (name) {
    if (cleverapps.home) {
        return cleverapps.home.getFurnitureByName(name);
    }

    var furniture = [];
    if (typeof cc === "undefined") {
        furniture = require("../../../../../wordsoup/res/locations/0/data.json").items;
    }

    for (var i = 0; i < furniture.length; i++) {
        if (name === furniture[i].name) {
            return furniture[i];
        }
    }

    return undefined;
};

var Families = {};

var FamiliesIds = {

    garbage1: 1,
    chloepetit: 2,
    cobweb1: 3,
    cobweb2: 4,
    fronthallwall: 5,
    cobweb3: 6,
    sidehallwall: 7,
    mainhallfloor: 8,
    highart: 9,
    portablemenu: 10,
    centraltables: 11,
    beveragealley: 12,
    plank1: 13,
    relaxationzone: 14,
    wastepaper: 15,
    cozycarpet: 16,
    boxofwonders: 17,
    clutter1: 18,
    foodmuseum: 19,
    plank2: 20,
    sourceofjoy: 21,
    platformofdreams: 22,
    paint: 23,
    extratables: 24,
    cozybooth: 25,
    minisidelights: 26,
    minifrontlights: 27,
    flowers: 28,
    kitchenentrance: 29,
    plates: 30,
    standlamp: 31,
    cobweb4: 32,
    leftkitchenwall: 33,
    cobweb5: 34,
    rightkitchenwall: 35,
    kitchenfloor: 36,
    gourmetstation: 37,
    plank3: 38,
    magiccorner: 39,
    kitchenclutter: 40,
    arcticparadise: 41,
    centerpiece: 42,
    yummyisland: 43,
    kitchenlights: 44,
    chefsmotherland: 45,
    brokentablewear: 46,
    kitchencarpet: 47,
    sungate: 48,
    cookinguniversity: 49,
    reinvigoratedcorner: 50,
    storageweb1: 51,
    rightstoragewall: 52,
    storageweb2: 53,
    leftstoragewall: 54,
    storagegarbage: 55,
    storagefloor: 56,
    colorfulvitamins: 57,
    coldzone: 58,
    storageunit: 59,
    provisioncenter: 60,
    lastfrontier: 61,
    storagelights: 62,
    officeweb1: 63,
    leftofficewall: 64,
    officeweb2: 65,
    rightofficewall: 66,
    officefloor: 67,
    wisdomtracker: 68,
    officeplanks1: 69,
    artificialday: 70,
    smallmercies: 71,
    softcomfort: 72,
    officewaste: 73,
    natureslefteye: 74,
    officeplanks2: 75,
    naturesrighteye: 76,
    officepaint: 77,
    cookingdatabase: 78,
    officecarpet: 79,
    frontdesk: 80,
    officelights: 81,
    inspirationstation: 82,
    indoorsecology: 83,
    bathroomweb1: 84,
    sinkofwonders: 85,
    bathromgarbage: 86,
    privacyzone: 87,
    bathroomweb2: 88,
    wonderfulview: 89,
    whitestone: 90,
    cliffhanger: 91,
    dusterminator: 92,
    cleanstorm: 93,
    staffroomweb1: 94,
    fashiondeposit: 95,
    staffroomweb2: 96,
    recreationalbench: 97,
    staffroomgarbage: 98,
    wall1: 99,
    wall2: 100,
    wall3: 101,
    floor: 102,
    staffroomfloor: 103,
    wall4: 104,
    bathroomlights: 105,
    bathroomcarpet: 106,
    staffroomlights: 107
};
Families.initialize = function () {
    Families.initializeBase();
    Families.afterInitialize();
};

Families.initializeBase = function () {
    Families.garbage1 = FamiliesHelper.FurnitureFamily({
        furniture: getFurnitureByName("Garbage 1"),
        expeditions: ["0"]
    });

    Families.chloepetit = FamiliesHelper.FurnitureFamily({
        furniture: getFurnitureByName("Chloe Petit"),
        expeditions: "all"
    });

    Families.cobweb1 = FamiliesHelper.FurnitureFamily({
        furniture: getFurnitureByName("Cobweb 1"),
        expeditions: ["0"]
    });
    Families.cobweb2 = FamiliesHelper.FurnitureFamily({
        furniture: getFurnitureByName("Cobweb 2"),
        expeditions: ["0"]
    });
    Families.fronthallwall = FamiliesHelper.FurnitureFamily({
        furniture: getFurnitureByName("Front Hall Wall"),
        expeditions: ["0"]
    });
    Families.cobweb3 = FamiliesHelper.FurnitureFamily({
        furniture: getFurnitureByName("Cobweb 3"),
        expeditions: ["0"]
    });
    Families.sidehallwall = FamiliesHelper.FurnitureFamily({
        furniture: getFurnitureByName("Side Hall Wall"),
        expeditions: ["0"]
    });
    Families.mainhallfloor = FamiliesHelper.FurnitureFamily({
        furniture: getFurnitureByName("Main Hall Floor"),
        expeditions: ["0"]
    });
    Families.highart = FamiliesHelper.FurnitureFamily({
        furniture: getFurnitureByName("High Art"),
        expeditions: ["0"]
    });
    Families.portablemenu = FamiliesHelper.FurnitureFamily({
        furniture: getFurnitureByName("Portable Menu"),
        expeditions: ["0"]
    });
    Families.centraltables = FamiliesHelper.FurnitureFamily({
        furniture: getFurnitureByName("Central Tables"),
        expeditions: ["0"]
    });
    Families.beveragealley = FamiliesHelper.FurnitureFamily({
        furniture: getFurnitureByName("Beverage Alley"),
        expeditions: ["0"]
    });
    Families.plank1 = FamiliesHelper.FurnitureFamily({
        furniture: getFurnitureByName("Plank 1"),
        expeditions: ["0"]
    });
    Families.relaxationzone = FamiliesHelper.FurnitureFamily({
        furniture: getFurnitureByName("Relaxation Zone"),
        expeditions: ["0"]
    });
    Families.wastepaper = FamiliesHelper.FurnitureFamily({
        furniture: getFurnitureByName("Waste Paper"),
        expeditions: ["0"]
    });
    Families.cozycarpet = FamiliesHelper.FurnitureFamily({
        furniture: getFurnitureByName("Cozy Carpet"),
        expeditions: ["0"]
    });
    Families.boxofwonders = FamiliesHelper.FurnitureFamily({
        furniture: getFurnitureByName("Box of Wonders"),
        expeditions: ["0"]
    });
    Families.clutter1 = FamiliesHelper.FurnitureFamily({
        furniture: getFurnitureByName("Clutter 1"),
        expeditions: ["0"]
    });
    Families.foodmuseum = FamiliesHelper.FurnitureFamily({
        furniture: getFurnitureByName("Food Museum"),
        expeditions: ["0"]
    });
    Families.plank2 = FamiliesHelper.FurnitureFamily({
        furniture: getFurnitureByName("Plank 2"),
        expeditions: ["0"]
    });
    Families.sourceofjoy = FamiliesHelper.FurnitureFamily({
        furniture: getFurnitureByName("Source of Joy"),
        expeditions: ["0"]
    });
    Families.platformofdreams = FamiliesHelper.FurnitureFamily({
        furniture: getFurnitureByName("Platform of Dreams"),
        expeditions: ["0"]
    });
    Families.paint = FamiliesHelper.FurnitureFamily({
        furniture: getFurnitureByName("Paint"),
        expeditions: ["0"]
    });
    Families.extratables = FamiliesHelper.FurnitureFamily({
        furniture: getFurnitureByName("Extra Tables"),
        expeditions: ["0"]
    });
    Families.cozybooth = FamiliesHelper.FurnitureFamily({
        furniture: getFurnitureByName("Cozy Booth"),
        expeditions: ["0"]
    });
    Families.minisidelights = FamiliesHelper.FurnitureFamily({
        furniture: getFurnitureByName("Mini Side Lights"),
        expeditions: ["0"]
    });
    Families.minifrontlights = FamiliesHelper.FurnitureFamily({
        furniture: getFurnitureByName("Mini Front Lights"),
        expeditions: ["0"]
    });
    Families.flowers = FamiliesHelper.FurnitureFamily({
        furniture: getFurnitureByName("Flowers"),
        expeditions: ["0"]
    });
    Families.kitchenentrance = FamiliesHelper.FurnitureFamily({
        furniture: getFurnitureByName("Kitchen Entrance"),
        expeditions: ["0"]
    });
    Families.plates = FamiliesHelper.FurnitureFamily({
        furniture: getFurnitureByName("Plates"),
        expeditions: ["0"]
    });
    Families.standlamp = FamiliesHelper.FurnitureFamily({
        furniture: getFurnitureByName("Stand Lamp"),
        expeditions: ["0"]
    });
    Families.cobweb4 = FamiliesHelper.FurnitureFamily({
        furniture: getFurnitureByName("Cobweb 4"),
        expeditions: ["0"]
    });
    Families.leftkitchenwall = FamiliesHelper.FurnitureFamily({
        furniture: getFurnitureByName("Left Kitchen Wall"),
        expeditions: ["0"]
    });
    Families.cobweb5 = FamiliesHelper.FurnitureFamily({
        furniture: getFurnitureByName("Cobweb 5"),
        expeditions: ["0"]
    });
    Families.rightkitchenwall = FamiliesHelper.FurnitureFamily({
        furniture: getFurnitureByName("Right Kitchen Wall"),
        expeditions: ["0"]
    });
    Families.kitchenfloor = FamiliesHelper.FurnitureFamily({
        furniture: getFurnitureByName("Kitchen Floor"),
        expeditions: ["0"]
    });
    Families.gourmetstation = FamiliesHelper.FurnitureFamily({
        furniture: getFurnitureByName("Gourmet Station"),
        expeditions: ["0"]
    });
    Families.plank3 = FamiliesHelper.FurnitureFamily({
        furniture: getFurnitureByName("Plank 3"),
        expeditions: ["0"]
    });
    Families.magiccorner = FamiliesHelper.FurnitureFamily({
        furniture: getFurnitureByName("Magic Corner"),
        expeditions: ["0"]
    });
    Families.kitchenclutter = FamiliesHelper.FurnitureFamily({
        furniture: getFurnitureByName("Kitchen Clutter"),
        expeditions: ["0"]
    });
    Families.arcticparadise = FamiliesHelper.FurnitureFamily({
        furniture: getFurnitureByName("Arctic Paradise"),
        expeditions: ["0"]
    });
    Families.centerpiece = FamiliesHelper.FurnitureFamily({
        furniture: getFurnitureByName("Center Piece"),
        expeditions: ["0"]
    });
    Families.yummyisland = FamiliesHelper.FurnitureFamily({
        furniture: getFurnitureByName("Yummy Island"),
        expeditions: ["0"]
    });
    Families.kitchenlights = FamiliesHelper.FurnitureFamily({
        furniture: getFurnitureByName("Kitchen Lights"),
        expeditions: ["0"]
    });
    Families.chefsmotherland = FamiliesHelper.FurnitureFamily({
        furniture: getFurnitureByName("Chef's Motherland"),
        expeditions: ["0"]
    });
    Families.brokentablewear = FamiliesHelper.FurnitureFamily({
        furniture: getFurnitureByName("Broken Tablewear"),
        expeditions: ["0"]
    });
    Families.kitchencarpet = FamiliesHelper.FurnitureFamily({
        furniture: getFurnitureByName("Kitchen Carpet"),
        expeditions: ["0"]
    });
    Families.sungate = FamiliesHelper.FurnitureFamily({
        furniture: getFurnitureByName("Sun Gate"),
        expeditions: ["0"]
    });
    Families.cookinguniversity = FamiliesHelper.FurnitureFamily({
        furniture: getFurnitureByName("Cooking University"),
        expeditions: ["0"]
    });
    Families.reinvigoratedcorner = FamiliesHelper.FurnitureFamily({
        furniture: getFurnitureByName("Reinvigorated Corner"),
        expeditions: ["0"]
    });
    Families.storageweb1 = FamiliesHelper.FurnitureFamily({
        furniture: getFurnitureByName("Storage Web 1"),
        expeditions: ["0"]
    });
    Families.rightstoragewall = FamiliesHelper.FurnitureFamily({
        furniture: getFurnitureByName("Right Storage Wall"),
        expeditions: ["0"]
    });
    Families.storageweb2 = FamiliesHelper.FurnitureFamily({
        furniture: getFurnitureByName("Storage Web 2"),
        expeditions: ["0"]
    });
    Families.leftstoragewall = FamiliesHelper.FurnitureFamily({
        furniture: getFurnitureByName("Left Storage Wall"),
        expeditions: ["0"]
    });
    Families.storagegarbage = FamiliesHelper.FurnitureFamily({
        furniture: getFurnitureByName("Storage Garbage"),
        expeditions: ["0"]
    });
    Families.storagefloor = FamiliesHelper.FurnitureFamily({
        furniture: getFurnitureByName("Storage Floor"),
        expeditions: ["0"]
    });
    Families.colorfulvitamins = FamiliesHelper.FurnitureFamily({
        furniture: getFurnitureByName("Colorful Vitamins"),
        expeditions: ["0"]
    });
    Families.coldzone = FamiliesHelper.FurnitureFamily({
        furniture: getFurnitureByName("Cold Zone"),
        expeditions: ["0"]
    });
    Families.storageunit = FamiliesHelper.FurnitureFamily({
        furniture: getFurnitureByName("Storage Unit"),
        expeditions: ["0"]
    });
    Families.provisioncenter = FamiliesHelper.FurnitureFamily({
        furniture: getFurnitureByName("Provision Center"),
        expeditions: ["0"]
    });
    Families.lastfrontier = FamiliesHelper.FurnitureFamily({
        furniture: getFurnitureByName("Last Frontier"),
        expeditions: ["0"]
    });
    Families.storagelights = FamiliesHelper.FurnitureFamily({
        furniture: getFurnitureByName("Storage Lights"),
        expeditions: ["0"]
    });
    Families.officeweb1 = FamiliesHelper.FurnitureFamily({
        furniture: getFurnitureByName("Office Web 1"),
        expeditions: ["0"]
    });
    Families.leftofficewall = FamiliesHelper.FurnitureFamily({
        furniture: getFurnitureByName("Left Office Wall"),
        expeditions: ["0"]
    });
    Families.officeweb2 = FamiliesHelper.FurnitureFamily({
        furniture: getFurnitureByName("Office Web 2"),
        expeditions: ["0"]
    });
    Families.rightofficewall = FamiliesHelper.FurnitureFamily({
        furniture: getFurnitureByName("Right Office Wall"),
        expeditions: ["0"]
    });
    Families.officefloor = FamiliesHelper.FurnitureFamily({
        furniture: getFurnitureByName("Office Floor"),
        expeditions: ["0"]
    });
    Families.wisdomtracker = FamiliesHelper.FurnitureFamily({
        furniture: getFurnitureByName("Wisdom Tracker"),
        expeditions: ["0"]
    });
    Families.officeplanks1 = FamiliesHelper.FurnitureFamily({
        furniture: getFurnitureByName("Office Planks 1"),
        expeditions: ["0"]
    });
    Families.artificialday = FamiliesHelper.FurnitureFamily({
        furniture: getFurnitureByName("Artificial Day"),
        expeditions: ["0"]
    });
    Families.smallmercies = FamiliesHelper.FurnitureFamily({
        furniture: getFurnitureByName("Small Mercies"),
        expeditions: ["0"]
    });
    Families.softcomfort = FamiliesHelper.FurnitureFamily({
        furniture: getFurnitureByName("Soft Comfort"),
        expeditions: ["0"]
    });
    Families.officewaste = FamiliesHelper.FurnitureFamily({
        furniture: getFurnitureByName("Office Waste"),
        expeditions: ["0"]
    });
    Families.natureslefteye = FamiliesHelper.FurnitureFamily({
        furniture: getFurnitureByName("Nature's Left Eye"),
        expeditions: ["0"]
    });
    Families.officeplanks2 = FamiliesHelper.FurnitureFamily({
        furniture: getFurnitureByName("Office Planks 2"),
        expeditions: ["0"]
    });
    Families.naturesrighteye = FamiliesHelper.FurnitureFamily({
        furniture: getFurnitureByName("Nature's Right Eye"),
        expeditions: ["0"]
    });
    Families.officepaint = FamiliesHelper.FurnitureFamily({
        furniture: getFurnitureByName("Office Paint"),
        expeditions: ["0"]
    });
    Families.cookingdatabase = FamiliesHelper.FurnitureFamily({
        furniture: getFurnitureByName("Cooking Database"),
        expeditions: ["0"]
    });
    Families.officecarpet = FamiliesHelper.FurnitureFamily({
        furniture: getFurnitureByName("Office Carpet"),
        expeditions: ["0"]
    });
    Families.frontdesk = FamiliesHelper.FurnitureFamily({
        furniture: getFurnitureByName("Front Desk"),
        expeditions: ["0"]
    });
    Families.officelights = FamiliesHelper.FurnitureFamily({
        furniture: getFurnitureByName("Office Lights"),
        expeditions: ["0"]
    });
    Families.inspirationstation = FamiliesHelper.FurnitureFamily({
        furniture: getFurnitureByName("Inspiration Station"),
        expeditions: ["0"]
    });
    Families.indoorsecology = FamiliesHelper.FurnitureFamily({
        furniture: getFurnitureByName("Indoors Ecology"),
        expeditions: ["0"]
    });
    Families.bathroomweb1 = FamiliesHelper.FurnitureFamily({
        furniture: getFurnitureByName("Bathroom Web 1"),
        expeditions: ["0"]
    });
    Families.sinkofwonders = FamiliesHelper.FurnitureFamily({
        furniture: getFurnitureByName("Sink of Wonders"),
        expeditions: ["0"]
    });
    Families.bathromgarbage = FamiliesHelper.FurnitureFamily({
        furniture: getFurnitureByName("Bathrom Garbage"),
        expeditions: ["0"]
    });
    Families.privacyzone = FamiliesHelper.FurnitureFamily({
        furniture: getFurnitureByName("Privacy Zone"),
        expeditions: ["0"]
    });
    Families.bathroomweb2 = FamiliesHelper.FurnitureFamily({
        furniture: getFurnitureByName("Bathroom Web 2"),
        expeditions: ["0"]
    });
    Families.wonderfulview = FamiliesHelper.FurnitureFamily({
        furniture: getFurnitureByName("Wonderful View"),
        expeditions: ["0"]
    });
    Families.whitestone = FamiliesHelper.FurnitureFamily({
        furniture: getFurnitureByName("White Stone"),
        expeditions: ["0"]
    });
    Families.cliffhanger = FamiliesHelper.FurnitureFamily({
        furniture: getFurnitureByName("Cliff Hanger"),
        expeditions: ["0"]
    });
    Families.dusterminator = FamiliesHelper.FurnitureFamily({
        furniture: getFurnitureByName("Dusterminator"),
        expeditions: ["0"]
    });
    Families.cleanstorm = FamiliesHelper.FurnitureFamily({
        furniture: getFurnitureByName("Clean Storm"),
        expeditions: ["0"]
    });
    Families.staffroomweb1 = FamiliesHelper.FurnitureFamily({
        furniture: getFurnitureByName("Staff Room Web 1"),
        expeditions: ["0"]
    });
    Families.fashiondeposit = FamiliesHelper.FurnitureFamily({
        furniture: getFurnitureByName("Fashion Deposit"),
        expeditions: ["0"]
    });
    Families.staffroomweb2 = FamiliesHelper.FurnitureFamily({
        furniture: getFurnitureByName("Staff Room Web 2"),
        expeditions: ["0"]
    });
    Families.recreationalbench = FamiliesHelper.FurnitureFamily({
        furniture: getFurnitureByName("Recreational Bench"),
        expeditions: ["0"]
    });
    Families.staffroomgarbage = FamiliesHelper.FurnitureFamily({
        furniture: getFurnitureByName("Staff Room Garbage"),
        expeditions: ["0"]
    });
    Families.wall1 = FamiliesHelper.FurnitureFamily({
        furniture: getFurnitureByName("Wall 1"),
        expeditions: ["0"]
    });
    Families.wall2 = FamiliesHelper.FurnitureFamily({
        furniture: getFurnitureByName("Wall 2"),
        expeditions: ["0"]
    });
    Families.wall3 = FamiliesHelper.FurnitureFamily({
        furniture: getFurnitureByName("Wall 3"),
        expeditions: ["0"]
    });
    Families.floor = FamiliesHelper.FurnitureFamily({
        furniture: getFurnitureByName("Floor"),
        expeditions: ["0"]
    });
    Families.staffroomfloor = FamiliesHelper.FurnitureFamily({
        furniture: getFurnitureByName("Staff Room Floor"),
        expeditions: ["0"]
    });
    Families.wall4 = FamiliesHelper.FurnitureFamily({
        furniture: getFurnitureByName("Wall 4"),
        expeditions: ["0"]
    });
    Families.bathroomlights = FamiliesHelper.FurnitureFamily({
        furniture: getFurnitureByName("Bathroom Lights"),
        expeditions: ["0"]
    });
    Families.bathroomcarpet = FamiliesHelper.FurnitureFamily({
        furniture: getFurnitureByName("Bathroom Carpet"),
        expeditions: ["0"]
    });
    Families.staffroomlights = FamiliesHelper.FurnitureFamily({
        furniture: getFurnitureByName("Staff Room Lights"),
        endlevel: true,
        expeditions: ["0"]
    });
};

Families.afterInitialize = function () {
    if (cleverapps.config.debugMode && typeof exports === "undefined") {
        var unknown = cleverapps.dataLoader.load(DataLoader.TYPES.UNKNOWN_UNIT_TEST) || {};
        if (unknown.code !== undefined && unknown.stage !== undefined) {
            delete Families[unknown.code].units[unknown.stage];
        } else if (unknown.code) {
            delete Families[unknown.code];
        }
    }

    Families.codes = Object.keys(Families).filter(function (code) {
        return typeof Families[code] === "object" && ["codes", "types"].indexOf(code) === -1;
    });

    Object.keys(Families).forEach(function (key) {
        var furniture = Families[key].furniture;
        if (furniture) {
            furniture.unitCode = key;
        }
    });

    Families.types = {};

    var allExpeditions = [];
    Families.codes.forEach(function (code) {
        var family = Families[code];
        family.code = code;
        family.id = FamiliesIds[code];
        family.expeditions = family.expeditions || ["main"];
        if (!Array.isArray(family.expeditions)) {
            family.expeditions = [family.expeditions];
        }

        if (family.extraBundles && !Array.isArray(family.extraBundles)) {
            family.extraBundles = [family.extraBundles];
        }

        if (family.code === family.images) {
            console.warn("remove " + code + " images");
            delete family.images;
        }

        family.expeditions.forEach(function (expedition) {
            if (expedition !== "all" && allExpeditions.indexOf(expedition) === -1) {
                allExpeditions.push(expedition);
            }
        });

        if (family.type) {
            Families.types[family.type] = true;
        }
    });

    Families.codes.forEach(function (code) {
        var family = Families[code];
        if (family.expeditions.indexOf("all") !== -1) {
            family.expeditions = allExpeditions;
        }
        if (family.extraBundles && family.extraBundles.indexOf("all") !== -1) {
            family.extraBundles = allExpeditions;
        }
    });

    if (typeof window === "undefined" || cleverapps.config.debugMode) {
        Families.codes.forEach(function (code) {
            if (/[^a-zA-Z0-9]/.test(code)) {
                throw "Invalid family code: " + code + ". The family code must match the regex [a-zA-Z0-9]";
            }
        });
    }

    var codesById = {};
    for (var code in FamiliesIds) {
        var id = FamiliesIds[code];
        codesById[id] = code;
    }

    Families.getCodeById = function (id) {
        return codesById[id];
    };

    Families.getIdByCode = function (code) {
        return FamiliesIds[code];
    };

    Families.listExpeditionCodes = function (expedition) {
        var codes = Families.codes.filter(function (code) {
            return Families[code].expeditions.indexOf(String(expedition)) !== -1;
        });
        return cleverapps.createSet(codes);
    };
};

if (typeof cc === "undefined") {
    module.exports = Families;
}