/**
 * Created by mac on 5/25/21
 */

var HeroItem = function (unit) {
    UnitComponent.call(this, unit);

    if (this.isHero()) {
        cleverapps.unitsLibrary.addHero(this.unit);
    }
};

HeroItem.prototype = Object.create(UnitComponent.prototype);
HeroItem.prototype.constructor = HeroItem;

HeroItem.IsHero = function (unit) {
    var heroitem = unit.findComponent(HeroItem);
    return heroitem && heroitem.isHero();
};

HeroItem.prototype.isHero = function () {
    return this.unit.stage === Families[this.unit.code].units.length - 1;
};

HeroItem.prototype.destructor = function () {
    if (this.isHero()) {
        cleverapps.unitsLibrary.removeHero(this.unit);
    }
};

HeroItem.prototype.getInfo = function () {
    if (cleverapps.meta.isFocused() || cleverapps.gameLevel.getLevel() < 3 || this.isHero()) {
        return undefined;
    }
    return {
        title: cleverapps.unitsLibrary.getUnitName(this.unit),
        windowParameters: {
            unitsLibrary: true,
            unit: this.unit
        }
    };
};