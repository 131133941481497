/**
 * Created by evgeny on 18.06.2024
 */

var Switchable = function (unit, options, choices) {
    UnitComponent.call(this, unit);

    this.unit = unit;
    this.choices = choices;
    this.choice = options.choice || 0;

    this.onShowChoice = function () {};
    this.onAnimateChoice = function () {};
    this.onAnimateHover = function () {};
    this.onStopAnimateHover = function () {};
};

Switchable.prototype = Object.create(UnitComponent.prototype);
Switchable.prototype.constructor = Switchable;

Switchable.prototype.save = function (data) {
    data.choice = this.choice;
};

Switchable.prototype.getChoice = function () {
    return this.choice;
};

Switchable.prototype.setChoice = function (choice) {
    this.choice = choice;
    this.unit.save();
    this.onAnimateChoice(choice);
};

Switchable.prototype.getLastStage = function () {
    return Families[this.unit.code].units.length - 1;
};

Switchable.prototype.showChoice = function (choice) {
    this.onShowChoice(choice);
};

Switchable.prototype.animateHover = function () {
    this.onAnimateHover();
};

Switchable.prototype.stopAnimateHover = function () {
    this.onStopAnimateHover();
};

Switchable.prototype.handleClick = function () {
    cleverapps.meta.display({
        focus: "SwitchableClick",
        action: function (f) {
            new MakeChoiceWindow(this, true);
            cleverapps.meta.onceNoWindowsListener = f;
        }.bind(this)
    });
};