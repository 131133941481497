/**
 * Created by mac on 12/15/20
 */

var HighlightView = cc.Node.extend({
    ctor: function (highlight) {
        this._super();

        this.highlight = highlight;

        var json = bundles.highlight_animations.jsons.hint_json;
        var view = this.view = new cleverapps.Spine(json);
        if (Game.currentGame && ["undersea", "undersea2", "undersea3"].indexOf(cleverapps.travelBook.getCurrentPage().id) !== -1) {
            this.setAnchorPoint(0.52, 0.44);
        } else {
            this.setAnchorPoint(0.5, 0.5);
        }

        this.setContentSize2(cleverapps.styles.Map2dView.cell);
        view.setPositionRound(this.width / 2, this.height / 2);
        view.setSkin(highlight.getShape() !== FamiliesHelper.ShapeCell ? "tile_light_2" : "tile_light_1");
        view.setAnimation(0, this.getAnimation(highlight.x, highlight.y), true);
        this.addChild(view);

        this.setLocalZOrder(-1);

        this.setRecCascadeColorEnabled();

        highlight.onMove = this.createListener(this.move.bind(this));
        highlight.hide = this.createListener(this.hide.bind(this));
        highlight.show = this.createListener(this.show.bind(this));
        highlight.onRemove = this.createListener(this.removeFromParent.bind(this));
        highlight.up = this.createListener(this.up.bind(this));

        if (highlight.getShape() === FamiliesHelper.ShapeSquare) {
            view.x += this.width / 2;
        }
        if (highlight.getShape() === FamiliesHelper.ShapeTopRightCorner) {
            view.x -= this.width / 2;
        }

        if (this.highlight.isErrorLight) {
            this.setColor(cleverapps.styles.COLORS.COLOR_RED);
        }
    }
});

HighlightView.prototype.hide = function () {
    this.visible = false;
};

HighlightView.prototype.show = function () {
    this.visible = true;
};

HighlightView.prototype.up = function () {
    this.setLocalZOrder(1);
};

HighlightView.prototype.move = function (x, y, silent) {
    this.view.setAnimation(0, this.getAnimation(x, y), true);

    this.stopAllActions();
    var mapView = Map2d.currentMap.getView();
    var position = mapView.alignInIsometricGrid(x, y);
    if (silent) {
        this.setPositionRound(position);
    } else {
        this.runAction(new cc.MoveTo(0.05, position.x, position.y));
    }

    if (this.highlight.isErrorLight || this.highlight.error) {
        this.setColor(cleverapps.styles.COLORS.COLOR_RED);
    } else {
        this.setColor(cleverapps.styles.COLORS.WHITE);
    }
};

HighlightView.prototype.getAnimation = function (x, y) {
    var animation = (Map2d.currentMap.getTerrainCode(x, y) === "s" && !this.highlight.error && !this.highlight.isErrorLight) ? "blue" : "yellow";
    return this.highlight.drag && this.view.hasAnimation(animation + "_dragging") ? animation + "_dragging" : animation;
};