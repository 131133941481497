/**
 * Created by slava on 17/9/19
 */

var MakeChoiceWindow = CleverappsWindow.extend({
    onWindowLoaded: function (switchable) {
        cleverapps.meta.showControlsWhileFocused("MenuBarGoldItem");
        this.switchable = switchable;

        this.switchable.animateHover();

        cleverapps.scenes.getRunningScene().map.scrollToUnit(0.5, switchable.unit, { zoom: 1 });

        this._super({
            styles: cleverapps.styles.MakeChoiceWindow,
            name: "makechoicewindow",
            content: this.getContent(),
            closeButton: false,
            contentPadding: {
                left: 0,
                right: 0,
                top: this.calcYOffset(),
                bottom: 0
            }
        });
    },

    unselectChoice: function (forNewSelect) {
        if (this.selected !== undefined) {
            this.choices[this.selected].bg.setVisible(true);
            this.choices[this.selected].selectedBg.setVisible(false);
            this.choices[this.selected].selectedCheckBox.setVisible(false);
            if (!forNewSelect) {
                this.selectedTitle.setVisible(false);
                this.selected = undefined;
                this.switchable.unshowChoice();
            }
        }
    },

    selectChoice: function (choice) {
        if (this.selected !== choice) {
            this.unselectChoice(true);
            this.selected = choice;
            this.choices[this.selected].bg.setVisible(false);
            this.choices[this.selected].selectedBg.setVisible(true);
            this.choices[this.selected].selectedCheckBox.setVisible(true);

            this.selectedTitle.setString(Messages.get("building." + this.switchable.unit.code + ".choice" + choice));
            this.selectedTitle.setFontSize(this.selectedTitle.originalFontSize);
            this.selectedTitle.fitTo(this.selectedTitle.maxWidth);
            this.selectedTitle.setVisible(true);

            this.switchable.showChoice(choice);
        }
    },

    makeChoice: function (choice) {
        var eventPrefix = this.switchable.choice === undefined ? cleverapps.EVENTS.MAKE_CHOICE : cleverapps.EVENTS.REDO_CHOICE;
        var choiceEvent = eventPrefix + choice + this.switchable.name;
        cleverapps.eventLogger.logEvent(choiceEvent);

        this.switchable.setChoice(choice);
        this.close();
    },

    finish: function () {
        if (this.selected !== undefined) {
            var choice = this.selected;

            if (this.isPreviousChoice(choice)) {
                this.close();
                return;
            }

            if (this.choices[choice].ad) {
                cleverapps.rewardedAdsManager.playRewarded(AdsLimits.TYPES.CHOICE, this.createListener(function () {
                    this.makeChoice(choice);
                }.bind(this)));
            } else if (this.choices[choice].price) {
                var price = this.choices[choice].price;
                if (levels.user.spendHard(cleverapps.EVENTS.SPENT.HOMEFIX_CHOICE, price)) {
                    this.makeChoice(choice);
                }
            } else {
                this.makeChoice(choice);
            }
        }
    },

    onClose: function () {
        this.switchable.stopAnimateHover();
    },

    isPreviousChoice: function (choice) {
        return this.switchable.choice !== undefined && this.switchable.choice === choice;
    },

    calcYOffset: function () {
        var styles = cleverapps.styles.MakeChoiceWindow;
        var scene = cleverapps.scenes.getRunningScene();

        var sceneHeight = cleverapps.resolution.getSceneSize().height;
        var bottomPadding = styles.bg.height + styles.bottomPadding;
        return scene.convertToNodeSpace({ x: 0, y: sceneHeight - bottomPadding }).y;
    },

    getContent: function () {
        var styles = cleverapps.styles.MakeChoiceWindow;

        var content = new cc.Node();
        content.setAnchorPoint(0.5, 0.5);

        this.choices = [];
        for (var i = 0; i < this.switchable.choices.length; i++) {
            this.choices.push(new MakeChoiceWindowItem({
                choice: i,
                isPreviousChoice: this.isPreviousChoice(i),
                onClick: this.selectChoice.bind(this, i),
                switchable: this.switchable,
                params: this.switchable.choices[i]
            }));
        }

        var choicesLine = new cleverapps.Layout(this.choices, {
            direction: cleverapps.UI.HORIZONTAL,
            margin: styles.choicesLine.margin
        });

        content.setContentSize2(choicesLine.width + 2 * styles.bg.padding, styles.bg.height);
        var bg = cleverapps.UI.createScale9Sprite(styles.bg.image);
        bg.setContentSize2(content.width, styles.bg.height);
        bg.setPositionRound(content.width / 2, content.height / 2);
        content.addChild(bg);

        choicesLine.setPositionRound(styles.choicesLine.position);
        bg.addChild(choicesLine);

        this.selectedTitle = cleverapps.UI.generateOnlyText("", cleverapps.styles.FONTS.LIGHT_WINDOW_TEXT);
        this.selectedTitle.setPositionRound(styles.choicesLine.title.position);
        this.selectedTitle.originalFontSize = this.selectedTitle.getFontSize();
        this.selectedTitle.maxWidth = content.width * 0.47;
        this.selectedTitle.setVisible(false);
        bg.addChild(this.selectedTitle);

        var selectButton = new cleverapps.UI.Button({
            text: "Select",
            type: cleverapps.styles.UI.Button.Images.small_button_green,
            width: styles.selectButton.width,
            height: styles.selectButton.height,
            onClicked: this.finish.bind(this)
        });

        selectButton.setPositionRound(styles.selectButton.position);
        bg.addChild(selectButton);

        this.selectChoice(this.switchable.choice !== undefined ? this.switchable.choice : 0);
        return content;
    },

    listBundles: function () {
        return ["homefix_windows"];
    }
});

cleverapps.styles.MakeChoiceWindow = {
    shadow: false,
    noBackground: true,
    bottomPadding: 20,

    bg: {
        image: bundles.homefix_windows.frames.choice_menu_png,
        padding: 20,
        height: 410
    },

    selectButton: {
        width: 200,
        height: 70,
        position: {
            x: { align: "right", dx: -20 },
            y: { align: "top", dy: -15 }
        }
    },

    choicesLine: {
        margin: 20,
        position: {
            x: { align: "center", dx: 0 },
            y: { align: "bottom", dy: 20 }
        },
        title: {
            position: {
                x: { align: "center", dx: -90 },
                y: { align: "top", dy: -30 }
            }
        }
    }
};
