/**
 * Created by mac on 2/3/21
 */

var UnitSaver = function (slot) {
    this.slot = slot;

    this.prefix = cleverapps.config.name + connector.platform.getLocalStoragePrefix() + "_map";
    if (this.slot) {
        this.prefix += "_" + this.slot + "_";
    }

    this.version = cleverapps.dataLoader.load(this.getVersionKey()) || 0;
    this.mapSize = cleverapps.dataLoader.load(this.getSizeKey()) || cc.size(0, 0);
};

UnitSaver.prototype.initialize = function () {
    var families = {};

    this.mapSize = cc.size(0, 0);

    Metha.ListAllLocations().forEach(function (data) {
        var level = new Level(data.episodeNo, data.levelNo);

        if (this.slot !== cleverapps.GameSaver.getStoreSlot(data.episodeNo, data.levelNo) || !level.mapSize) {
            return;
        }

        this.mapSize = cc.size(
            Math.max(this.mapSize.width, level.mapSize.width),
            Math.max(this.mapSize.height, level.mapSize.height)
        );

        families = Object.assign(families, level.families);
    }.bind(this));

    this.unitSyncer = new UnitSyncer(this, families);

    this.migrateSlotDragonia();

    this.loadMap();

    cleverapps.dataLoader.save(this.getSizeKey(), this.mapSize);
};

UnitSaver.prototype.saveData = function (x, y, data) {
    var key = this.getStoreKey(x, y);
    var dataStr = JSON.stringify(data);
    cleverapps.dataLoader.save(key, dataStr, { raw: true });
};

UnitSaver.prototype.loadData = function (key) {
    var data = cleverapps.dataLoader.load(key, { raw: true });
    if (!data) {
        return;
    }

    data = data.charAt(0) !== "{" ? UnitPacker.expandUnit(data) : JSON.parse(data);

    if (data.code && typeof data.code !== "string") {
        if (Array.isArray(data.code) && data.code.length === 1) {
            data.code = data.code[0];
        }
    }

    if (data._compact) {
        data = UnitPacker.expandUnit(data._compact, data.code);
        delete data.x;
        delete data.y;
    }

    return data;
};

UnitSaver.prototype.reset = function () {
    for (var x = 0; x < this.mapSize.width; x++) {
        for (var y = 0; y < this.mapSize.height; y++) {
            this.remove(x, y);
        }
    }

    cleverapps.dataLoader.remove(this.getSizeKey());

    if (this.unitSyncer) {
        this.unitSyncer.reset();
    }
};

UnitSaver.prototype.update = function (x, y, data, fromSyncer) {
    if (data.code === "unknown") {
        data = data._data;
    }

    if (UnitSyncer.DELETED_CODES[data.code]) {
        return;
    }

    this.saveData(x, y, data);

    if (fromSyncer !== true) {
        if (this.unitSyncer) {
            this.unitSyncer.save(x, y, data);
        }
    }
};

UnitSaver.prototype.getVersionKey = function () {
    return this.prefix + "_version";
};

UnitSaver.prototype.getSizeKey = function () {
    return this.prefix + "_size";
};

UnitSaver.prototype.getStoreKey = function (x, y) {
    return this.prefix + x + "_" + y;
};

UnitSaver.prototype.remove = function (x, y) {
    if (cleverapps.config.adminMode) {
        return;
    }

    var key = this.getStoreKey(x, y);

    cleverapps.dataLoader.remove(key);

    if (this.unitSyncer) {
        this.unitSyncer.remove(x, y);
    }
};

UnitSaver.prototype.migrateSlotDragonia = function () {
    if (this.slot !== "2") {
        return;
    }

    var x, y, dataStr, key, oldKey;

    for (x = 10; x < this.mapSize.width; x++) {
        for (y = 0; y < this.mapSize.height; y++) {
            key = this.getStoreKey(x, y);
            oldKey = key.replace("_map_2_", "_map2");

            dataStr = cleverapps.dataLoader.load(oldKey, { raw: true });
            if (dataStr) {
                cleverapps.dataLoader.save(key, dataStr, { raw: true });
                cleverapps.dataLoader.remove(oldKey);
            }
        }
    }
};

UnitSaver.prototype.loadMap = function () {
    var units = [];

    var x, y, data, key;

    for (x = 0; x < this.mapSize.width; x++) {
        for (y = 0; y < this.mapSize.height; y++) {
            key = this.getStoreKey(x, y);
            data = this.loadData(key);
            if (!data || UnitSyncer.DELETED_CODES[data.code]) {
                continue;
            }

            units.push({
                x: x,
                y: y,
                data: data
            });
        }
    }

    if (this.unitSyncer) {
        this.unitSyncer.reset(true);

        units.forEach(function (info) {
            this.unitSyncer.save(info.x, info.y, info.data, true);
        }, this);
    }

    return units;
};

UnitSaver.prototype.loadUnits = function (knownFamilies, expedition) {
    var units = this.loadMap();
    units = this.migrateUnits(units, expedition);
    this.processUnknown(units, knownFamilies);
    return units;
};

// eslint-disable-next-line no-unused-vars
UnitSaver.prototype.migrateUnits = function (unitsData, expedition) {
    return unitsData;
    // var toDelete = [];
    //
    // if (!this.version || this.version < 1) {
    //     this.version = 1;
    //
    //     var wrongUnits = {
    //         main: {
    //             hlbattlepass: true,
    //             hlfranky: true,
    //             hlgrave: true,
    //             hlgravechest: true,
    //             hlgravesource: true,
    //             hlgrowing: true,
    //             hlwitch: true
    //         }
    //     };
    //
    //     wrongUnits = wrongUnits[expedition] || {};
    //
    //     unitsData.filter(function (info) {
    //         if (wrongUnits[info.data && info.data.code] || wrongUnits[info.data && info.data._data && info.data._data.code]) {
    //             toDelete.push(info);
    //             return false;
    //         }
    //
    //         return true;
    //     });
    // }
    //
    // toDelete.forEach(function (info) {
    //     this.remove(info.x, info.y);
    // }.bind(this));
    //
    // cleverapps.dataLoader.save(this.getVersionKey(), this.version);
};

UnitSaver.prototype.updateInfo = function (units) {
    var x, y, key;

    for (x = 0; x < this.mapSize.width; x++) {
        for (y = 0; y < this.mapSize.height; y++) {
            key = this.getStoreKey(x, y);
            if (this.loadData(key)) {
                cleverapps.dataLoader.remove(key);
            }
        }
    }

    var kickOuts = [];

    units.forEach(function (unit) {
        if (unit.kickout) {
            kickOuts.push(unit);
        } else {
            this.update(unit.x, unit.y, unit.data, true);
        }
    }, this);

    this.saveKickOuts(kickOuts);
};

UnitSaver.prototype.processUnknown = function (units, knownFamilies) {
    units.forEach(function (unit) {
        var unknown = !Families[unit.data.code] || !Families[unit.data.code].units[unit.data.stage] || !knownFamilies[unit.data.code];
        if (unknown && !["intact", "furniture"].includes(unit.data.code)) {
            unit.data = { code: "unknown", stage: 0, _data: unit.data };
        }
    });
};

UnitSaver.prototype.loadKickOuts = function (knownFamilies) {
    var units = cleverapps.dataLoader.load(DataLoader.TYPES.UNIT_SAVER_KICKOUTS + this.slot) || [];
    this.processUnknown(units, knownFamilies);
    return units;
};

UnitSaver.prototype.saveKickOuts = function (kickOuts) {
    cleverapps.dataLoader.save(DataLoader.TYPES.UNIT_SAVER_KICKOUTS + this.slot, kickOuts);
};

UnitSaver.prototype.resetKickOuts = function () {
    cleverapps.dataLoader.remove(DataLoader.TYPES.UNIT_SAVER_KICKOUTS + this.slot);
};
