/**
 * Created by r4zi4l on 17.12.2021
 */

var Draggable = function (unit) {
    UnitComponent.call(this, unit);
};

Draggable.prototype = Object.create(UnitComponent.prototype);
Draggable.prototype.constructor = Draggable;

Draggable.prototype.onDragStart = function () {
    if (this.view) {
        this.view.handleDragStart();
    }
};

Draggable.prototype.onDragEnd = function () {
    if (this.view) {
        this.view.handleDragEnd();
    }
};

Draggable.prototype.onHighlight = function (target) {
    if (this.view) {
        this.view.highlight(target);
    } 
};

Draggable.prototype.onUnhighlight = function (target) {
    if (this.view) {
        this.view.unhighlight(target);
    } 
};

Draggable.prototype.onPointToTarget = function (target) {
    if (this.view) {
        this.view.pointToTarget(target);
    } 
};

Draggable.prototype.onCanBeAccepted = function (accepted) {
    if (this.view) {
        this.view.canBeAccepted(accepted);
    } 
};

Draggable.prototype.handleDragStart = function () {
    this.acceptors = Map2d.currentMap.listAvailableUnits().map(function (unit) {
        return unit.findAcceptorComponent(this.unit);
    }, this).filter(Boolean);

    this.acceptors.forEach(function (acceptor) {
        acceptor.unit.highlight();
        this.onHighlight(acceptor.unit);
    }, this);

    this.onDragStart();
};

Draggable.prototype.handleDragMove = function (x, y) {
    var target = Map2d.currentMap.getUnit(x, y);
    if (target) {
        if (target.head) {
            target = target.head;
        }

        if (target.findAcceptorComponent(this.unit)) {
            if (this.unit.light) {
                this.unit.light.hide();
            }
            this.unit.onMarkNormal();
            this.onCanBeAccepted(true);
            return true;
        }
    }

    this.acceptors.forEach(function (acceptor) {
        if (acceptor.unit.view && !acceptor.unit.view.draggableArrow) {
            this.onHighlight(acceptor.unit);
        }
    }.bind(this));

    this.onCanBeAccepted(false);
};

Draggable.prototype.handleDragEnd = function (x, y) {
    if (this.acceptors) {
        this.acceptors.forEach(function (acceptor) {
            acceptor.unit.unhighlight();
            this.onUnhighlight(acceptor.unit);
        }, this);
        this.acceptors = undefined;
    }

    var acceptor;
    var target = Map2d.currentMap.getUnit(x, y);
    if (target) {
        if (target.head) {
            target = target.head;
        }

        acceptor = target.findAcceptorComponent(this.unit);
        if (!acceptor && target.findAcceptorComponent(this.unit, { skipBuild: true })) {
            cleverapps.centerHint.createTextHint("cantCollectBuilding", { target: Messages.get("Units." + target.code + ".stage" + (target.stage + 1) + ".name") });
        }
    }

    this.onDragEnd();

    if (acceptor) {
        acceptor.acceptDraggable(this.unit);
        return true;
    }
};
