/**
 * Created by andrey on 31.07.2023
 */

var FogBalloonView = cc.Node.extend({
    ctor: function (baloon) {
        this._super();

        this.balloon = baloon;

        var styles = cleverapps.styles.FogBalloonView;
        this.setBalloonPosition();
        this.setAnchorPoint(0.5, 0.5);
        this.setContentSize(styles);

        if (!cleverapps.config.editorMode) {
            cleverapps.UI.applyHover(this);
            cleverapps.UI.onClick(this, function () {
                baloon.block.showBuyWindow();
            });
        }

        var animation = this.animation = new cleverapps.Spine(bundles.merge.jsons.fog_balloon_json);
        var skins = animation.listSkins();
        animation.setSkin(baloon.block.options.id % skins.length + "");

        animation.setPositionRound(styles.animation);
        this.addChild(this.animation);

        baloon.onHide = this.createListener(this.hide.bind(this));
        baloon.onShow = this.createListener(this.show.bind(this));

        if (baloon.shown) {
            this.show(true);
        } else {
            this.hide(true);
        }
    },

    hide: function (silent) {
        if (silent) {
            this.visible = false;
        } else {
            this.runAction(new cc.Sequence(
                new cc.ScaleTo(0.15, 0),
                new cc.CallFunc(function () {
                    this.visible = false;
                    this.setScale(1);
                }.bind(this))
            ));
        }
    },

    show: function (silent) {
        this.visible = true;

        if (silent) {
            this.animation.setAnimation(0, "idle", true);
        } else {
            this.animation.setAnimationAndIdleAfter("open", "idle");
        }
    },

    setBalloonPosition: function () {
        var x = this.balloon.tile.x;
        var y = this.balloon.tile.y;
        var mapView = Map2d.currentMap.getView();

        this.setPositionRound(mapView.alignInIsometricGrid(x, y));
        this.setLocalZOrder(mapView.getFogViewLocalZOrder(x, y));
    }
});

cleverapps.styles.FogBalloonView = {
    height: 270,
    width: 150,

    animation: {
        x: { align: "center", dx: 20 },
        y: { align: "center", dy: -50 }
    }
};