/**
 * Created by mac on 2/25/20
 */

var Fogs = function (map2d, isNewGame) {
    cleverapps.EventEmitter.call(this);
    this.map2d = map2d;

    this.wands = 0;

    this.loadConfig();

    this.wantsCalcFogStates = undefined;
    var needSave = false;
    var stored = cleverapps.dataLoader.load(DataLoader.TYPES.FOGS + Game.currentGame.slot);

    if (isNewGame) {
        stored = needSave = [];
    } else if (!stored && Game.currentGame && Game.currentGame.isMainGame()) {
        stored = needSave = cleverapps.GameSaver.loadProperty(CustomSyncers.SLOT_MAIN, "fogs") || [];
    } else if (!stored) {
        stored = [];
    }

    if (typeof stored === "number"
        || Array.isArray(stored) && stored.length <= Fogs.SYNCED_NUMBERS && typeof stored[0] === "number") {
        stored = Fogs.FromBit(stored, this.config);
    }

    this.blocks = {};

    var openedFogs = Object.keys(this.config).filter(function (type) {
        return this.config[type].opened || stored.indexOf(type) !== -1;
    }, this);

    var unopenedFogs = Object.keys(this.config).filter(function (type) {
        return openedFogs.indexOf(type) === -1;
    }, this);

    for (var i = 0; i < openedFogs.length;) {
        var fog = this.config[openedFogs[i]];

        if (fog.available === false || fog.debugMode === true && !cleverapps.config.debugMode) {
            unopenedFogs.push(openedFogs[i]);
            openedFogs.splice(i, 1);
            needSave = true;
        } else {
            i += 1;
        }
    }

    this.fakeUnits = Fogs.ReadDefaults(this.map2d.field);

    var regions = this.map2d.regions;

    Object.keys(this.config).forEach(function (id) {
        this.config[id].emptyRegion = !regions[id];
    }, this);

    unopenedFogs.forEach(function (id) {
        var block = new FogBlock(id, this);

        var region = regions[id];
        if (!region) {
            return;
        }

        this.addBlockTiles(block, region);

        if (cleverapps.config.debugMode && block.isMoney() && !block.options.bubbles) {
            block.tiles.forEach(function (tile) {
                var unit = this.fakeUnits[tile.x] && this.fakeUnits[tile.x][tile.y];
                if (unit === undefined) {
                    console.log("No unit on " + block.id + " on x:" + tile.x + " y:" + tile.y);
                    return;
                }
                unit = unit.head || unit;
                if (Families[unit.code].units[unit.stage].price === undefined) {
                    alert("No price for " + unit.code + " " + unit.stage);
                }
            }.bind(this));
        }
        this.blocks[id] = block;
    }, this);

    Object.values(this.blocks).forEach(function (block) {
        if (!block.head) {
            return;
        }
        var styles = cleverapps.styles.FogTileView[block.head.getFogType()];
        if (styles && styles.dispelling) {
            block.prepareOpenOrderForDispelling();
        } else if (styles && styles.whirlwind) {
            block.prepareOpenOrderForWind();
        } else if (!styles || !styles.groundStateOnly) {
            block.prepareOpenOrder();
        }
    });

    if (needSave) {
        this.save();
    }
};

Fogs.prototype = Object.create(cleverapps.EventEmitter.prototype);
Fogs.prototype.constructor = Fogs;

Fogs.prototype.setWands = function (amount) {
    this.wands = amount;
    for (var id in this.blocks) {
        this.blocks[id].onChangeWandsAmount(amount);
    }
};

Fogs.prototype.takeWands = function (wands) {
    this.trigger("takeWands", wands);
};

Fogs.prototype.addBlockTiles = function (block, region) {
    region.positions.sort(function (a, b) {
        return a.y - b.y || b.x - a.x;
    }).forEach(function (p) {
        var head = region.head && cc.pointEqualToPoint(p, region.head);
        block.add(p.x, p.y, { head: head });
    });

    this.addBlockBalloon(block, region);
};

Fogs.prototype.getTilesWithDecorators = function (block) {
    if (cleverapps.config.name !== "hustlemerge") {
        return [];
    }

    var map = this.map2d;
    var used = {};
    var decorTiles = [];

    var visit = function (tile, firstPass) {
        ISO_NEIGHBORS.concat([Iso.DOWN_BELOW, Iso.HORIZONTAL_LEFT, Iso.HORIZONTAL_RIGHT, Iso.UP_ABOVE]).forEach(function (dir) {
            var decorTile = {
                x: tile.x + dir.x,
                y: tile.y + dir.y
            };

            var key = map.getPositionKey(decorTile.x, decorTile.y);
            if (!used[key]) {
                used[key] = true;

                var unit = map.getUnit(decorTile.x, decorTile.y);

                if (firstPass || !unit || unit.code !== "decorator" || unit.stage === 1) {
                    decorTiles.push(decorTile);
                }

                if (unit && unit.code === "decorator" && unit.stage === 1) {
                    visit(decorTile);
                }
            }
        });
    };

    block.tiles.forEach(visit, true);

    decorTiles.sort(function (a, b) {
        return b.x - a.x || a.y - b.y;
    });

    return decorTiles;
};

Fogs.prototype.addBlockBalloon = function (block, region) {
    if (region.positions.length > 0 && block.isMoney()) {
        if (!region.balloons) {
            region.balloons = FogBalloon.ChoosePosition(region.positions, region.head);
        }
        if (region.balloons) {
            block.addBalloon(region.balloons.x, region.balloons.y);
        }
    }
};

Fogs.ReadMapUnit = function (item) {
    item = cleverapps.clone(item, true);

    var family = Families[item.code];
    if (!family) {
        cleverapps.throwAsync("unknown unit while reading map data " + item.code);
        return;
    }

    var data = Families[item.code].units[item.stage];

    item.type = Families[item.code].type;
    item.getType = function () {
        return item.type;
    };

    if (data.source) {
        item.grounded = true;
    }

    if (data.multicell) {
        item.multicell = data.multicell;
    }

    if (data.pointOfInterest) {
        item.pointOfInterest = data.pointOfInterest;
    }

    if (data.important) {
        item.important = data.important;
    }

    item.fresh = true;

    return item;
};

Fogs.ReadDefaults = function (defaults) {
    var units = {};
    defaults.forEach(function (item) {
        item = Fogs.ReadMapUnit(item);
        if (!item) {
            return;
        }

        var x = item.x;
        var y = item.y;

        if (units[x] === undefined) {
            units[x] = {};
        }

        units[x][y] = item;

        if (item.multicell) {
            item.multicell.forEach(function (delta) {
                if (delta.x || delta.y) {
                    var nx = x + delta.x;
                    var ny = y + delta.y;

                    if (units[nx] === undefined) {
                        units[nx] = {};
                    }

                    units[nx][ny] = {
                        head: item,
                        x: nx,
                        y: ny
                    };
                }
            });
        }
    });
    return units;
};

Fogs.prototype.open = function (id) {
    delete this.blocks[id];

    this.map2d.blockedGrounds.updateBlockedGrounds();
    Map2d.currentMap.workers.placeNotPositionedWorkers(id);

    this.wantsCalcFogStates = true;

    this.save();
    cleverapps.offerManager.refreshByFog(id);

    this.trigger("open");
};

Fogs.prototype.loadConfig = function () {
    this.config = FogsConfig[cleverapps.travelBook.getCurrentPage().id] || FogsConfig.main;

    var level = Game.currentGame && Game.currentGame.level;

    if (level && String(level.episodeNo).indexOf("ads") === 0) {
        this.config = FogsConfig.ads[level.episodeNo + "_" + level.levelNo] || FogsConfig.GenerateAdsDefault();
    }
};

Fogs.FromBit = function (bit, config) {
    var ids = cleverapps.createSet(cleverapps.fromBit(bit));

    return Object.keys(config).filter(function (code) {
        return ids[config[code].id];
    }, this);
};

Fogs.prototype.toBit = function (fogs) {
    var ids = fogs.map(function (fog) {
        return this.config[fog].id;
    }, this);
    return cleverapps.toBit(ids, Fogs.SYNCED_BITS);
};

Fogs.prototype.getInfo = function () {
    var fogs = cleverapps.substract(Object.keys(this.config), Object.keys(this.blocks || {})).filter(function (id) {
        return !this.config[id].emptyRegion;
    }, this);

    if (!Game.currentGame.isMainGame() || typeof Homefix !== "undefined") {
        fogs = this.toBit(fogs);
    }

    return fogs;
};

Fogs.prototype.save = function () {
    if (cleverapps.config.adminMode || cleverapps.config.editorMode) {
        return;
    }

    cleverapps.dataLoader.save(DataLoader.TYPES.FOGS + Game.currentGame.slot, this.getInfo());

    cleverapps.synchronizer.addUpdateTask("fogs" + Game.currentGame.slot);
};

Fogs.prototype.calcFogStates = function () {
    for (var id in this.blocks) {
        this.blocks[id].calcState();
    }
};

Fogs.prototype.calcFogStatesStage = function () {
    if (this.wantsCalcFogStates && !cleverapps.meta.isFocused()) {
        this.wantsCalcFogStates = undefined;
        this.calcFogStates();
    }
};

Fogs.prototype.isOpened = function (fogId) {
    return !this.blocks[fogId];
};

Fogs.prototype.getBlockById = function (fogId) {
    return this.blocks[fogId];
};

Fogs.prototype.findAvailableFogBlock = function () {
    var result;

    for (var id in this.blocks) {
        var block = this.blocks[id];

        if (block.state !== FogBlock.UNAVAILABLE) {
            if (!result || result.state < block.state) {
                result = block;
            }
        }
    }
    return result;
};

Fogs.prototype.showBalloons = function (silent) {
    for (var i in this.blocks) {
        this.blocks[i].showBalloon(silent);
    }
};

Fogs.prototype.hideBalloons = function () {
    for (var i in this.blocks) {
        this.blocks[i].hideBalloon();
    }
};

Fogs.prototype.findBlockWithProgress = function () {
    return Object.values(this.blocks).filter(function (block) {
        return block.state === FogBlock.CANTOPEN && !block.canOpen() && !block.isMoney() && !block.isSpecial();
    }).sort(function (a, b) {
        return a.getOpenPrice() - b.getOpenPrice();
    })[0];
};

Fogs.prototype.findFogReadyToOpen = function () {
    for (var i in this.blocks) {
        var fogBlock = this.blocks[i];
        if (fogBlock.options.available !== false && fogBlock.state === FogBlock.CANOPEN
            && !fogBlock.isMoney() && fogBlock.canOpen() && fogBlock.isReady()) {
            return this.blocks[i];
        }
    }
};

Fogs.prototype.findTile = function (x, y) {
    for (var i in this.blocks) {
        var fogBlock = this.blocks[i];
        var tile = fogBlock.findTile(x, y);
        if (tile) {
            return tile;
        }
    }
};

Fogs.prototype.findFakeUnit = function (targetUnit) {
    for (var row in this.fakeUnits) {
        for (var col in this.fakeUnits[row]) {
            if (Unit.IsApplicable(targetUnit, this.fakeUnits[row][col])) {
                return this.fakeUnits[row][col];
            }
        }
    }
};

Fogs.prototype.listFakeUnits = function (filter) {
    if (filter && typeof filter !== "function") {
        filter = Unit.IsApplicable.bind(Unit, filter);
    }

    var units = [];

    for (var row in this.fakeUnits) {
        for (var col in this.fakeUnits[row]) {
            if (!this.fakeUnits[row][col].head && filter(this.fakeUnits[row][col])) {
                units.push(this.fakeUnits[row][col]);
            }
        }
    }

    return units;
};

Fogs.prototype.getFakeUnit = function (x, y) {
    return this.fakeUnits[x] && this.fakeUnits[x][y];
};

Fogs.prototype.isPresentOnMap = function (unit) {
    if (!this.presentedUnits) {
        var presentedUnits = {};

        for (var row in this.fakeUnits) {
            for (var col in this.fakeUnits[row]) {
                if (!this.fakeUnits[row][col].head) {
                    presentedUnits[Unit.GetKey(this.fakeUnits[row][col])] = true;
                }
            }
        }

        this.presentedUnits = presentedUnits;
    }

    return this.presentedUnits[Unit.GetKey(unit)];
};

CustomSyncers.registerBySlots("fogs", function (slot) {
    return cleverapps.dataLoader.load(DataLoader.TYPES.FOGS + slot) || [];
}, function (slot, serverData) {
    cleverapps.dataLoader.save(DataLoader.TYPES.FOGS + slot, serverData);
});

Fogs.reset = function () {
    CustomSyncers.SLOTS.forEach(function (slot) {
        cleverapps.dataLoader.save(DataLoader.TYPES.FOGS + slot, []);
        cleverapps.synchronizer.addUpdateTask("fogs" + slot);
    });
};

Fogs.LoadOpened = function (pageId) {
    var page = cleverapps.travelBook.getPageById(pageId);
    var config = FogsConfig[page.id] || FogsConfig[0];
    var stored = cleverapps.dataLoader.load(DataLoader.TYPES.FOGS + page.slot);

    if (typeof stored === "number"
        || Array.isArray(stored) && stored.length <= Fogs.SYNCED_NUMBERS && typeof stored[0] === "number") {
        stored = Fogs.FromBit(stored, config);
    }

    return Array.isArray(stored) ? cleverapps.createSet(stored) : {};
};

Fogs.SYNCED_NUMBERS = 2;
Fogs.SYNCED_BITS = cleverapps.SYNCED_BITS * Fogs.SYNCED_NUMBERS;
