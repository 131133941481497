/**
 * Created by r4zi4l on 29.01.2021
 */

var InstantWorker = function (unit, options) {
    UnitComponent.call(this, unit);

    this.limit = options.limit || cleverapps.parseInterval(InstantWorker.LIMIT);

    this.onDragStart = function () {};
    this.onDragEnd = function () {};

    this.onDestructor = function () {};
};

InstantWorker.prototype = Object.create(UnitComponent.prototype);
InstantWorker.prototype.constructor = InstantWorker;

InstantWorker.prototype.onInfoOpened = function () {
    this.onUpdateState();
};

InstantWorker.prototype.onInfoClosed = function () {
    this.onUpdateState();
};

InstantWorker.prototype.onStartMagic = function () {
    if (this.view) {
        this.view.startMagic();
    }
};

InstantWorker.prototype.onFinishMagic = function () {
    if (this.view) {
        this.view.finishMagic();
    }
};

InstantWorker.prototype.onUpdateState = function () {
    if (this.view) {
        this.view.restoreState(true);
    }
};

InstantWorker.prototype.isFresh = function () {
    return this.limit === cleverapps.parseInterval(InstantWorker.LIMIT);
};

InstantWorker.prototype.isWorking = function () {
    return Boolean(this.assignment);
};

InstantWorker.prototype.save = function (data) {
    data.limit = this.limit;
};

InstantWorker.prototype.destructor = function () {
    Map2d.currentMap.workers.removeInstant(this);

    this.onDestructor();
};

InstantWorker.prototype.getInfo = function () {
    return {
        title: this.unit,
        duration: this.limit,
        remains: {
            value: this.limit,
            total: cleverapps.parseInterval(InstantWorker.LIMIT)
        },
        windowParameters: {
            unit: this.unit,
            guideOptions: InstantWorkerGuideOptions
        }
    };
};

InstantWorker.prototype.getPercentage = function () {
    return this.limit * 100 / cleverapps.parseInterval(InstantWorker.LIMIT);
};

InstantWorker.BUILDING_ANIMATION_TIME = 5500;

InstantWorker.prototype.buildInstantly = function (buildable) {
    var instantWorker = Map2d.currentMap.workers.findInstantAssigned(buildable);
    if (instantWorker || this.assignment) {
        return;
    }

    Map2d.currentMap.showLayerTile(Map2d.LAYER_UNITS, this.unit.x, this.unit.y);

    this.unit.move(this.unit.x, this.unit.y);

    var worker = Map2d.currentMap.workers.findAssigned(buildable.unit);
    if (worker) {
        worker.completeAssignment();
    }

    this.assignment = buildable;
    this.startBuildTime = Date.now();

    this.onStartMagic();
    buildable.startInstantWorker(this);

    if (!worker) {
        Map2d.mapEvent(Map2d.START_BUILDING, { affected: buildable.unit });
    }

    this.doMagicTimeout = Game.currentGame.counter.setTimeout(this.doMagic.bind(this), InstantWorker.BUILDING_ANIMATION_TIME);
};

InstantWorker.prototype.clearDoMagicTimeout = function () {
    if (this.doMagicTimeout !== undefined) {
        clearTimeout(this.doMagicTimeout);
        this.doMagicTimeout = undefined;
    }
};

InstantWorker.prototype.doMagic = function () {
    var buildable = this.assignment;

    this.clearDoMagicTimeout();

    delete this.assignment;
    delete this.startBuildTime;
    this.onFinishMagic(buildable);

    buildable.finishInstantWorker(this);

    var timeSpend = Math.min(this.limit, buildable.time);

    if (buildable.time > timeSpend) {
        buildable.unit.suspendBuilding(timeSpend);

        cleverapps.eventBus.trigger("taskEvent", DailyTasks.USE_WORKER);
    } else {
        buildable.unit.completeBuilding();
    }

    this.limit -= timeSpend;
    this.unit.save();

    if (this.limit <= 0) {
        cleverapps.eventBus.trigger("taskEvent", DailyTasks.USE_UP_INSTANT_WORKER);

        Game.currentGame.replace(this.unit, {
            code: this.unit.code,
            stage: 0
        });
    }
};

InstantWorker.prototype.getFullJobTime = function () {
    if (!this.isWorking()) {
        return;
    }
    return cleverapps.parseInterval(this.assignment.unit.getData().buildable);
};

InstantWorker.LIMIT = "1 hour";

if (typeof cc === "undefined") {
    module.exports = InstantWorker;
}
