/**
 * Created by evgeny on 13.06.2024
 */

(function () {
    Unit.CreateHierarchyMenu = function (callback) {
        var menu = {};

        Object.keys(Game.currentGame && Game.currentGame.level.families || {}).forEach(function (code) {
            if (["unknown", "multiCellBody", "specialoffer"].indexOf(code) !== -1) {
                return;
            }

            var type = Families[code].type || "other";
            var units = {};

            for (var stage = 0; stage < Families[code].units.length; ++stage) {
                if (Families[code].units[stage].deleted) {
                    continue;
                }

                var unit = { code: code, stage: stage };

                units[Unit.GetKey(unit)] = {
                    icon: UnitView.getUnitImage.bind(UnitView, unit),
                    action: callback.bind(null, unit)
                };
            }

            if (!menu[type]) {
                menu[type] = {};
            }

            menu[type][code] = units;
        });
        return menu;
    };

    Unit.CreateDebug = function (unit, cell) {
        var scene = cleverapps.scenes.getRunningScene();

        if (!cell) {
            cell = scene.map.getCellByCoordinates(scene.scroll.getCurrentPosition());
            cell = Map2d.currentMap.findEmptySlot(cell.x, cell.y, unit, { radius: 0 });
        }

        if (cell && (Map2d.currentMap.isGround(cell.x, cell.y) || Map2d.currentMap.isWaterUnit(cell.x, cell.y))) {
            unit = new Unit(unit);
            unit.setPosition(cell.x, cell.y);
            Map2d.currentMap.add(Map2d.LAYER_UNITS, cell.x, cell.y, unit);
            Map2d.currentMap.onAddUnit(cell.x, cell.y, unit);
            Map2d.currentMap.onUnitAvailable(unit);

            Map2d.mapEvent(Map2d.SPAWN, { unit: unit, debug: true });

            return true;
        }
    };

    UnitView.prototype.showTouchArea = function () {
        var used = {};
        var pool = [];
        var points = [];
        var siblings = [{ x: 1, y: 0 }, { x: 0, y: -1 }, { x: -1, y: 0 }, { x: 0, y: 1 }];

        var directions = [];
        for (var i = 0, j = 0, k = 1, l = 0, x = 0, y = 0; k < 6;) {
            x += siblings[j % 4].x;
            y += siblings[j % 4].y;
            directions.push(cc.p(x, y));

            i += 1;

            if (i >= k) {
                j += 1;
                l += 1;
                i = 0;
            }

            if (l >= 2) {
                k += 1;
                l = 0;
            }
        }

        var acceptTouch = function (point) {
            point = this.convertToWorldSpace(point);
            return this.checkTouchInside(new cc.Touch(point.x, point.y));
        }.bind(this);

        y = Math.floor(this.height / 2);
        for (x = 0; x < this.width; ++x) {
            var point = cc.p(x, y);

            if (acceptTouch(point)) {
                pool.push(point);
                break;
            }
        }

        for (i = 0; i < pool.length; ++i) {
            var current = pool[i];

            for (j = 0; j < directions.length; ++j) {
                var next = cc.p(current.x + directions[j].x, current.y + directions[j].y);
                var key = (next.x + 1000) + (next.y + 1000) * 10000;

                if (used[key]) {
                    continue;
                }
                used[key] = true;

                if (!acceptTouch(next)) {
                    continue;
                }

                for (k = 0; k < siblings.length; ++k) {
                    if (!acceptTouch(cc.p(next.x + siblings[k].x, next.y + siblings[k].y))) {
                        points.push(next);
                        pool.push(next);
                        break;
                    }
                }

                if (k !== siblings.length) {
                    break;
                }
            }
        }

        points = points.filter(function (point, index) {
            return !(index % 5);
        });

        this.savedTouchArea = new cc.DrawNode();
        this.savedTouchArea.setAnchorPoint(0, 0);
        this.savedTouchArea.setContentSize2(this.width, this.height);
        this.savedTouchArea.drawDots(points, 1.2, cc.color(0, 0, 200, 255));
        this.savedTouchArea.setLocalZOrder(1000);
        this.addChild(this.savedTouchArea);
    };

    UnitView.prototype.debugBorder = cleverapps.extendFunc(UnitView.prototype.debugBorder, function (options) {
        this._super(options);
        if (engine === "cocos2d") {
            this.showTouchArea();
        }
    });

    UnitView.prototype.cleanupBorder = cleverapps.extendFunc(UnitView.prototype.cleanupBorder, function (options) {
        this._super(options);

        if (this.savedTouchArea) {
            this.savedTouchArea.removeFromParent();
            this.savedTouchArea = undefined;
        }
    });

    FogBlock.prototype.click = cleverapps.extendFunc(FogBlock.prototype.click, function () {
        var time = Date.now();
        var timeout = 200;
        if (time - (this._toolLastClick || 0) < timeout) {
            clearTimeout(this.clickTimeout);
            delete this.clickTimeout;

            this._toolLastClick = 0;

            FogBlock.OpenDebug(this.id);
        } else {
            this._toolLastClick = time;
            var _super = this._super;
            var args = arguments;
            this.clickTimeout = setTimeout(function () {
                _super.apply(this, args);

                delete this.clickTimeout;
            }.bind(this), timeout);
        }
    });

    FogBlock.OpenDebug = function (targetId) {
        var scene = cleverapps.scenes.getRunningScene();

        var merge = Game.currentGame;
        var library = cleverapps.unitsLibrary;
        var fogs = merge.map.fogs.blocks;

        merge.tutorial.finishAll();

        var fogsToOpen = [];
        var findFogParent = function (fog) {
            fogsToOpen.unshift(fog);
            var options = fog.options || {};
            options.conditions = options.conditions || {};
            if (options.parentFogId !== undefined && fogs[options.parentFogId]) {
                findFogParent(fogs[options.parentFogId]);
            }
        };
        findFogParent(fogs[targetId]);

        merge.counter.setTimeout(function () {
            Game.currentGame.quests.quests = Game.currentGame.quests.quests.filter(function (quest) {
                return !quest.getTutorial();
            });
            Game.currentGame.quests.quests.forEach(function (quest) {
                quest.setFresh(false);
            });
            Game.currentGame.quests.save();
        }, 1000);

        fogsToOpen.forEach(function (fog) {
            var options = fog.options || {};
            options.conditions = options.conditions || {};

            merge.addReward("wands", options.price);
            fog.unlock();

            if (options.conditions.level) {
                while (cleverapps.gameLevel.getLevel() < options.conditions.level) {
                    cleverapps.exp.setExp(cleverapps.gameLevel.nextLevelThreshold(), true);
                    cleverapps.gameLevel.moveToNextLevel();
                }
                cleverapps.exp.onChange();
            }

            if (options.conditions.hero && !library.isHeroAvailable(options.conditions.hero)) {
                var unit = {
                    code: options.conditions.hero,
                    stage: Families[options.conditions.hero].units.length - 1
                };

                var cell = scene.map.getCellByCoordinates(scene.scroll.getCurrentPosition());
                cell = merge.map.findEmptySlot(cell.x, cell.y, unit, { radius: 0 });

                if (cell) {
                    unit = new Unit(unit);
                    unit.setPosition(cell.x, cell.y);
                    merge.map.add(Map2d.LAYER_UNITS, cell.x, cell.y, unit);
                    merge.map.onAddUnit(cell.x, cell.y, unit);
                    merge.onUnitAvailable(unit);
                }
            }
        });
    };

    Unit.prototype.debugRemove = function () {
        if (cleverapps.meta.isFocused() || !cleverapps.config.editorMode && this.code === "decorator" || Map2d.currentMap.getUnit(this.x, this.y) !== this) {
            return;
        }
        var buildable = this.findComponent(Buildable);
        if (Map2d.currentMap.workers.findAssigned(this) || buildable && Map2d.currentMap.workers && Map2d.currentMap.workers.findInstantAssigned(buildable)) {
            return;
        }

        InfoView.CloseInfo();
        this.onDestruction();
        this.remove(true);
        this.onClickEvent = function () {};
        Map2d.currentMap.blockedGrounds.updateBlockedGrounds();
    };

    UnitView.prototype.handleClick = cleverapps.extendFunc(UnitView.prototype.handleClick, function () {
        if (cleverapps.keyboardController.isPressed(cc.KEY.c)) {
            Game.currentGame.copyUnit(this.unit);
            return;
        }

        if (cleverapps.keyboardController.isPressed(cc.KEY.x)) {
            var url = cleverapps.RestClient.standsWeb("/?unitsDemo=" + cleverapps.travelBook.currentPage.id + "&code=" + this.unit.code);
            connector.platform.openUrl(url);
            return;
        }

        var locked = this.unit.findComponent(LockedComponent);
        if (cleverapps.keyboardController.isPressed(cc.KEY.l) && locked) {
            locked.setLockActive(!locked.isLockActive());
            return;
        }

        if (this._toolLastClick + PointerHandler.DOUBLE_CLICK_WAIT > Date.now()) { //
            this.unit.debugRemove();
            return true;
        }
        this._toolLastClick = Date.now();
        return this._super.apply(this, arguments);
    });

    Map2dView.prototype.addControls = cleverapps.extendFunc(Map2dView.prototype.addControls, function () {
        cc.eventManager.addListener({
            event: cc.EventListener.MOUSE,
            onMouseDown: function (event) {
                if (!this.isDisplayed()) {
                    return false;
                }

                if (event.getButton() === cc.EventMouse.BUTTON_RIGHT) {
                    var unit = this.findTouchUnit(event);
                    if (unit) {
                        if (cleverapps.keyboardController.isPressed(cc.KEY.shift)) {
                            Map2d.currentMap.listAvailableUnits(unit).forEach(function (unit) {
                                unit.debugRemove();
                            });
                        } else {
                            unit.debugRemove();
                        }
                    }
                }
            }.bind(this)
        }, this);

        this._super.apply(this, arguments);
    });

    UnitView.getUnitImage = cleverapps.extendFunc(UnitView.getUnitImage, function (unit) {
        var view = this._super.apply(this, arguments);
        if (cleverapps.environment.isEditorScene()) {
            if (Unit.IsApplicable(unit, { code: "decorator", stage: 0 })) {
                view.addChild(cleverapps.UI.generateImageText("*", cleverapps.styles.FONTS.WHITE_TEXT));
            }
            if (Unit.IsApplicable(unit, { code: "decorator", stage: 1 })) {
                view.addChild(cleverapps.UI.generateImageText("-", cleverapps.styles.FONTS.WHITE_TEXT));
            }
        }
        return view;
    });
}());