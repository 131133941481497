/**
 * Created by evgeny on 31.07.2024
 */

var ThrowawayView = function (throwaway, unitView) {
    this.throwaway = throwaway;
    this.unitView = unitView;

    this.throwaway.onAnimateDestroy = this.animateDestroy.bind(this);
};

ThrowawayView.prototype.animateDestroy = function (callback) {
    var sprite = this.unitView.sprite;

    new ActionPlayer([
        function (f) {
            sprite.setAnimation(0, "clean", false);
            sprite.setCompleteListener(f);
        },

        function (f) {
            sprite.setAnimation(0, "hide", false);
            sprite.setCompleteListener(f);
        }
    ]).play(callback);
};