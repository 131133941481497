/**
 * Created by evgeny on 01.08.2024
 */

var HighLight = function (unit) {
    UnitComponent.call(this, unit);

    this.unit = unit;

    this.onAddHighlight = function () {};
    this.onRemoveHighlight = function () {};
};

HighLight.prototype = Object.create(UnitComponent.prototype);
HighLight.prototype.constructor = HighLight;

HighLight.prototype.removeHighlight = function () {
    this.onRemoveHighlight();
};

HighLight.prototype.addHighlight = function () {
    this.onAddHighlight();
};