/**
 * Created by spepa on 21.06.2023
 */

var Map2dFenceView = cc.Node.extend({
    ctor: function (fenceTile) {
        this._super();
        this.setAnchorPoint(0.5, 0.5);
        this._localZOrder = fenceTile.getLocalZOrder();

        var image = new cleverapps.Spine(fenceTile.getJson());
        image.setIdle("animation");
        image.setPositionRound({ align: "center" }, { align: "center" });
        this.setContentSize2(image);
        this.addChild(image);

        fenceTile.onRemove = this.removeFromParent.bind(this);
        fenceTile.onGetView = function () {
            return this;
        }.bind(this);
    }
});