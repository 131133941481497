/**
 * Created by r4zi4l on 02.04.2021
 */

var ThirdElementView = cc.Node.extend({
    ctor: function (thirdElement, unitView) {
        this._super();

        this.thirdElement = thirdElement;
        this.unitView = unitView;

        this.createPrize();
        this.createTimer();
    },

    createPrize: function () {
        var anchors = UnitView.getUnitAnchor(this.thirdElement.prize);

        var prize = this.prize = UnitView.getUnitImage(this.thirdElement.prize);
        prize.setLocalZOrder(-1);
        this.unitView.addChild(prize);
        this.prize.setAnchorPoint(anchors.x, anchors.y);
        this.prize.setPositionRound(this.unitView.width / 2, this.unitView.height / 2);
    },

    createTimer: function () {
        var styles = cleverapps.styles.ThirdElementView.timer;

        var timer = this.timer = new cleverapps.CountDownView(new cleverapps.CountDown(this.thirdElement.getTimeLeft()), {
            font: cleverapps.styles.FONTS.COMPONENT_TIMER_TEXT,
            background: {
                frame: bundles.timer_merge.frames.timer_bg_png,
                width: styles.width,
                height: styles.height
            },
            icon: {
                frame: bundles.timer_merge.frames.timer_png,
                json: bundles.timer_merge.jsons.timer_icon_json,
                animation: "animation"
            }
        });
        timer.setPositionRound(styles);

        var buttonOptions = {
            width: styles.button.width,
            height: styles.button.height,
            type: cleverapps.styles.UI.Button.Images.field_button_blue
        };

        if (this.thirdElement.unit.stage === 0) {
            buttonOptions.text = "##Watch";
            buttonOptions.onClicked = this.thirdElement.watch.bind(this.thirdElement);
        } else {
            buttonOptions.text = "ThirdElementView.buy";
            buttonOptions.onClicked = this.thirdElement.handleClick.bind(this.thirdElement);
        }

        var button = new cleverapps.UI.Button(buttonOptions);
        button.setAnchorPoint(0.5, 0.5);
        button.setPositionRound(styles.button);
        timer.addChild(button);

        this.unitView.createAdditionalView("timer", timer);
    },

    helicopterAppearance: function () {
        var styles = cleverapps.styles.ThirdElementView;

        var unitView = this.unitView;
        unitView.sprite.setVisible(false);

        this.unitView.getAdditionalView("timer").hide(undefined, true);

        var helicopter = new cleverapps.Spine(bundles.thirdelement.jsons.thirdelement_json);
        helicopter.setPositionRound(styles.helicopter);
        unitView.addChild(helicopter);
        helicopter.replaceParentSamePlace(Map2d.currentMap.getView().animations);

        this.prize.setVisible(false);
        this.prize.replaceParentSamePlace(helicopter);
        this.prize.setPositionRound(styles.prize.x, styles.prize.y - this.prize.height / 2);

        var shine = new cleverapps.Spine(bundles.buildable_animation.jsons.buildable_above_json);
        shine.setLocalZOrder(1);
        shine.setPositionRound(unitView.width / 2, unitView.height / 2);
        shine.setVisible(false);
        unitView.addChild(shine);

        var offset = styles.helicopter.offset;
        helicopter.runAction(new cc.Sequence(
            new cc.PlaySound(bundles.thirdelement.urls.thirdelement_effect),
            new cc.DelayTime(0.5667),
            new cc.CallFunc(helicopter.runAction.bind(helicopter, new cc.RepeatForever(new cc.Sequence(
                new cc.MoveBy(1.25, offset.x, offset.y).easing(cc.easeQuadraticActionInOut()),
                new cc.MoveBy(1.25, -offset.x, -offset.y).easing(cc.easeQuadraticActionInOut())
            ))))
        ));

        this.prize.runAction(new cc.Sequence(
            new cc.DelayTime(0.5667),
            new cc.Show(),
            new cc.MoveBy(0.2, 0, -62),
            new cc.CallFunc(this.prize.runAction.bind(this.prize, new cc.RepeatForever(new cc.Sequence(
                new cc.ScaleTo(0.247, 0.92, 1),
                new cc.ScaleTo(0.169, 1, 1)
            ))))
        ));

        helicopter.setAnimation(0, "appearance", false);
        helicopter.setCompleteListener(function () {
            helicopter.stopAllActions();
            helicopter.setAnimation(0, "disappearance", false);
            helicopter.setCompleteListenerRemove();

            var endPosition = unitView.convertToWorldSpace(cc.p(unitView.width / 2, unitView.height / 2));
            endPosition = helicopter.convertToNodeSpace(endPosition);

            this.prize.runAction(new cc.Sequence(
                new cc.ScaleTo(0.2, 1, 1),
                new cc.MoveTo(0.17, endPosition.x, endPosition.y),
                new cc.CallFunc(function () {
                    if (!unitView.isRunning()) {
                        return;
                    }

                    this.prize.replaceParentSamePlace(unitView);
                    this.prize.runAction(new cc.Sequence(
                        new cc.PlaySound(bundles.merge.urls.spawn_landing_effect),
                        new cc.ScaleTo(0.15, 1, 0.8),
                        new cc.ScaleTo(0.15, 1, 1)
                    ));

                    shine.setVisible(true);
                    shine.setAnimation(0, "animation_bonus", false);
                    shine.setCompleteListenerRemove();

                    unitView.sprite.setScale(0.3);
                    unitView.sprite.runAction(new cc.Sequence(
                        new cc.DelayTime(0.3),
                        new cc.Show(),
                        new cc.PlaySound(bundles.thirdelement.urls.thirdelement_appearance_effect),
                        new cc.ScaleTo(0.4, 1.3),
                        new cc.ScaleTo(0.2, 1),
                        new cc.CallFunc(function () {
                            var timer = unitView.getAdditionalView("timer");
                            if (timer) {
                                timer.animateAppear();
                            }
                        })
                    ));
                }.bind(this))
            ));
        }.bind(this));
    },

    planeAppearance: function () {
        var mapView = Map2d.currentMap.getView();

        var styles = cleverapps.styles.ThirdElementView;

        this.unitView.getAdditionalView("timer").hide(undefined, true);
        this.prize.setVisible(false);
        this.prize.runAction(new cc.Sequence(
            new cc.PlaySound(bundles.thirdelement.urls.thirdelement_effect),
            new cc.ScaleTo(0.15, 1, 0.8),
            new cc.ScaleTo(0.15, 1, 1)
        ));

        var drop = new cleverapps.Spine(bundles.thirdelement.jsons.thirdelement_json);
        this.unitView.addChild(drop);
        drop.setPositionRound(styles.drop);
        drop.replaceParentSamePlace(mapView.animations);
        var dropTimeout = this.sendPlane();
        this.unitView.sprite.setVisible(false);
        this.runAction(new cc.Sequence(
            new cc.DelayTime(dropTimeout),
            new cc.CallFunc(function () {
                drop.setAnimation(0, "gift", false);
                drop.setCompleteListenerRemove();
            }),
            new cc.PlaySound(bundles.merge.urls.third_element_box),
            new cc.DelayTime(1.2),
            new cc.CallFunc(function () {
                this.prize.setVisible(true);
                this.unitView.sprite.setVisible(true);
                if (this.unitView.sprite.hasAnimation("animation1")) {
                    this.unitView.sprite.setAnimationAndIdleAfter("animation1", "animation");
                } else {
                    this.unitView.sprite.setAnimation(0, "animation", true);
                }
            }.bind(this)),
            new cc.DelayTime(1),
            new cc.CallFunc(function () {
                var timer = this.unitView.getAdditionalView("timer");
                if (timer) {
                    timer.animateAppear();
                }
            }.bind(this))
        ));
    },

    sendPlane: function () {
        var styles = cleverapps.styles.ThirdElementView.plane;
        if (!styles) {
            return 0;
        }
        var mapView = Map2d.currentMap.getView();

        var plane = new cc.Sprite(bundles.thirdelement.frames.thirdelement_plane);
        this.unitView.addChild(plane);
        plane.setPositionRound(styles);
        plane.replaceParentSamePlace(mapView.animations);

        var minTimeout = 0;
        while (cc.rectContainsPoint(mapView.visibleRect, plane)) {
            plane.x -= styles.speed.x;
            plane.y -= styles.speed.y;
            minTimeout += 0.5;
        }

        var flight = new cc.Sequence(
            new cc.MoveBy(0.5, styles.speed.x, styles.speed.y),
            new cc.CallFunc(function () {
                if (cc.rectContainsPoint(mapView.visibleRect, plane) || minTimeout > 0) {
                    minTimeout -= 0.5;
                    plane.runAction(flight.clone());
                } else {
                    plane.removeFromParent();
                }
            })
        );
        plane.runAction(flight.clone());

        return minTimeout;
    },

    playDestruction: function () {
        cleverapps.audio.playSound(bundles.thirdelement.urls.thirdelement_destruction_effect);
        this.unitView.removeAdditionalView("timer");
    },

    playBuying: function (unit) {
        var mapView = Map2d.currentMap.getView();

        var prizeView = unit.onGetView();
        prizeView.setScale(0.2);
        prizeView.setVisible(false);
        prizeView.debugCreated = "thirdelement";

        var unitView = this.unitView;

        var animation = new cleverapps.Spine(bundles.buildable_animation.jsons.buildable_above_json);
        animation.setLocalZOrder(2);

        mapView.alignViewInIsometricGrid(unit.x, unit.y, animation);
        mapView.animations.addChild(animation);

        unitView.stopAllActions();
        unitView.runAction(new cc.Sequence(
            new cc.DelayTime(0.7),
            new cc.PlaySound(bundles.thirdelement.urls.thirdelement_buying_effect),
            new cc.CallFunc(function () {
                animation.setAnimation(0, "animation", false);
                animation.setCompleteListenerRemove();
                unitView.removeAdditionalView("timer");
            }),
            new cc.ScaleTo(0.2, 0.3),
            new cc.RemoveSelf()
        ));

        prizeView.runAction(new cc.Sequence(
            new cc.DelayTime(0.7),
            new cc.Show(),
            new cc.CallFunc(function () {
                prizeView.debugShown = true;
            }),
            new cc.ScaleTo(0.2, 1),
            new cc.DelayTime(0.2),
            new cc.CallFunc(function () {
                prizeView.animateAppearPrizeView();
            })
        ));

        cc.eventManager.removeListeners(this.timer, true);
    }
});

cleverapps.styles.ThirdElementView = {
    timer: {
        x: { align: "center", dx: 0 },
        y: { align: "center", dy: -10 },
        width: 135,
        height: 29,

        button: {
            x: { align: "center" },
            y: { align: "bottom", dy: -56 },
            width: 130,
            height: 50,
            padding: {
                left: 12,
                right: 12,
                top: 5,
                bottom: 11
            },
            margin: 0
        }
    },

    prize: {
        x: { align: "center" },
        y: 322
        // offset: {
        //     y: 62
        // }
    },

    helicopter: {
        x: { align: "center" },
        y: { align: "bottom" },
        offset: {
            x: 20,
            y: 10
        }
    },

    adsButton: {
        icon: {
            x: { align: "right", dx: -10 },
            y: { align: "center", dy: 4 },
            scale: 0.55
        },

        text: {
            x: { align: "left" },
            y: { align: "center" }
        }
    }
};
