/**
 * Created by Aleksandr on 02.08.2024
 */

var EndLevelComponent = function (unit) {
    UnitComponent.call(this, unit);

    cleverapps.home.unlockLocation(cleverapps.home.level + 1);
};

EndLevelComponent.prototype = Object.create(UnitComponent.prototype);
EndLevelComponent.prototype.constructor = EndLevelComponent;
