/**
 * Created by vladislav on 13.04.2022
 */

var ShipView = function (ship, unitView) {
    this.ship = ship;
    this.unitView = unitView;

    this.restoreState();
};

ShipView.prototype.restoreState = function () {
    var mission = this.ship.getMission();

    if (cleverapps.gameModes.hideGuideAndProgress || !(mission && mission.getTimeLeft())) {
        this.removeTimer();
    } else {
        this.createTimer();
    }
};

ShipView.prototype.onCustomSpawn = function (delay) {
    this.unitView.stopAllActions();
    this.unitView.setVisible(false);

    this.spawnAction = this.unitView.runAction(new cc.Sequence(
        new cc.DelayTime(delay || 0),
        new cc.Show(),
        new cc.CallFunc(this.restoreState.bind(this)),
        new cc.PlaySound(bundles.ship.urls.spawn_ship_effect),
        new cc.CallFunc(function () {
            this.unitView.sprite.setAnimationAndIdleAfter("spawn", "idle");
        }.bind(this))
    ));
};

ShipView.prototype.createTimer = function () {
    var mission = this.ship.getMission();
    var styles = cleverapps.styles.ShipView;
    var timer = new ComponentTimer(mission.getTimeLeft(), cleverapps.parseInterval(mission.duration), {
        barType: ScaledProgressBar.Types.blue
    });
    timer.setPositionRound(styles.timer);
    this.unitView.createAdditionalView("timer", timer);
};

ShipView.prototype.clearAnimations = function () {
    if (this.spawnAction) {
        if (!this.spawnAction.isDone()) {
            this.unitView.stopAction(this.spawnAction);
        }

        delete this.spawnAction;
    }
};

ShipView.prototype.removeTimer = function () {
    this.unitView.removeAdditionalView("timer");
};

cleverapps.styles.ShipView = {
    timer: {
        x: { align: "center" },
        y: { align: "center", dy: -100 }
    }
};