/**
 * Created by iamso on 25.03.2020.
 */

var NewTaskAnimation = cc.Node.extend({
    ctor: function (furniture, scene, showDelay) {
        this._super();

        this.setAnchorPoint(0.5, 0.5);
        this.setContentSize2(cleverapps.styles.NewTaskAnimation);

        this.addBg();
        this.addTitle(furniture);
        this.addIcon(furniture);

        this.runAction(new cc.Sequence(
            new cc.DelayTime(showDelay || 2),
            new cc.CallFunc(function () {
                this.setupChildren = undefined;
            }.bind(this)),
            new cc.MoveBy(0.5, this.width * 2, 0).easing(cc.easeIn(1)),
            new cc.RemoveSelf()
        ));
    },

    addBg: function () {
        var animation = new cleverapps.Spine(bundles.homefix.jsons.hometask_newtask_json);
        animation.setAnimation(0, "animation", false);
        this.addChild(animation);
        animation.setPositionRound(this.width / 2, this.height / 2);
    },

    addTitle: function (furniture) {
        var title = cleverapps.UI.generateTTFText(Messages.get(furniture.getInfo().title), cleverapps.styles.FONTS.SMALL_WHITE_TEXT);
        title.fitTo(cleverapps.styles.NewTaskAnimation.title.width);
        this.addChild(title);
        title.setPositionRound(cleverapps.styles.NewTaskAnimation.title);
        title.setOpacity(0);
        title.runAction(new cc.Sequence(
            new cc.DelayTime(0.65),
            new cc.FadeIn(0.1)
        ));
    },

    createFurnitureIcon: function (furniture) {
        var bundle;

        if (!furniture.unit) {
            bundle = bundles["unit_chloepetit"].jsons["unit_chloepetit_1"];
        } else {
            bundle = bundles["unit_" + furniture.unit.code].jsons["unit_" + furniture.unit.code + "_1"];
        }

        var view = new cleverapps.Spine(bundle);
        view.setAnimation(0, "animation", true);
        return view;
    },

    addIcon: function (furniture) {
        var styles = cleverapps.styles.NewTaskAnimation.furniture;

        var container = new cc.Node();
        container.setAnchorPoint(0.5, 0.5);
        container.setContentSize2(styles);
        this.addChild(container);
        container.setPositionRound(styles);

        var animation = this.createFurnitureIcon(furniture);
        cleverapps.UI.fitToBox(animation, container);

        container.addChild(animation);
        animation.setPositionRound(container.width / 2, container.height / 2);

        container.setScale(0.2);
        container.setVisible(false);
        container.runAction(new cc.Sequence(
            new cc.DelayTime(0.65),
            new cc.Show(),
            new cc.ScaleTo(0.1, 1)
        ));
    }
});

cleverapps.styles.NewTaskAnimation = {
    width: 260,
    height: 340,

    title: {
        width: 245,
        x: { align: "center", dx: 5 },
        y: { align: "top", dy: -17 }
    },

    furniture: {
        width: 160,
        height: 160,
        x: { align: "center", dx: 0 },
        y: { align: "center", dy: -10 }
    }
};