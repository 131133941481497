/**
 * Created by r4zi4l on 17.12.2021
 */

var ToyFactoryView = cc.Node.extend({
    ctor: function (factory, unitView) {
        this._super();

        this.factory = factory;
        this.unitView = unitView;

        this.restoreState();
    },

    restoreState: function () {
        this.createGuideButton();

        if (this.factory.unit.prizes) {
            this.unitView.getAnimation().setAnimation(0, "idle", true);
            this.unitView.removeAdditionalView("timer", true);
        } else {
            this.unitView.getAnimation().setAnimation(0, "work", true);
            this.updateTimer();
        }
    },

    animateStart: function () {
        this.restoreState();
        cleverapps.audio.playSound(bundles.merge.urls.toyfactory_effect);
    },

    updateTimer: function () {
        var styles = cleverapps.styles.ToyFactoryView.timer;

        var timer = new cc.Node();
        timer.setAnchorPoint(0.5, 0.5);
        timer.setPositionRound(styles);

        var countdown = new cleverapps.CountDownView(new cleverapps.CountDown(this.factory.getTimeLeft()), {
            font: cleverapps.styles.FONTS.EXCHANGER_TIMER_TEXT,
            timerPosition: styles.text,
            bar: {
                type: ScaledProgressBar.Types.blue,
                width: styles.width,
                fullValue: ToyFactory.PRODUCTION_DURATION,
                reversed: false
            }
        });
        countdown.setPositionRound(styles.countdown);
        timer.addChild(countdown);

        var icon = new cc.Sprite(bundles.timer_merge.frames.timer_png);
        icon.setAnchorPoint(0.5, 0.5);
        icon.setPositionRound(styles.icon);
        timer.addChild(icon);

        var button = new cleverapps.UI.Button({
            width: styles.button.width,
            height: styles.button.height,
            type: cleverapps.styles.UI.Button.Images.field_button_blue,
            text: "Toyfactory.speedup",
            onClicked: this.factory.offerSpeedup.bind(this.factory)
        });
        button.adviceTarget = "speedup";
        button.setPositionRound(styles.button);
        timer.addChild(button);

        cleverapps.UI.wrap(timer);
        this.unitView.createAdditionalView("timer", timer);
    },

    createGuideButton: function () {
        var guide = this.factory.unit.getData().guide;
        if (this.guideButton || !guide) {
            return;
        }

        this.guideButton = new cleverapps.UI.HelpButton(
            function () {
                cleverapps.meta.display({
                    focus: "FactoryWindow",
                    action: function (f) {
                        new GuideWindow(guide);
                        cleverapps.meta.onceNoWindowsListener = f;
                    }
                });
            }
        );
        this.guideButton.setPositionRound(cleverapps.styles.ToyFactoryView.guideButton);
        this.addChild(this.guideButton);
    }
});

cleverapps.styles.ToyFactoryView = {
    timer: {
        x: { align: "center" },
        y: { align: "bottom" },
        width: 136,

        countdown: {
            x: { align: "center" },
            y: { align: "center" }
        },

        icon: {
            x: { align: "center", dx: -68 },
            y: { align: "center" }
        },

        text: {
            x: 4,
            y: 0
        },

        button: {
            height: 50,
            width: 130,
            x: { align: "center" },
            y: { align: "bottom", dy: -67 }
        }
    },

    guideButton: {
        x: { align: "right", dx: 140 },
        y: { align: "top", dy: 125 }
    }
};
