/**
 * Created by andrey on 15.12.2020.
 */

var Map2dEditorView = EditorViewBase.extend({
    ctor: function (editor) {
        this._super(editor);

        var map = this.map = new EditorMapComponent({
            width: this.width,
            height: this.height
        });
        this.addChild(map);

        var gitButtons = new GitButtonsView();
        this.addChild(gitButtons);

        var layersSwitcherView = new LayersSwitcherView(Map2dEditor.LAYERS);
        this.addChild(layersSwitcherView);
        layersSwitcherView.setPositionRound(cleverapps.styles.Map2dEditorView.layers);
    }
});

cleverapps.styles.Map2dEditorView = {
    layers: {
        x: { align: "left", dx: 60 },
        y: { align: "top", dy: -180 }
    }
};

EditorView = Map2dEditorView;
