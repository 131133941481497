/**
 * Created by vladislav on 10/08/2023
 */

var GuideComponent = function (unit) {
    UnitComponent.call(this, unit);
};

GuideComponent.prototype = Object.create(UnitComponent.prototype);
GuideComponent.prototype.constructor = GuideComponent;

GuideComponent.prototype.handleClick = function () {
    var guideOptions = this.unit.getData().guide;

    cleverapps.meta.display({
        focus: "GuideWindow",
        action: function (f) {
            new GuideWindow(guideOptions);

            cleverapps.meta.onceNoWindowsListener = f;
        }
    });
    return true;
};