/**
 * Created by r4zi4l on 30.07.2021
 */

var Telly = function (unit, options) {
    UnitComponent.call(this, unit);

    if (options.prizesCount) {
        this.seed = options.seed;
        this.unit.setPrizes(this.listPrize());
    }
};

Telly.prototype = Object.create(UnitComponent.prototype);
Telly.prototype.constructor = Telly;

Telly.prototype.onPrizeHarvested = function () {
    this.remove();
};

Telly.prototype.save = function (data) {
    data.seed = this.seed;
};

Telly.prototype.seedRandom = function () {
    if (this.seed === undefined) {
        this.seed = this.unit.seedRandom();
        this.seed = ((this.seed % 1000) + 1000) % 1000;
    }
    return this.seed;
};

Telly.prototype.listPrize = function () {
    this.unit.seedRandom();
    return Prizes.Generate(Telly.PRIZES[cleverapps.travelBook.getCurrentPage().id][this.unit.stage]);
};

Telly.prototype.listPrizeProb = function () {
    return {
        minAmount: 1,
        maxAmount: 1,
        types: Prizes.Generate(Telly.PRIZES[cleverapps.travelBook.getCurrentPage().id][this.unit.stage], {
            listOnly: true
        }).map(function (unit) {
            return {
                code: unit.code,
                stage: unit.stage,
                amount: 1
            };
        })
    };
};

Telly.prototype.handleClick = function () {
    this.unit.squeeze();

    var actions = [
        function (f) {
            setTimeout(f, 300);
        },

        function (f) {
            new WatchTellyWindow(this);
            cleverapps.meta.onceNoWindowsListener = f;
        }.bind(this)
    ];

    cleverapps.meta.display({
        focus: "WatchTellyWindow",
        actions: actions
    });
    return true;
};

Telly.prototype.remove = function () {
    this.unit.onDestruction();
    this.unit.remove(true);
};

Telly.prototype.prize = function () {
    var unitOnMap = Map2d.currentMap.getUnit(this.unit.x, this.unit.y);
    if (unitOnMap === this.unit) {
        this.seedRandom();
        this.unit.setPrizes(this.listPrize());

        cleverapps.eventBus.trigger("taskEvent", DailyTasks.WATCH_ADS);
    }
};

Telly.PRIZES = {
    main: [
        [{
            units: [
                { currentType: "fruit", stage: 2 },
                { currentType: "hero", stage: 0 },
                { code: "worker", stage: 0 }
            ]
        }],
        [{
            units: [
                { currentType: "hero", stage: 1 },
                { code: "wands", stage: 1 },
                { code: "seasonchest", stage: 0 },
                { code: "worker", stage: 1 }
            ]
        }],
        [{
            units: [
                { currentType: "hero", stage: 2 },
                { code: "wands", stage: 2 },
                { code: "seasonchest", stage: 1 },
                { code: "magicplant", stage: 1 },
                { code: "worker", stage: 2 }
            ]
        }]
    ]
};
