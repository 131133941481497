/**
 * Created by Andrey Popov on 16.08.2022
 */

var Feedable = function (unit) {
    UnitComponent.call(this, unit);

    this.fruitCode = this.unit.getData().feedable.fruitCode;
    this.canStarve = !this.unit.getType().startsWith("clpet");

    this.eating = false;
    this.setupPrize();
};

Feedable.prototype = Object.create(UnitComponent.prototype);
Feedable.prototype.constructor = Feedable;

Feedable.prototype.onInfoClosed = function (withAction) {
    if (!withAction && this.isHungry()) {
        this.unit.setPrizes({ sprite: cleverapps.skins.getSlot("feed_icon_png") });
    }
};

Feedable.prototype.canAcceptDraggable = function (unit) {
    return this.isHungry() && (!unit.getData || unit.getData().feedableTarget);
};

Feedable.prototype.acceptDraggable = function (unit) {
    this.feed();
    unit.findComponent(Collectible).onFeed(this.unit);
    unit.remove(true);
};

Feedable.prototype.onFeed = function () {
    if (this.view) {
        this.view.feed();
    }
};

Feedable.prototype.handlePrizes = function () {
    if (this.state === Feedable.STATE_STARVE) {
        var map = Map2d.currentMap;

        var position = cc.p(this.unit.x, this.unit.y);

        var readySources = [];
        var sources = [];
        var shop;
        map.listAvailableUnits(function (unit) {
            if (Unit.Equals(unit, {
                code: this.fruitCode,
                stage: 3
            }) && unit.findComponent(Fruit).timeToReady() === 0) {
                readySources.push(unit);
            }

            if (unit.code === this.fruitCode) {
                sources.push(unit);
            }

            if (unit.getType() === "unitsshop") {
                shop = unit;
            }
        }.bind(this));

        readySources.sort(function (a, b) {
            return cc.pDistanceSQ(position, a) - cc.pDistanceSQ(position, b);
        });
        sources.sort(function (a, b) {
            return b.stage - a.stage || cc.pDistanceSQ(position, a) - cc.pDistanceSQ(position, b);
        });

        var target;
        if (readySources.length > 0) {
            target = readySources[0];
        } else if (sources.length > 0) {
            target = sources[0];
        } else if (shop) {
            target = shop;
        } else {
            target = Map2d.currentMap.fogs.findFakeUnit([{ type: "unitsshop" }]);
        }

        if (target) {
            map.getView().scroll.runAction(new cc.CellScrollAction(target, {
                allowScrollWithFocus: true
            }).easing(cc.easeInOut(2)));

            Map2d.currentMap.showLayerTile(Map2d.LAYER_UNITS, target.x, target.y);
            FingerView.hintClick(target, {
                runOnce: true
            });

            cleverapps.centerHint.create({
                fruitCode: this.fruitCode,
                unit: target
            });
        }

        return true;
    }
};

Feedable.prototype.setupPrize = function () {
    var prize = this.unit.getData().prize;
    var resource = prize.resource;
    var amount = prize.amount;

    this.prize = {
        resource: resource,
        amount: amount
    };
};

Feedable.prototype.feed = function () {
    this.eating = true;
    this.state = Feedable.STATE_FULL;
    this.unit.setPrizes();

    this.feedTime = Date.now();

    var game = Game.currentGame;

    var reward = this.prize.resource;
    var rewardAmount = this.prize.amount;
    if (reward === "pawsPoints" && Game.currentGame.tutorial.checkTypes([Map2d.FEED_HERO])) {
        rewardAmount = Game.currentGame.pawBox.getPointsLeft();
    }

    game.counter.setTimeout(function () {
        this.eating = false;
        game.addReward(reward, rewardAmount, this.unit, { withoutDelta: true });
        Map2d.mapEvent(Map2d.FEED_HERO, { affected: this.unit });

        cleverapps.audio.playSound(bundles.merge.urls.feeding_effect);

        Feedable.processFeedable();
    }.bind(this), Feedable.FOOD_FLY_TIME);
};

Feedable.prototype.isAcceptable = function (stage) {
    return stage === 4;
};

Feedable.prototype.canCollect = function (stage) {
    return this.isAcceptable(stage) && this.isHungry();
};

Feedable.prototype.isReady = function () {
    return this.getTimeLeft() <= 0;
};

Feedable.prototype.isHungry = function () {
    return this.state === Feedable.STATE_HUNGRY;
};

Feedable.prototype.getTimeLeft = function () {
    var feedTime = this.feedTime || 0;
    return Math.max(0, feedTime + Feedable.FEED_COOLDOWN - Date.now());
};

Feedable.prototype.getInfo = function () {
    var food = Map2d.currentMap.listAvailableUnits(function (unit) {
        return unit.getData().feedableTarget;
    });
    if (this.isHungry() && food.length > 0) {
        var collectible = food[0].findComponent(Collectible);
        return {
            title: this.unit,
            buttons: {
                feed: collectible.collect.bind(collectible, this.unit)
            }
        };
    }
};

Feedable.prototype.clearTimer = function () {
    if (this.timeout) {
        clearTimeout(this.timeout);
        this.timeout = undefined;
    }
};

Feedable.prototype.updateTimer = function () {
    this.clearTimer();

    this.timeout = new cleverapps.LongTimeout(function () {
        Feedable.processFeedable();
    }, this.getTimeLeft());
};

Feedable.prototype.setState = function (state) {
    if (this.state === state) {
        return;
    }

    this.state = state;

    this.clearTimer();

    if (this.state === Feedable.STATE_HUNGRY) {
        this.unit.setPrizes({ sprite: cleverapps.skins.getSlot("feed_icon_png") });
    } else if (this.state === Feedable.STATE_FULL) {
        this.unit.setPrizes();
    } else if (this.state === Feedable.STATE_STARVE) {
        this.unit.setPrizes({ sprite: bundles.merge.frames.prize_exclamation_off });
    }
};

Feedable.prototype.destructor = function () {
    clearTimeout(this.timeout);
    this.timeout = undefined;
    this.cleared = true;
};

Feedable.prototype.handleRemove = function () {
    Feedable.processFeedable();
};

Feedable.processFeedable = function () {
    if (this.processTimeout) {
        return;
    }

    var game = Game.currentGame;

    this.processTimeout = setTimeout(function () {
        if (this.processTimeout) {
            clearTimeout(this.processTimeout);
            this.processTimeout = undefined;
        }

        if (game.stopped || game !== Game.currentGame) {
            return;
        }

        var food = [];
        var feedables = [];
        var readyForFeed = 0;

        game.map.listAvailableUnits(function (unit) {
            if (unit.getData().feedableTarget) {
                food.push(unit);
            }

            var feedable = unit.findComponent(Feedable);
            if (feedable) {
                feedables.push(feedable);
            }
        });

        var findMinDist = function (feedable) {
            var minDist = 99999;
            food.forEach(function (item) {
                var dist = cc.pDistanceSQ(feedable.unit, item);
                minDist = dist < minDist ? dist : minDist;
            });
            return minDist;
        };

        feedables = feedables.sort(function (a, b) {
            var timeDiff = (a.feedTime || 0) - (b.feedTime || 0);
            return timeDiff !== 0 ? timeDiff : (findMinDist(a) - findMinDist(b));
        });

        for (var i = 0; i < feedables.length; i++) {
            var feedable = feedables[i];
            if (readyForFeed < food.length && feedable.isAcceptable(food[0].stage) && !feedable.eating) {
                if (feedable.isReady()) {
                    feedable.setState(Feedable.STATE_HUNGRY);
                    readyForFeed++;
                } else {
                    feedable.updateTimer();
                }
            } else if (food.length === 0 && feedable.canStarve) {
                feedable.setState(Feedable.STATE_STARVE);
            } else {
                feedable.setState(Feedable.STATE_FULL);
            }
        }

        food.forEach(function (food) {
            var collectible = food.findComponent(Collectible);
            if (collectible) {
                collectible.setFeedables(feedables);
            }

            var pulsing = food.findComponent(Pulsing);
            if (pulsing) {
                pulsing.setActive(readyForFeed > 0);
            }
        });
    }.bind(this), 100);
};

Feedable.FOOD_FLY_TIME = 650;
Feedable.FEED_COOLDOWN = cleverapps.parseInterval("10 seconds");

Feedable.STATE_HUNGRY = 0;
Feedable.STATE_FULL = 1;
Feedable.STATE_STARVE = 2;
