/**
 * Created by Andrey Popov on 10.11.22
 */

var ClimbableView = cc.Node.extend({
    ctor: function (climbable, unitView) {
        this._super();

        this.unitView = unitView;
        this.climbable = climbable;

        var sfx = this.getClimbSfx();
        if (sfx) {
            cleverapps.bundleLoader.ensureSoundsLoaded(sfx);
        }
    },

    customClimb: function (newUnit, callback) {
        var newUnitView = newUnit.onGetView();
        newUnitView.setVisible(false);

        this.unitView.replaceParentSamePlace(Map2d.currentMap.getView().animations, {
            keepScale: true
        });
        this.unitView.sprite.setAnimation(0, "climb", false);
        this.unitView.sprite.setCompleteListenerOnce(function () {
            this.unitView.removeFromParent();
            newUnitView.setVisible(true);
            callback();
        }.bind(this));
    },

    beforeStageUpdate: function () {
        var prizeView = this.unitView.getAdditionalView("prize");
        if (prizeView) {
            prizeView.hide();
        }
    },

    hasCustomAnimation: function () {
        return this.unitView.sprite.hasAnimation && this.unitView.sprite.hasAnimation("climb");
    },

    updateStage: function (newUnit, callback) {
        if (!this.climbable.unit.getData().simpleClimb && !this.hasCustomAnimation()) {
            this.unitView.removeFromParent();
            callback();
            return;
        }

        var sound = this.getClimbSfx();
        cleverapps.audio.playSound(sound);

        if (this.hasCustomAnimation()) {
            this.customClimb(newUnit, callback);
        } else {
            this.runAction(AnimationsLibrary.upgradeCloud(this.unitView, newUnit.onGetView(), { callback: callback }));
        }
    },

    getClimbSfx: function () {
        return bundles.merge.urls["climb_effect_" + Unit.GetKey(this.climbable.unit)]
        || bundles.merge.urls["climb_effect_" + this.climbable.unit.getType()];
    }
});