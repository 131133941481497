/**
 * Created by mac on 2/3/20
 */

var Home = function () {
    cleverapps.EventEmitter.call(this);

    this.onSave = function () {};
    this.onChangeStars = function () {};

    this.level = 0;
    this.stars = 0;
    this.currentFurnitureId = this.firstFurnitureId();

    this.load();
    this.loadLocationData(this.level);

    this.tasks = new HomeTasks(this);
    this.tasks.reset();
};

Home.prototype = Object.create(cleverapps.EventEmitter.prototype);
Home.prototype.constructor = Home;

Home.prototype.amountLevelStars = function (level) {
    return level.isHard() ? 3 : 1;
};

Home.prototype.gamePlayed = function (outcome, game) {
    if (outcome === GameBase.OUTCOME_VICTORY) {
        cleverapps.eventLogger.logEvent(cleverapps.EVENTS.METHA_LEVEL);
        cleverapps.playSession.set(cleverapps.EVENTS.METHA_LEVEL, true);

        game.rewards.homeStar = this.amountLevelStars(game.level);
    }
};

Home.prototype.showChoiceTarget = function (furniture, f) {
    this.trigger("showChoiceTarget", furniture, f);
};

Home.prototype.showTarget = function (furniture, f) {
    this.trigger("showTarget", furniture, f);
};

Home.prototype.firstFurnitureId = function () {
    return -1;
};

Home.prototype.loadLocationData = function (locationNo) {
    locationNo = locationNo || 0;

    var location = {};

    var config = bundles["location_" + locationNo].jsons.data_json.getJson();

    location.furniture = [];

    if (config) {
        location.config = config;

        location.furniture = location.config.items.map(function (data) {
            var item = new Furniture(this, data);
            return item;
        }, this);
    }

    this.location = location;
    this.location.available = true;
};

Home.prototype.load = function () {
    var data = cleverapps.dataLoader.load(DataLoader.TYPES.HOMEFIX);
    this.updateData(data);
};

Home.prototype.getConfig = function () {
    return this.location.config;
};

Home.prototype.getFurniture = function () {
    return this.location.furniture;
};

Home.prototype.getCurrentFurnitureId = function () {
    return this.currentFurnitureId;
};

Home.prototype.setCurrentFurnitureId = function (newId) {
    this.currentFurnitureId = newId;
    this.save();
};

Home.prototype.getFurnitureById = function (id) {
    var furniture = this.getFurniture();

    for (var i in furniture) {
        if (furniture[i].id === id) {
            return furniture[i];
        }
    }
    return undefined;
};

Home.prototype.getFurnitureByName = function (name) {
    var furniture = this.getFurniture();

    for (var i in furniture) {
        if (furniture[i].name === name) {
            return furniture[i];
        }
    }
    return undefined;
};

Home.prototype.getMaxFurniture = function () {
    return this.getFurnitureById(this.getCurrentFurnitureId());
};

Home.prototype.updateInfo = function (serverData) {
    this.updateData(serverData);
    this.save(true);
};

Home.prototype.updateData = function (data) {
    if (!data) {
        return;
    }
    this.oldItems = data.items;

    this.level = data.level || 0;
    this.stars = data.stars;
    this.currentFurnitureId = data.currentFurnitureId;
};

Home.prototype.getInfo = function () {
    return {
        level: this.level,
        stars: this.stars,
        currentFurnitureId: this.currentFurnitureId
    };
};

Home.prototype.save = function (fromSever) {
    cleverapps.dataLoader.save(DataLoader.TYPES.HOMEFIX, this.getInfo());
    if (!fromSever) {
        cleverapps.synchronizer.addUpdateTask("metha");
    }
    this.onSave();
};

if (cleverapps.config.debugMode) {
    Home.prototype.reset = function () {
        this.stars = 0;
        this.level = 0;
        this.currentFurnitureId = this.firstFurnitureId();

        this.save();
    };

    Home.prototype.getNextId = function () {
        var furniture = this.getFurniture();
        var currentFurnitureId = this.getCurrentFurnitureId();

        for (var i = 0; i < furniture.length; i++) {
            var currentFurniture = furniture[i];

            if (currentFurniture.id === currentFurnitureId) {
                var nextFurniture = furniture[i + 1];
                return nextFurniture ? nextFurniture.id : currentFurnitureId + 1;
            }
        }
    };
}

Home.prototype.giveNewTask = function (f, furniture) {
    this.tasks.add(furniture);
    this.trigger("newTask", furniture, f);
};

Home.prototype.needNewTask = function () {
    var furniture = this.getFurniture();

    for (var i = 0; i < furniture.length; i++) {
        if (furniture[i].readyToOpen()) {
            return true;
        }
    }

    return false;
};

Home.prototype.getFurnitureToUpgrade = function () {
    for (var id in this.tasks.actives) {
        if (this.tasks.actives[id].canBeUpgraded()) {
            return this.tasks.actives[id];
        }
    }

    return undefined;
};

Home.prototype.moveNext = function (furniture, f) {
    var cost = furniture.getInfo().cost;
    this.stars -= cost;

    if (!furniture.nextStage()) {
        this.tasks.onFurnitureCompleted(furniture);
        cleverapps.eventLogger.logEvent(cleverapps.EVENTS.METHA_ITEM + "_" + this.level + "_" + furniture.id);
    }
    this.trigger("change:stage", furniture, f);

    this.save();
    this.onChangeStars();
};

Home.prototype.earnStars = function (stars, silent) {
    this.stars += stars;

    this.save();

    if (!silent) {
        this.onChangeStars();
    }
};

Home.prototype.getLatestNotCompletedFurniture = function () {
    if (this.tasks.actives.length > 0) {
        return this.tasks.actives[0];
    }

    var furniture = this.getFurniture();
    return furniture[furniture.length - 1];
};

Home.prototype.isCompleted = function () {
    if (!this.location.completed) {
        var furniture = this.getFurniture();

        for (var i in furniture) {
            if (!furniture[i].isComplete()) {
                return false;
            }
        }

        this.location.completed = true;
    }
    return this.location.completed;
};

Home.prototype.changeLevel = function (newLevel, f) {
    this.level = newLevel;
    this.tasks.reset();
    this.save();

    cleverapps.scenes.replaceScene(new HomefixScene(), f);
};

Home.prototype.unlockLocation = function (index) {
    if (index < 0 || index > Home.LEVELS.length - 1 || this.location.available) {
        return;
    }

    this.location.available = true;
    this.save();
};

Home.GetFurnitureProgress = function (level, furnitureIndex, currentStage) { /// ?
    var home = cleverapps.home.location;

    var data = {
        furnitureId: home.furniture[furnitureIndex].id,
        stars: home.stars,
        items: {}
    };

    home.furniture.forEach(function (furniture, index) {
        var choice;
        var stage = 0;
        if (index < furnitureIndex) {
            if (furniture.hasChoices()) {
                choice = furniture.choice || 0;
            }
            stage = furniture.amountStages();
        } else if (index === furnitureIndex) {
            stage = currentStage;
        }

        data.items[furniture.id] = {
            choice: choice,
            stage: stage
        };
    });

    return data;
};

Home.LEVELS = ["cafe", "sushibar", "bakery", "burgerbar"];