/**
 * Created by razial on 01.07.2024.
 */

var Dynamite = function (unit) {
    UnitComponent.call(this, unit);
};

Dynamite.prototype = Object.create(UnitComponent.prototype);
Dynamite.prototype.constructor = Dynamite;

Dynamite.prototype.destructor = function () {
    cleverapps.timeouts.clearTimeout(this.timeout);
    this.timeout = undefined;
};

Dynamite.prototype.explode = function (callback) {
    callback = callback || function () {};

    if (this.timeout) {
        callback();
        return;
    }

    if (this.view) {
        this.view.explode();
    }

    this.unit.movable = false;

    this.timeout = cleverapps.timeouts.setTimeout(function () {
        var units = this.listAffectedUnits();

        units.forEach(function (unit) {
            if (unit.prizes) {
                unit.takePrizes(true);

                if (unit.prizes) {
                    return;
                }
            }

            var lock = unit.findComponent(LockedComponent);
            if (lock && lock.isLockActive()) {
                lock.unlock();
            }

            var mineable = unit.findComponent(Mineable);
            if (mineable && !mineable.isCompleted()) {
                mineable.completeMining();
                unit.takePrizes(true);
            }
        });

        this.unit.onDestruction();
        this.unit.remove(true);

        Game.currentGame.harvested.wantsToShowTip = false;

        callback();
    }.bind(this), Dynamite.TIME_TO_EXPLODE);
};

Dynamite.prototype.listAffectedUnits = function () {
    var units = [];

    var shape = Dynamite.calcExplosionShape(this.unit.getData().dynamite.radius);

    shape.forEach(function (part) {
        var x = this.unit.x + part.x;
        var y = this.unit.y + part.y;

        var unit = Map2d.currentMap.getUnit(x, y);
        if (unit) {
            if (unit.head) {
                unit = unit.head;
            }

            if (units.indexOf(unit) !== -1) {
                return;
            }

            if (Dynamite.canExplodeUnit(unit)) {
                units.push(unit);
            }
        }
    }.bind(this));

    return units;
};

Dynamite.prototype.getInfo = function () {
    if (this.timeout) {
        return;
    }

    return {
        simpleInfo: true,
        title: {
            code: this.unit.code,
            stage: this.unit.stage,
            icon: true
        },
        buttons: {
            explode: {
                action: this.explode.bind(this)
            }
        }
    };
};

Dynamite.SHAPE_CACHE = {};

Dynamite.calcExplosionShape = function (radius) {
    if (Dynamite.SHAPE_CACHE[radius]) {
        return Dynamite.SHAPE_CACHE[radius];
    }

    var directions = [{ x: 1, y: 0 }, { x: -1, y: 0 }, { x: 0, y: 1 }, { x: 0, y: -1 }];
    var used = {};

    var queue = [{ x: 0, y: 0, r: 0 }];
    used[queue[0].x + "_" + queue[0].y] = true;

    for (var i = 0; i < queue.length; ++i) {
        var cell = queue[i];

        for (var j = 0; j < directions.length; ++j) {
            var direction = directions[j];
            var next = { x: cell.x + direction.x, y: cell.y + direction.y, r: cell.r + 1 };

            if (!used[next.x + "_" + next.y] && next.r <= radius) {
                used[next.x + "_" + next.y] = true;
                queue.push(next);
            }
        }
    }

    Dynamite.SHAPE_CACHE[radius] = queue;

    return queue;
};

Dynamite.canExplodeUnit = function (unit) {
    return unit && !Map2d.currentMap.workers.findAssigned(unit) && (unit.prizes || unit.findComponent(Mineable));
};

Dynamite.TIME_TO_EXPLODE = 1760;
