/**
 * Created by olga on 28.08.2024
 */

var LocationUnitsSaver = function (slot) {
    this.slot = slot;
    this.units = {};
    this.load();
};

LocationUnitsSaver.prototype.load = function () {
    this.units = cleverapps.dataLoader.load(DataLoader.TYPES.LOCATION_UNITS + this.slot) || {};
};

LocationUnitsSaver.prototype.update = function (x, y, data) {
    this.units[data.code] = data;
    this.save();
};

LocationUnitsSaver.prototype.remove = function (x, y) {
    var fakeUnit = Map2d.currentMap.fogs.getFakeUnit(x, y);
    if (fakeUnit) {
        delete this.units[fakeUnit.code];
        this.save();
    }
};

LocationUnitsSaver.prototype.save = function () {
    cleverapps.dataLoader.save(DataLoader.TYPES.LOCATION_UNITS + this.slot, this.units);

    cleverapps.synchronizer.addUpdateTask("units_location_" + this.slot);
};

LocationUnitsSaver.prototype.loadUnits = function () {
    var units = [];
    if (cleverapps.home.oldItems) {
        return this.migrateUnits(cleverapps.home.oldItems);
    }

    Object.keys(this.units).forEach(function (code) {
        var data = this.units[code];
        data.code = code;

        var fakeUnits = Map2d.currentMap.fogs.listFakeUnits({ code: code, stage: 0 });
        fakeUnits.forEach(function (fakeUnit) {
            units.push({
                data: data,
                x: fakeUnit.x,
                y: fakeUnit.y
            });
        });
    }.bind(this));
    return units;
};

LocationUnitsSaver.prototype.migrateUnits = function (oldItems) {
    var units = [];
    Object.keys(oldItems).forEach(function (id) {
        var furniture = cleverapps.home.getFurnitureById(id);
        var fakeUnit = Map2d.currentMap.fogs.findFakeUnit({
            code: furniture.unitCode,
            type: "furniture",
            stage: 0
        });
        if (fakeUnit) {
            var item = oldItems[id];
            fakeUnit.stage = item.stage - 1 || 0;
            fakeUnit.choice = item.choice;
            units.push(fakeUnit);
        }
    });
    return units;
};

LocationUnitsSaver.compactUnits = function (units) {
    var data = "";
    Object.keys(units).forEach(function (code) {
        var unit = units[code];
        var id = Families.getIdByCode(code);
        data += LocationUnitsSaver.encodeId(id);
        if (unit.choice) {
            data += LocationUnitsSaver.ENCODE_KEYS.choice + Base80.compactNumber(unit.choice, 1);
        }
    });
    return data;
};

LocationUnitsSaver.expandUnits = function (data) {
    var units = {}, i = 0, code, unknown = "";
    while (i < data.length) {
        var part = data[i] + data[i + 1];
        if (LocationUnitsSaver.DELIMITERS.indexOf(data[i]) > -1) {
            if (LocationUnitsSaver.DELIMITERS.indexOf(data[i + 1]) > -1) {
                part += data[i + 2];
            }
            code = LocationUnitsSaver.decodeId(part);

            if (!code) {
                unknown += part;
            }
            i += part.length;
            continue;
        }

        if (!code) {
            unknown += data[i];
            i++;
            continue;
        }

        units[code] = { stage: Base80.expandNumber(data[i]) };
        
        while (LocationUnitsSaver.DELIMITERS.indexOf(data[i]) === -1) {
            units[code][LocationUnitsSaver.DECODE_KEYS[data[i]]] = Base80.expandNumber(data[i + 1]);
            i += 2;
        }

        i++;
    }

    if (unknown.length) {
        cleverapps.throwAsync("expand unknown unit: " + unknown);
    }

    return units;
};

LocationUnitsSaver.encodeId = function (id) {
    var data = "";

    if (id < 800) {
        data += LocationUnitsSaver.DELIMITERS[Math.floor(id / 80)] + Base80.compactNumber(id % 80, 1);
    } else {
        data += LocationUnitsSaver.DELIMITERS[Math.floor(id / 800)]
            + LocationUnitsSaver.DELIMITERS[Math.floor((id % 800) / 80)]
            + Base80.compactNumber(id % 80, 1);
    }

    return data;
};

LocationUnitsSaver.decodeId = function (str) {
    var code;
    if (str.length < 3) {
        code = Families.getCodeById(str[0] * 80 + Base80.expandNumber(str[1]));
    } else {
        code = Families.getCodeById(str[0] * 800 + str[1] * 80 + Base80.expandNumber(str[2]));
    }
    return code;
};

CustomSyncers.registerBySlots("units_location_", function (slot) {
    var data = cleverapps.dataLoader.load(DataLoader.TYPES.LOCATION_UNITS + slot) || {};
    return LocationUnitsSaver.compactUnits(data);
}, function (slot, serverData) {
    cleverapps.dataLoader.save(DataLoader.TYPES.LOCATION_UNITS + slot, LocationUnitsSaver.expandUnits(serverData || ""));
});

LocationUnitsSaver.DELIMITERS = ":,;.~_{}[]";

LocationUnitsSaver.ENCODE_KEYS = { choice: "c" };
LocationUnitsSaver.DECODE_KEYS = {};

for (var key in LocationUnitsSaver.ENCODE_KEYS) {
    var encoded = LocationUnitsSaver.ENCODE_KEYS[key];
    LocationUnitsSaver.DECODE_KEYS[encoded] = key;
}
