/**
 * Created by slava on 17/9/19
 */

var FurnitureWindow = CleverappsWindow.extend({
    onWindowLoaded: function (furniture) {
        this.furniture = furniture;

        var content = this.createContent();

        this._super({
            title: Messages.get(this.furniture.getInfo().title),
            name: "furniturewindow",
            content: content,
            help: function () {
                new GuideWindow({
                    name: "HomefixRulesWindow",
                    bundle: bundles.homefixruleswindow
                });
            },
            button: this.getButton()
        });
    },

    getButton: function () {
        if (this.furniture.canBeUpgraded()) {
            var styles = cleverapps.styles.FurnitureWindow.button;

            return {
                text: "Complete%%" + this.furniture.getInfo().cost,
                icons: {
                    "%%": bundles.menubar.frames.menubar_metha_stars
                },
                width: styles.width,
                onPressed: this.onPressed.bind(this)
            };
        }
        return {
            text: "Play",
            width: cleverapps.styles.FurnitureWindow.button.play.width,
            onPressed: function () {
                this.close();

                cleverapps.meta.distract({
                    focus: "PlayButton",
                    action: function (f) {
                        cleverapps.meta.wantsToPlay(f);
                    }
                });
            }.bind(this)
        };
    },

    onPressed: function () {
        this.close();

        cleverapps.meta.distract({
            focus: "NextStage." + this.furniture.id + "." + this.furniture.getStage(),
            action: function (f) {
                cleverapps.home.moveNext(this.furniture, f);
            }.bind(this)
        });
    },

    createContent: function () {
        var styles = cleverapps.styles.FurnitureWindow;

        var progressBar = this.createProgressBar();
        var charNode = this.createCharNode();

        return new cleverapps.Layout([charNode, progressBar].filter(Boolean), {
            direction: cleverapps.UI.VERTICAL,
            margin: styles.margin,
            padding: styles.padding
        });
    },

    createProgressBar: function () {
        if (this.furniture.canBeUpgraded()) {
            return;
        }

        if (!this.furniture.getInfo()) {
            var tasks = JSON.stringify(cleverapps.home.tasks.actives.map(function (furniture) {
                return furniture.id;
            }));
            throw "FurnitureWindow furniture.getInfo() is undefined furnitureId:" + this.furniture.id + " stage:" + this.furniture.stage
                + " tasks:" + tasks + " data:" + JSON.stringify(cleverapps.home.getInfo());
        }

        var styles = cleverapps.styles.FurnitureWindow.progressBar;
        var amount = this.furniture.getInfo().cost, status = cleverapps.home.stars;

        var progressBar = this.bar = new ScaledProgressBar({
            background: bundles.progress_bar.frames.bg_dailytask,
            progress: bundles.progress_bar.frames.bar_dailytask,
            barText: {
                font: cleverapps.styles.FONTS.DAILY_TASK_PROGRESS_FONT,
                text: status + "/" + amount + "]]",
                icons: {
                    "]]": bundles.menubar.frames.menubar_metha_stars
                }
            }
        });
        progressBar.setLength(styles.width);
        progressBar.setGoal(amount);
        progressBar.setPercentage(status);

        var infoText = cleverapps.UI.generateOnlyText("FurnitureWindow.collectStars", cleverapps.styles.FONTS.LIGHT_WINDOW_TEXT);
        infoText.setHorizontalAlignment(cc.TEXT_ALIGNMENT_CENTER);
        infoText.setDimensions(styles.width, 0);
        infoText.fitTo(undefined, styles.textNode.height);
        return new cleverapps.Layout([
            progressBar,
            infoText
        ], {
            direction: cleverapps.UI.VERTICAL,
            margin: styles.textNode.margin
        });
    },

    createFurnitureIcon: function (furniture) {
        var view = new cleverapps.Spine(bundles["unit_" + furniture.unit.code].jsons["unit_" + furniture.unit.code + "_1"]);
        view.setAnimation(0, "animation", true);
        return view;
    },

    createCharNode: function () {
        var styles = cleverapps.styles.FurnitureWindow.charNode;

        var item = new cc.Node();
        item.setAnchorPoint(0.5, 0.5);
        item.setContentSize2(styles.item);

        var animation = this.createFurnitureIcon(this.furniture);

        cleverapps.UI.fitToBox(animation, {
            width: item.width,
            height: item.height
        });

        animation.setPosition(item.width / 2, item.height / 2);
        item.addChild(animation);

        var summary = this.getSummary();

        return new cleverapps.Layout([item, summary], {
            direction: cleverapps.UI.VERTICAL,
            margin: styles.margin,
            padding: styles.padding
        });
    },

    getSummary: function () {
        var styles = cleverapps.styles.FurnitureWindow.charNode;
        var summary = cleverapps.UI.generateTTFText(Messages.get(this.furniture.getInfo().summary), cleverapps.styles.FONTS.WINDOW_TEXT);
        summary.setHorizontalAlignment(cc.TEXT_ALIGNMENT_CENTER);
        summary.setDimensions(styles.textNode.text.width, 0);
        summary.fitTo(undefined, styles.textNode.text.height);
        return summary;
    },

    getCharAnimation: function () {
        var char = new cc.Node();
        char.setAnchorPoint(0.5, 0.5);
        char.setContentSize2(cleverapps.styles.FurnitureWindow.charNode);

        var charAnimation = new Person(this.furniture.getInfo().person);
        charAnimation.setPosition(char.width / 2, char.height / 2);

        char.addChild(charAnimation);
        return char;
    }
});

cleverapps.styles.FurnitureWindow = {
    margin: 50,

    padding: {
        x: -30,
        y: 50
    },

    button: {
        width: 400,

        play: {
            width: 300
        }
    },

    charNode: {
        width: 620,
        height: 430,
        image: bundles.progress_bar.frames.bg_homefix,
        margin: 70,

        bar: {
            image: bundles.progress_bar.frames.bar_homefix,
            width: 642,
            height: 47,
            minWidth: 20
        },

        text: {
            x: { align: "center" },
            y: { align: "center", dy: -5 }
        },

        padding: {
            x: 0,
            y: 30
        },

        item: {
            width: 400,
            height: 400
        },

        animation: {
            width: 360,
            height: 260
        },

        textNode: {
            text: {
                width: 580,
                height: 140
            }
        }
    },

    progressBar: {
        width: 520,

        textNode: {
            height: 44,
            margin: 10
        }
    }
};
