/**
 * Created by evgeny on 31.07.2024
 */

var Throwaway = function (unit) {
    UnitComponent.call(this, unit);

    this.unit = unit;

    this.onAnimateDestroy = function () {};
};

Throwaway.prototype = Object.create(UnitComponent.prototype);
Throwaway.prototype.constructor = Throwaway;

Throwaway.prototype.animateDestroy = function (f) {
    this.onAnimateDestroy(function () {
        this.unit.remove();
        f();
    }.bind(this));
};
