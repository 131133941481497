/**
 * Created by mac on 12/15/20
 */

var Highlight = function (x, y, options) {
    options = options || {};

    this.error = 0;

    this.x = x;
    this.y = y;

    this.isErrorLight = options.isErrorLight;
    this.unit = options.unit;
    this.drag = options.drag;

    this.onMove = function () {};
    this.onRemove = function () {};
    this.show = function () {};
    this.hide = function () {};
    this.up = function () {};
};

Highlight.prototype.getShape = function () {
    return this.unit ? this.unit.getShape() : FamiliesHelper.ShapeCell;
};

Highlight.prototype.move = function (x, y, silent) {
    this.error = this.unit && !this.unit.canMoveTo(x, y);

    this.onMove(x, y, silent);
};

Highlight.prototype.destructor = function () {
    clearTimeout(this.timeout);
};

Highlight.prototype.remove = function () {
    clearTimeout(this.timeout);
    this.onRemove();
};

Highlight.prototype.schedule = function (callback, timeout) {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(callback, timeout);
};

Highlight.ONE_PULSE = 1500;