/**
 * Created by mac on 12/23/20
 */

var GeneratorView = cc.Node.extend({
    ctor: function (generator, unitView) {
        this._super();

        this.unitView = unitView;
        this.generator = generator;

        var styles = cleverapps.styles.GeneratorView;

        this.guideButton = new cleverapps.UI.HelpButton(function () {
            cleverapps.meta.display({
                focus: "GeneratorGuideWindow",
                action: function (f) {
                    new GuideWindow(GeneratorGuideOptions, generator.unit);
                    cleverapps.meta.onceNoWindowsListener = f;
                }
            });
        });
        this.guideButton.setPositionRound(styles.guideButton);
        this.addChild(this.guideButton);

        if (cleverapps.gameModes.hideGeneratorGuideButton) {
            this.guideButton.setVisible(false);
        }
    },

    beforeUpdateStage: function () {
        var styles = cleverapps.styles.GeneratorView;
        var mapView = Map2d.currentMap.getView();
        var cloudAnimation = new cleverapps.Spine(bundles.merge_animations.jsons.change_stage_cloud_json);
        cloudAnimation.setScale(styles.cloud.scale || 1.0);
        var pos = mapView.alignInIsometricGrid(this.generator.unit.x, this.generator.unit.y);
        cloudAnimation.setPositionRound(pos.x + styles.cloud.x, pos.y + styles.cloud.y);
        mapView.animations.addChild(cloudAnimation);

        cloudAnimation.setAnimation(0, "animation", false);
        cloudAnimation.setCompleteListenerRemove();
    },

    afterUpdateStage: function () {
        this.unitView.setScale(0.8);
        this.updateStageAction = this.unitView.runAction(new cc.ScaleTo(0.3, 1));
    },

    clearAnimations: function () {
        if (this.updateStageAction && !this.updateStageAction.isDone()) {
            this.unitView.stopAction(this.updateStageAction);
        }
    }
});

cleverapps.styles.GeneratorView = {
    cloud: {
        x: 84,
        y: 70,
        scale: 1.35
    },

    guideButton: {
        x: { align: "right", dx: 100 },
        y: { align: "top", dy: 120 }
    }
};