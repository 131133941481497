/**
 * Created by andrey on 18.12.2020.
 */

var EditorMapComponent = cleverapps.UI.ScrollView.extend({
    ctor: function (options) {
        var editor = Editor.currentEditor;
        var map = new EditorMap2dView(editor.map);
        map.scroll = this;

        this._super(map, {
            childrenVisibility: cleverapps.UI.ScrollView.CHILDREN_VISIBILITY_FULLCHECK,
            childrenVisibilityPadding: Math.max(cleverapps.styles.Map2dView.cell.width, cleverapps.styles.Map2dView.cell.height),
            outOfBoundaryScale: 0.25,
            inertialScrollFactor: 0.2,
            zoom: {
                minZoom: 0.3
            }
        });

        this.setContentSize2(options.width, options.height);
        this.setPositionRound({ align: "right" }, { align: "center" });
        this.baseWidth = this.width;

        this.scrollTo(cleverapps.UI.ScrollView.SCROLLS.CENTER);

        cc.eventManager.addListener(cc.EventListener.create({
            event: cc.EventListener.KEYBOARD,
            onKeyPressed: function (keyCode) {
                if (keyCode === cc.KEY.ctrl || keyCode === cc.KEY.b) {
                    this.touchScrollDisabled = true;
                }
            }.bind(this),
            onKeyReleased: function (keyCode) {
                if (keyCode === cc.KEY.ctrl || cc.KEY.b) {
                    this.touchScrollDisabled = false;
                }
            }.bind(this)
        }), this);

        map.isVisiblePoint = this.isVisiblePoint.bind(this);
    }
});