/**
 * Created by mac on 2/5/20
 */

var HomeController = function (map) {
    this.home = cleverapps.home;
    this.map = map;

    this.scrollToHandler = this.home.on("scrollTo", map.scrollToUnit.bind(map));
    this.changeStageHandler = this.home.on("change:stage", this.changeStage.bind(this));
    this.newTaskHandler = this.home.on("newTask", this.newTask.bind(this));
    this.showTargetHandler = this.home.on("showTarget", this.showTarget.bind(this));
    this.showChoiceTargetHandler = this.home.on("showChoiceTarget", this.scrollTo.bind(this));

    this.showHelpHandler = this.home.on("showHelp", function (options) {
        var furniture = options.furniture;
        var f = options.f;
        this.showStageDialogue(furniture, f);
    }.bind(this));
};

HomeController.prototype.clear = function () {
    this.scrollToHandler.clear();
    this.changeStageHandler.clear();
    this.newTaskHandler.clear();
    this.showChoiceTargetHandler.clear();
    this.showHelpHandler.clear();
    this.showTargetHandler.clear();

    this.map.animateNewTask = function () {};

    this.home = undefined;
    this.map = undefined;
};

HomeController.prototype.onEnter = function () {
    var latestFurniture = this.home.getLatestNotCompletedFurniture();
    this.scrollTo(latestFurniture);
};

HomeController.prototype.changeStage = function (furniture, f) {
    furniture.removeHighlight();

    var actions = [
        function (f) {
            this.scrollTo(furniture, f);
        }.bind(this),

        function (f) {
            this.moveNextUnitStage(furniture.unit, f);
        }.bind(this),

        function (f) {
            if (furniture.isComplete() && furniture.hasChoices()) {
                new MakeChoiceWindow(furniture.getSwitchable());
                cleverapps.meta.onceNoWindowsListener = f;
                return;
            }
            f();
        }];

    if (!furniture.isComplete()) {
        actions = actions.concat([
            function (f) {
                this.showStageDialogue(furniture, f);
            }.bind(this),

            function (f) {
                this.map.animateNewTask(furniture);
                setTimeout(f, 2350);
            }.bind(this),

            function (f) {
                new FurnitureWindow(furniture);
                cleverapps.meta.onceNoWindowsListener = f;
            },

            function (f) {
                this.home.tasks.actives.forEach(function () {
                    furniture.addHighlight();
                }, this);
                f();
            }.bind(this)
        ]);
    } else if (this.home.isCompleted()) {
        actions = actions.concat([
            function (f) {
                var dialogue = this.createDialogue("Final");
                dialogue.on("afterClose", f);

                new DialogueView(dialogue);
            }.bind(this),

            function (f) {
                if (cleverapps.home.level < Home.LEVELS.length - 1) {
                    cleverapps.home.unlockLocation(cleverapps.home.level + 1);
                    new TravelMapWindow();
                    cleverapps.meta.onceNoWindowsListener = f;
                } else {
                    new AllDoneWindow();
                    cleverapps.meta.onceNoWindowsListener = f;
                }
            }
        ]);
    }

    cleverapps.meta.compound(f, actions);
};

HomeController.prototype.moveNextUnitStage = function (unit, f) {
    if (!unit) {
        f();
        return;
    }

    var throwaway = unit.findComponent(Throwaway);

    if (throwaway) {
        throwaway.animateDestroy(f);
        return;
    }

    if (!unit.isLast()) {
        var x = unit.x;
        var y = unit.y;
        var code = unit.code;
        var stage = unit.stage + 1;

        var newUnit = new Unit({ code: code, stage: stage });

        Map2d.currentMap.onAddUnit(x, y, newUnit);
        Map2d.currentMap.onUnitAvailable(newUnit);

        if (newUnit.view.sprite.hasAnimation("switch0")) {
            newUnit.setPosition(x, y);

            newUnit.view.sprite.setAnimation(0, "switch0", false);
            newUnit.view.sprite.setCompleteListenerOnce(function () {
                f();
            });

            return;
        }

        unit.view.runAction(AnimationsLibrary.upgrade(unit.view, newUnit.view, {
            beforeRemove: function () {
                newUnit.setPosition(x, y);
            },
            callback: function () {
                f();
            }
        }));
    } else {
        f();
    }
};

HomeController.prototype.newTask = function (newbie, f) {
    cleverapps.meta.compound(f, [
        function (f) {
            this.showStageDialogue(newbie, f);
        }.bind(this),

        function (f) {
            this.scrollTo(newbie, f);
            this.map.animateNewTask(newbie);
        }.bind(this),

        function (f) {
            this.home.tasks.actives.forEach(function () {
                newbie.addHighlight();
            }, this);
            setTimeout(f, 2200);
        }.bind(this)
    ]);
};

HomeController.prototype.showTarget = function (furniture, f) {
    cleverapps.meta.compound(f, [
        function (f) {
            this.scrollTo(furniture, f);
        }.bind(this),

        function (f) {
            new FurnitureWindow(furniture);
            cleverapps.meta.onceNoWindowsListener = f;
        },

        function (f) {
            this.home.tasks.actives.forEach(function () {
                furniture.addHighlight();
            }, this);
            f();
        }.bind(this)
    ]);
};

HomeController.prototype.scrollTo = function (furniture, callback) {
    if (furniture.unit) {
        this.map.scrollToUnit(0.5, furniture.unit, {
            zoom: 1,
            callback: callback || function () {}
        });
    }
};

HomeController.prototype.showUp = function (f) {
    cleverapps.meta.compound(f, [
        function (f) {
            setTimeout(f, 500);
        },

        function (f) {
            var furniture = this.home.getMaxFurniture();
            if (furniture.id === this.home.firstFurnitureId()) {
                if (furniture.canBeUpgraded()) {
                    this.home.moveNext(furniture, f);
                } else if (!this.home.forcedAlready) {
                    this.home.forcedAlready = true;
                    cleverapps.meta.compound(f, [
                        function (f) {
                            this.showStageDialogue(furniture, f);
                        }.bind(this),

                        function (f) {
                            cleverapps.meta.wantsToPlay(f);
                        }
                    ]);
                } else {
                    f();
                }
            } else if (this.home.getFurnitureToUpgrade()) {
                new HomeTasksWindow();
                cleverapps.meta.onceNoWindowsListener = f;
            } else {
                f();
            }
        }.bind(this),

        function (f) {
            if (this.home && !this.home.isCompleted()) {
                this.home.tasks.actives.forEach(function (furniture) {
                    furniture.addHighlight();
                }, this);
            }
            f();
        }.bind(this)
    ]);
};

HomeController.prototype.showStageDialogue = function (furniture, f) {
    var dialogue = this.createDialogue(furniture.getInfo().dialogue);
    if (!dialogue) {
        f();
        return;
    }

    dialogue.on("afterClose", f);

    new DialogueView(dialogue);
};

HomeController.prototype.createDialogue = function (dialogueName) {
    var dialogue = this.home.getConfig().dialogues[dialogueName];

    if (!dialogue) {
        return undefined;
    }

    return new Dialogue(dialogue, {
        autoClose: true,
        showUp: true
    });
};