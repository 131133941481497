/**
 * Created by andrey on 28.07.2023
 */

var Map2dChessGroundView = cc.Sprite.extend({
    ctor: function (frame) {
        this._super(frame);
        this.init(frame);
    },

    reuse: function (frame) {
        this.setSpriteFrame(frame);

        this.init(frame);

        this._super();
    },

    init: function (frame) {
        this.setLocalZOrder(cleverapps.config.debugMode && frame === bundles.dev_resources.frames.chess_debug ? 100 : 0);
    }
});

Map2dChessGroundView.PoolLimit = 500;

Map2dChessGroundView.GetFromPool = function (frame) {
    if (!frame) {
        return;
    }

    if (!cleverapps.config.debugMode && !cleverapps.skins.getSlot("hasChessLayer")) {
        return;
    }

    if (cleverapps.config.debugMode) {
        var visible = frame === bundles.dev_resources.frames.chess_debug
            ? SceneDebugger.showStarredChessLayer : SceneDebugger.chessLayerVisibility;
        if (!visible) {
            return;
        }
    }

    return cc.pool.getFromPool(Map2dChessGroundView, frame) || new Map2dChessGroundView(frame);
};