/**
 * Created by evgeny on 11.06.2024
 */

FogsConfig.initialize = function () {
    FogsConfig.main = {
        fog0: {
            id: 0,
            price: 0,
            exp: 0,
            headless: true
        },
        fog1: {
            id: 1,
            price: 0,
            exp: 0,
            headless: true
        }
    };

    FogsConfig.afterInitialize();
};

FogsConfig.initialize();
