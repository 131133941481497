/**
 * Created by spepa on 10.10.2022
 */

var Landmark = function (unit) {
    UnitComponent.call(this, unit);
};

Landmark.prototype = Object.create(UnitComponent.prototype);
Landmark.prototype.constructor = Landmark;

Landmark.prototype.onRemove = function () {
    if (this.view) {
        this.view.onRemove();
    } 
};

Landmark.prototype.onCreate = function () {
    if (this.view) {
        this.view.onCreate();
    } 
};

Landmark.prototype.handleClick = function () {
    cleverapps.meta.display({
        focus: "landMarkWindow",
        actions: Game.currentGame.landmarks.replaceActions(this.unit)
    });
    return true;
};