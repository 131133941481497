/**
 * Created by mac on 2/3/20
 */

var Furniture = function (home, data) {
    cleverapps.EventEmitter.call(this);

    this.home = home;
    this.stage = 0;

    this.init(data);
};

Furniture.prototype = Object.create(cleverapps.EventEmitter.prototype);
Furniture.prototype.constructor = Furniture;

Furniture.prototype.removeHighlight = function () {
    if (!this.unit) {
        return;
    }

    var hightlight = this.unit.findComponent(HighLight);
    if (hightlight) {
        hightlight.removeHighlight();
    }
};

Furniture.prototype.addHighlight = function () {
    if (!this.unit) {
        return;
    }

    var hightlight = this.unit.findComponent(HighLight);
    if (hightlight) {
        hightlight.addHighlight();
    }
};

Furniture.prototype.getSwitchable = function () {
    if (!this.unit) {
        return;
    }

    return this.unit.findComponent(Switchable);
};

Furniture.prototype.init = function (data) {
    Object.assign(this, data);
    this.stages.forEach(function (stage) {
        stage.title = stage.dialogue;
    });
};

Furniture.prototype.bindUnit = function (unit) {
    this.unit = unit;
    this.stage = unit.stage;
};

Furniture.prototype.readyToOpen = function () {
    if (this.home.tasks.isActive(this) || !this.unit) {
        return false;
    }

    var furniture = this.home.getFurniture();
    for (var i = 0; i < this.order.length; i++) {
        var dependency = this.order[i];
        if (!furniture[dependency].isComplete()) {
            return false;
        }
    }

    return !this.isComplete();
};

Furniture.prototype.hasChoices = function () {
    return this.unit.findComponent(Switchable);
};

Furniture.prototype.isCurrent = function () {
    return this.id <= this.home.getCurrentFurnitureId();
};

Furniture.prototype.amountStages = function () {
    return this.stages.length;
};

Furniture.prototype.isComplete = function () {
    if (!this.isCurrent()) {
        return false;
    }

    if (this.throwaway && !this.unit) {
        return true;
    }

    return this.stage >= this.amountStages();
};

Furniture.prototype.getInfo = function () {
    return this.unit && this.stages[this.unit.stage];
};

Furniture.prototype.canBeUpgraded = function () {
    return this.getInfo() && this.getInfo().cost <= cleverapps.home.stars;
};

Furniture.prototype.getStage = function () {
    return this.unit && this.unit.stage;
};

Furniture.prototype.nextStage = function () {
    this.stage++;

    return !this.isComplete();
};
