/**
 * Created by razial on 15.06.2023
 */

var UnitSavers = function () {
    this.initialize();
};

UnitSavers.prototype.initialize = function () {
    this.instances = {};

    CustomSyncers.SLOTS.forEach(function (slot) {
        this.instances[slot] = this.getInstance(slot);
    }, this);
};

UnitSavers.prototype.getInstance = function (slot) {
    if (!this.instances[slot]) {
        this.instances[slot] = new UnitSaver(slot);
        this.instances[slot].initialize();
    }
    return this.instances[slot];
};

UnitSavers.prototype.reset = function () {
    var slots = {};

    Metha.ListAllLocations().forEach(function (data) {
        slots[cleverapps.GameSaver.getStoreSlot(data.episodeNo, data.levelNo)] = true;
    });

    for (var slot in this.instances) {
        this.instances[slot].reset();

        delete slots[slot];
    }

    for (slot in slots) {
        var unitSaver = new UnitSaver(slot);
        unitSaver.reset();
    }

    this.initialize();
};
