/**
 * Created by spepa on 21.06.2023
 */

var Map2dFence = function (regionName, code, x, y) {
    this.regionName = regionName;
    this.code = code;
    this.x = x;
    this.y = y;

    this.onGetView = function () {};
    this.onRemove = function () {};
};

Map2dFence.prototype.getJson = function () {
    return bundles[this.regionName].jsons[this.regionName + "_" + this.code];
};

Map2dFence.prototype.isDisplayed = function () {
    return this.code !== "inner";
};

Map2dFence.prototype.getLocalZOrder = function () {
    return ["left", "down"].includes(this.code) ? 10 : -10;
};