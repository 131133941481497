/**
 * Created by Andrey Popov on 5/22/23.
 */

var MakeChoiceWindowItem = cc.Node.extend({
    ctor: function (options) {
        this._super();

        var styles = cleverapps.styles.MakeChoiceWindowItem;
        this.options = options;

        this.unitCode = this.options.switchable.unit.code;
        this.switchable = this.options.switchable;

        this.setAnchorPoint(0.5, 0.5);
        this.setContentSize2(styles.width, styles.height);

        var bg = this.bg = cleverapps.UI.createScale9Sprite(styles.bg);
        bg.setContentSize2(this.width, this.height);
        bg.setPositionRound(this.width / 2, this.height / 2);
        this.addChild(bg);

        var slectedBg = this.selectedBg = cleverapps.UI.createScale9Sprite(styles.selected.bg);
        slectedBg.setContentSize2(this.width, this.height);
        slectedBg.setPositionRound(this.width / 2, this.height / 2);
        this.addChild(slectedBg);
        slectedBg.setVisible(false);

        var view = this.createChoiceView(options.choice);
        view.setPosition(this.width / 2, this.height / 2);
        this.addChild(view);

        var selectedCheckBox = this.selectedCheckBox = new cc.Sprite(styles.selected.checkbox.image);
        selectedCheckBox.setPositionRound(styles.selected.checkbox.position);
        selectedCheckBox.setVisible(false);
        this.addChild(selectedCheckBox);

        if (!options.isPreviousChoice) {
            if (options.params.ad && cleverapps.rewardedAdsManager.isRewardedAvailable()) {
                this.ad = true;
                var video = new cc.Sprite(styles.video.image);
                video.setPositionRound(styles.video.position);
                this.addChild(video);
            } else if (options.params.price) {
                this.price = options.params.price;
                var priceBg = cleverapps.UI.createScale9Sprite(styles.price.bg);
                priceBg.setPositionRound(styles.price.position);

                var priceText = cleverapps.UI.generateImageText(options.params.price, cleverapps.styles.FONTS.WINDOW_TEXT);
                var coin = new cc.Sprite(styles.price.coin.image);
                coin.setScale(styles.price.coin.scale);
                var price = new cleverapps.Layout([coin, priceText], {
                    direction: cleverapps.UI.HORIZONTAL,
                    margin: styles.price.margin
                });
                priceBg.setContentSize2(price.width + 2 * styles.price.padding.x, price.height + 2 * styles.price.padding.y);
                price.setPositionRound(priceBg.width / 2, priceBg.height / 2);
                priceBg.addChild(price);

                this.addChild(priceBg);
            }
        }

        cleverapps.UI.applyHover(this);
        cleverapps.UI.onClick(this, this.onPressed.bind(this));
    },

    onPressed: function () {
        this.options.onClick();
    },

    createChoiceView: function (choice) {
        var view = new cleverapps.Spine(bundles["unit_" + this.unitCode].jsons["unit_" + this.unitCode + "_" + (this.switchable.getLastStage() + 1)]);

        cleverapps.UI.fitToBox(view, this);
        view.setAnimation(0, "choice" + choice, true);

        return view;
    }
});

cleverapps.styles.MakeChoiceWindowItem = {
    bg: bundles.homefix_windows.frames.choice_menu_choice_bg_png,
    selected: {
        bg: bundles.homefix_windows.frames.choice_menu_choice_bg_selected_png,
        checkbox: {
            image: bundles.homefix_windows.frames.choice_menu_selected_checkbox_png,
            position: {
                x: { align: "left", dx: -10 },
                y: { align: "top", dy: 10 }
            }
        }
    },
    width: 300,
    height: 300,
    viewPadding: 30,
    video: {
        image: bundles.homefix_windows.frames.choice_menu_video_price_png,
        position: {
            x: { align: "right", dx: 14 },
            y: { align: "bottom", dy: -10 }
        }
    },
    price: {
        margin: 5,
        padding: {
            x: 16,
            y: 11
        },
        bg: bundles.homefix_windows.frames.choice_menu_price_bg_png,
        coin: {
            image: bundles.menubar.frames.gold_png,
            scale: 0.4
        },
        position: {
            x: { align: "right", dx: 10 },
            y: { align: "bottom", dy: -8 }
        }
    }
};
