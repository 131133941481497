/**
 * Created by mac on 3/1/21
 */

var WorkerView = cc.Node.extend({
    ctor: function (worker) {
        this._super();

        this.worker = worker;
        worker.setView(this);

        var styles = cleverapps.styles.WorkerView;

        this.setLocalZOrder(10);
        this.setAnchorPoint(styles.anchorX, styles.anchorY);

        if (cleverapps.skins.getSlot("walkingWorkersSkins")) {
            this.sprite = new cleverapps.Spine(bundles.workers.jsons.walking_king);
            this.sprite.setSkin(worker.getSkin());
            this.sprite.setPosition(this.sprite.width / 2, this.sprite.height / 2);
            this.addChild(this.sprite);

            this.setContentSize(this.sprite.width, this.sprite.height);
        }

        this.restoreState();
    },

    createBuildingAnimation: function (building) {
        var targetView = building.unit.onGetView();

        var json = cleverapps.skins.getSlot("worker_json") || bundles.mineable.jsons.worker_json;
        var buildingAnimation = targetView.workerAnimation = new cleverapps.Spine(json);
        buildingAnimation.setSkin(cleverapps.skins.getSlot("workerSkin") || "regular");

        buildingAnimation.setAnimation(0, "building", true);
       
        buildingAnimation.setLocalZOrder(2);

        targetView.addChild(buildingAnimation);

        buildingAnimation.setPositionRound(cleverapps.styles.WorkerView.buildingAnimation);
    },

    animateAppearBuildingAnimation: function (building) {
        var targetView = building.unit.onGetView();

        var effects = this.buildingEffects(building.unit.code);
        this.sound = cleverapps.audio.playSound(effects.sound);

        if (targetView.workerAnimation.hasAnimation("jump")) {
            targetView.workerAnimation.setAnimationAndIdleAfter("jump", "building");
        }
    },

    createMiningAnimation: function (minable) {
        var targetView = minable.unit.onGetView();

        var json = cleverapps.skins.getSlot("worker_json") || bundles.mineable.jsons.worker_json;
        var miningAnimation = targetView.workerAnimation = new cleverapps.Spine(json);

        miningAnimation.setSkin(cleverapps.skins.getSlot("workerSkin") || "regular");

        var effects = this.miningEffects(minable.unit.getResource() || minable.unit.code);
        miningAnimation.setAnimation(0, effects.animation, true);

        miningAnimation.setLocalZOrder(2);

        if (!targetView) {
            throw "no view for minable unit " + minable.unit.code + " " + minable.unit.stage + " " + minable.unit.x + " " + minable.unit.y
                + " stack - " + new Error().stack
                + " removeStack - " + this.removeStack;
        }

        targetView.addChild(miningAnimation);

        var position = cleverapps.skins.getSlot("workerMiningAnimationStyles") || cleverapps.styles.WorkerView.miningAnimation;
        if (Map2d.currentMap.getFog(minable.unit.x + Iso.DOWN_RIGHT.x, minable.unit.y + Iso.DOWN_RIGHT.y)
            || Map2d.currentMap.getDecoratorUnit(minable.unit.x + Iso.DOWN_RIGHT.x, minable.unit.y + Iso.DOWN_RIGHT.y)) {
            position = cleverapps.clone(position, true);
            position.x.dx = position.x.leftDx;
            miningAnimation.setScaleX(-1);
        }
        miningAnimation.setPositionRound(position);
    },

    animateAppearMiningAnimation: function (minable) {
        var targetView = minable.unit.onGetView();

        var effects = this.miningEffects(minable.unit.getResource());
        this.sound = cleverapps.audio.playSound(effects.sound);

        if (targetView.workerAnimation.hasAnimation("jump")) {
            targetView.workerAnimation.setAnimationAndIdleAfter("jump", effects.animation);
        }
    },

    removeAnimation: function (target) {
        var targetView = target.unit.onGetView();

        if (targetView && targetView.workerAnimation) {
            if (targetView.workerAnimation.hasAnimation("jump_back")) {
                targetView.workerAnimation.setAnimation(0, "jump_back", false);
                targetView.workerAnimation.setCompleteListenerRemove();
            } else {
                targetView.workerAnimation.removeFromParent();
            }
            delete targetView.workerAnimation;
        }
        cleverapps.audio.stopSound(this.sound);
    },

    complete: function (target) {
        if (!target.unit.onGetView()) {
            return;
        }

        this.restoreState();

        this.hideTimer(target);
        this.removeAnimation(target);
    },

    restoreState: function () {
        if (this.sprite) {
            if (this.worker.path) {
                this.setVisible(true);
            } else if (this.worker.position) {
                this.setVisible(true);
                this.restorePosition(this.worker.position);

                if (this.worker.unit) {
                    this.animateWorking(true);
                } else {
                    this.animateIdle();
                }
            } else {
                this.setVisible(false);
            }
        }

        var unitView = this.worker.unit && this.worker.unit.onGetView();
        if (!unitView) {
            return;
        }

        var workable = this.worker.unit.findComponent(Mineable) || this.worker.unit.findComponent(Buildable);

        if (!this.sprite && !unitView.workerAnimation) {
            if (workable instanceof Buildable) {
                this.createBuildingAnimation(workable);
            } else {
                this.createMiningAnimation(workable);
            }
        }

        if (this.worker.getTimeLeft() <= 0) {
            this.hideTimer(workable, true);
        } else {
            this.createTimer(workable);
        }
    },

    restorePosition: function (cell) {
        var mapView = Map2d.currentMap.getView();

        this.setPosition(mapView.alignInIsometricGrid(cell.x, cell.y));
        mapView.addTile(Map2d.LAYER_UNITS, cell.x, cell.y, this);
    },

    startWorking: function (target) {
        target.onUpdateState();

        this.animateWorking();

        var targetView = target.unit.onGetView();
        if (targetView) {
            if (!this.sprite) {
                if (target instanceof Buildable) {
                    this.animateAppearBuildingAnimation(target);
                } else {
                    this.animateAppearMiningAnimation(target);
                }
            }

            this.animateAppearTimer(target);
        }
    },

    createTimer: function (workable) {
        this.hideTimer(workable, true);
        var targetView = workable.unit.onGetView();
        var timer = new WorkerComponentTimer(this.worker);
        timer.setPositionRound(cleverapps.styles.WorkerView.timer);
        targetView.createAdditionalView("timer", timer);
    },

    animateAppearTimer: function (workable) {
        var targetView = workable.unit.onGetView();
        var view = targetView.getAdditionalView("timer");
        if (view) {
            view.animateAppear();
        }
    },

    hideTimer: function (workable, silent) {
        var targetView = workable.unit.onGetView();
        targetView.removeAdditionalView("timer", this.worker.speedupInProgress, silent);
    },

    showSpeedUp: function (unit, time, callback) {
        var runAnimation = function () {
            var unitView = unit.onGetView();

            var timer = unitView && unitView.getAdditionalView("timer");

            if (timer) {
                timer.view.showBoost({
                    limit: time,
                    duration: Workers.BOOST_ANIMATE_DURATION,
                    callback: callback
                });
            } else {
                callback();
            }
        };

        cleverapps.meta.display({
            focus: "AdsTimeSpeedUp",
            actions: [
                function (f) {
                    runAnimation = cleverapps.accumulate(800, runAnimation);

                    setTimeout(f, 200);
                },

                function (f) {
                    Map2d.currentMap.getView().scroll.runAction(new cc.Spawn(
                        new cc.CellScrollAction(unit, {
                            duration: cc.ScrollAction.SCROLL_TO_DURATION * 1.5,
                            allowScrollWithFocus: true
                        }).easing(cc.easeInOut(2)),
                        new cc.ZoomAction(0.5, {
                            zoomKoef: 1.25,
                            maxZoom: 1.35,
                            direction: cleverapps.UI.ZoomHandler.ZOOM_DIRECTION_IN
                        })
                    ));

                    setTimeout(f, 600 + Workers.BOOST_ANIMATE_DURATION);
                }
            ]
        });

        runAnimation();
    },

    miningEffects: function (code) {
        switch (code) {
            case "bamboo":
            case "drwood":
            case "hlgrave":
            case "hlcaldron":
            case "hlghost":
            case "hlbat":
            case "wood": return {
                animation: "chop",
                sound: bundles.mineable.urls.mining_wood_start_effect
            };
            case "advrock":
            case "drstone":
            case "stone": return {
                animation: "chop2",
                sound: bundles.mineable.urls.mining_stone_start_effect
            };
            case "drcastle":
            case "drwagon":
            case "drsled":
            case "drpurplecrystal":
            case "wagon": return {
                animation: "chop2",
                sound: bundles.mineable.urls.mining_wagon_start_effect
            };
            default: return {
                animation: "chop",
                sound: cleverapps.skins.getSlot("workerSound") || bundles.mineable.urls.mining_start_effect
            };
        }
    },

    buildingEffects: function (code) {
        return this.miningEffects(code);
    },

    dashToCell: function (nextCell) {
        this.stopAllActions();
        this.restorePosition(nextCell);
    },

    moveToCell: function (currentCell, nextCell, callback) {
        var mapView = Map2d.currentMap.getView();

        var lastPosition = mapView.alignInIsometricGrid(currentCell.x, currentCell.y);
        var nextPosition = mapView.alignInIsometricGrid(nextCell.x, nextCell.y);

        var vector = cc.pSub(nextPosition, lastPosition);
        var duration = cc.pLength(vector) / cleverapps.styles.WorkerView.speed;

        var direction = this.chooseDirection(lastPosition, nextPosition);
        var nextAnimation = "walk_" + direction;

        var actions = [];

        actions.push(new cc.CallFunc(this.setSpriteAnimation.bind(this, nextAnimation, this.playStepsAction.bind(this))));
        actions.push(new cc.MoveBy(duration / 2, cc.pMult(vector, 0.5)));
        actions.push(new cc.CallFunc(mapView.addTile.bind(mapView, Map2d.LAYER_UNITS, nextCell.x, nextCell.y, this)));
        actions.push(new cc.MoveBy(duration / 2, cc.pMult(vector, 0.5)));
        actions.push(new cc.CallFunc(callback));

        this.stopAllActions();
        this.runAction(new cc.Sequence(actions));
    },

    stopMoving: function () {
        this.stopAllActions();
        this.playLastStepSound();
        this.animateIdle();
    },

    animateWorking: function (silent) {
        if (this.sprite) {
            var mapView = Map2d.currentMap.getView();

            var workerPosition = mapView.alignInIsometricGrid(this.worker.position.x, this.worker.position.y);
            var workablePosition = mapView.alignInIsometricGrid(this.worker.unit.x, this.worker.unit.y);

            var multiCell = this.worker.unit.findComponent(MultiCell);
            if (multiCell) {
                var centerDelta = multiCell.getCenter();
                workablePosition.x += centerDelta.x;
                workablePosition.y += centerDelta.y;
            }

            var direction = this.chooseDirection(workerPosition, workablePosition);
            var animation = "chop_" + direction;
            var sound = bundles.workers.urls.mining_effect;

            var data = this.worker.unit.getData();
            if (data.workingAnimation) {
                animation = "chop_" + data.workingAnimation + "_" + direction;
                sound = bundles.workers.urls["mining_" + data.workingAnimation + "_effect"] || sound;
            }

            this.setSpriteAnimation(animation, function (duration) {
                return new cc.Sequence(
                    new cc.DelayTime(0.35),
                    new cc.CallFunc(function () {
                        var unitView = this.worker.unit && this.worker.unit.onGetView();
                        if (unitView) {
                            unitView.animateFlinch();
                        }
                    }.bind(this)),
                    new cc.DelayTime(duration - 0.35)
                );
            }.bind(this));

            if (!silent) {
                this.sound = cleverapps.audio.playSound(sound);
            }
        }
    },

    animateIdle: function () {
        if (this.sprite) {
            this.setSpriteAnimation("idle_w");
        }
    },

    chooseDirection: function (from, to) {
        var vector = cc.pSub(to, from);

        if (vector.x < 0) {
            if (vector.y < 0) {
                return "w";
            } 
            return "n";
        }

        if (vector.y < 0) {
            return "s";
        } 
        return "e";
    },

    getViewPosition: function (rounded) {
        var mapView = Map2d.currentMap.getView();
        return mapView.getCellByCoordinates(this.getPosition(), !rounded);
    },

    setSpriteAnimation: function (animation, repeatActions) {
        if (this.lastAnimation === animation) {
            return 0;
        }
        this.lastAnimation = animation;

        this.sprite.stopAllActions();
        this.sprite.setCompleteListener();
        this.sprite.setAnimation(0, animation, true);

        if (repeatActions) {
            this.sprite.runAction(new cc.RepeatForever(repeatActions(this.sprite.getTimeLeft())));
        }
    },

    playStepsAction: function (duration) {
        return new cc.Sequence(
            new cc.CallFunc(this.playStepSound.bind(this)),
            new cc.DelayTime(duration / 2),
            new cc.CallFunc(this.playStepSound.bind(this)),
            new cc.DelayTime(duration / 2)
        );
    },

    playStepSound: function () {
        var cell = this.getViewPosition(true);
        var sounds = Map2d.currentMap.isWaterUnit(cell.x, cell.y)
            ? ["step_wood_1", "step_wood_2", "step_wood_3", "step_wood_4", "step_wood_5", "step_wood_6"]
            : ["step_grass_1", "step_grass_2", "step_grass_3", "step_grass_4", "step_grass_5", "step_grass_6"];

        cleverapps.audio.playSound(bundles.workers.urls[cleverapps.Random.choose(sounds)]);
    },

    playLastStepSound: function () {
        var cell = this.getViewPosition(true);
        cleverapps.audio.playSound(bundles.workers.urls[Map2d.currentMap.isWaterUnit(cell.x, cell.y) ? "step_wood_stop" : "step_grass_stop"]);
    }
});

cleverapps.styles.WorkerView = {
    anchorX: 0.54,
    anchorY: 0.11,

    speed: 450,

    timer: {
        x: { align: "center", dx: 10 },
        y: { align: "center", dy: -29 }
    },

    miningAnimation: {
        x: { align: "center", dx: 90, leftDx: -45 },
        y: { align: "bottom", dy: 0 }
    },

    buildingAnimation: {
        x: { align: "center", dx: 70 },
        y: { align: "bottom", dy: 0 }
    }
};