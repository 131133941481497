/**
 * Created by mac on 12/23/20
 */

var BuildableView = cc.Node.extend({
    ctor: function (build, unitView) {
        this._super();

        this.build = build;
        this.unitView = unitView;
        this.unitAnimation = unitView.getAnimation();

        this.createCheckmark();
        this.restoreState();
    },

    restoreState: function (animated) {
        if (this.build.unit.isBuilt()) {
            this.unitView.setImageOpacity(255);
        } else if (this.unitAnimation) {
            this.unitAnimation.setTimeScale(0);

            var normalColor = new cc.Color(160, 160, 160, 255);
            this.unitAnimation.setColor(normalColor);
            this.unitAnimation.normalColor = normalColor;

            this.unitView.setImageOpacity(200);
        } else {
            this.unitView.setImageOpacity(180);
        }

        var worker = Map2d.currentMap.workers.findAssigned(this.build.unit);
        var instantWorker = Map2d.currentMap.workers.findInstantAssigned(this.build);

        if (this.build.isCompleted() || InfoView.IsDisplayedFor(this.build.unit) || worker || instantWorker) {
            this.hideCheckmark(animated);
        } else {
            this.showCheckmark(animated);
        }

        if (instantWorker) {
            InstantWorkerView.createInstantAnimation(this.unitView, instantWorker, this.build.time);
        } else {
            InstantWorkerView.removeInstantAnimation(this.unitView);
        }

        if (worker) {
            worker.onUpdateState();
        }
    },

    startMagic: function (worker) {
        InstantWorkerView.startMagic(this.unitView, worker, this.restoreState.bind(this, true));
    },

    finishMagic: function (worker) {
        InstantWorkerView.finishMagic(this.unitView, worker, this.restoreState.bind(this, true));
    },

    createCheckmark: function () {
        var styles = cleverapps.styles.BuildableView.animation;

        if (bundles.buildable.jsons.buildable_icon_json) {
            this.checkmark = new cleverapps.Spine(bundles.buildable.jsons.buildable_icon_json);
            this.checkmark.setAnimationAndIdleAfter("animation", "idle");

            var multiCell = this.unitView.unit.findComponent(MultiCell);
            if (multiCell) {
                styles = styles.multiCell;
            }

            this.checkmark.setPositionRound(styles.offset, -this.unitView.height / 2 - (styles.dy - this.unitView.height * this.unitView.anchorY) + styles.offset);

            cleverapps.UI.applyHover(this.checkmark);
            cleverapps.UI.onClick(this.checkmark, function () {
                this.unitView.handleClick();
            }.bind(this));
        } else {
            this.checkmark = new cc.Sprite(bundles.buildable.frames.buildable_exclamation);
        }
        this.addChild(this.checkmark);
        this.checkmark.hidden = false;
    },

    hideCheckmark: function (animated) {
        if (this.checkmark.hidden) {
            return;
        }
        this.checkmark.hidden = true;
        this.checkmark.stopAllActions();

        if (!animated) {
            this.checkmark.setVisible(false);
            return;
        }

        this.checkmark.runAction(new cc.Sequence(
            new cc.ScaleTo(0.1, 0.3).easing(cc.easeIn(2)),
            new cc.Hide()
        ));
    },

    showCheckmark: function (animated) {
        if (!this.checkmark.hidden) {
            return;
        }
        this.checkmark.hidden = false;
        this.checkmark.stopAllActions();

        if (!animated) {
            this.checkmark.setVisible(true);
            return;
        }

        this.checkmark.runAction(new cc.Sequence(
            new cc.ScaleTo(0, 0.3),
            new cc.Show(),
            new cc.ScaleTo(0.1, 1).easing(cc.easeOut(2))
        ));
    },

    animateCompletion: function () {
        this.restoreState();

        var draggable = this.build.unit.findComponent(Draggable);
        if (draggable) {
            draggable.onUnhighlight(this.build.unit);
        }

        var targetView = this.unitView;
        targetView.setImageOpacity(255);
        var mapView = Map2d.currentMap.getView();

        if (this.unitAnimation) {
            this.unitAnimation.normalColor = undefined;
            this.unitAnimation.runAction(new cc.Sequence(
                new cc.TintTo(1.0, 255, 255, 255),
                new cc.CallFunc(function () {
                    this.unitAnimation.setTimeScale(1);
                }.bind(this))
            ));
        }

        if (!this.build.unit.findComponent(MultiCell)) {
            var below = new cleverapps.Spine(bundles.buildable_animation.jsons.buildable_below_json);
            below.setLocalZOrder(-1);
            mapView.addTile(Map2d.LAYER_UNITS, this.build.unit.x, this.build.unit.y, below);

            var above = new cleverapps.Spine(bundles.buildable_animation.jsons.buildable_above_json);
            above.setLocalZOrder(1);
            mapView.alignViewInIsometricGrid(this.build.unit.x, this.build.unit.y, above);
            mapView.animations.addChild(above);

            below.setAnimation(0, "animation", false);
            below.setCompleteListenerRemove();

            above.setAnimation(0, "animation", false);
            above.setCompleteListenerRemove();
        }

        this.completeAction = targetView.runAction(new cc.Sequence(
            new cc.DelayTime(0.13),
            new cc.ScaleTo(0.5, 1.3),
            new cc.ScaleTo(0.4, 1)
        ));

        cleverapps.audio.playSound(bundles.buildable.urls.build_finish_effect);
    },

    clearAnimations: function () {
        if (this.completeAction && !this.completeAction.isDone()) {
            this.unitView.stopAction(this.completeAction);
        }
    }
});

cleverapps.styles.BuildableView = {
    animation: {
        dy: 55,
        offset: 45,

        multiCell: {
            dy: 120,
            offset: 85
        }
    }
};