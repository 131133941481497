/**
 * Created by spepa on 24.10.2022
 */

var LandmarkDonor = function (unit) {
    UnitComponent.call(this, unit);
};

LandmarkDonor.prototype = Object.create(UnitComponent.prototype);
LandmarkDonor.prototype.constructor = LandmarkDonor;

LandmarkDonor.prototype.handleClick = function () {
    var landmark = Game.currentGame.landmarks.findDonorLandmark(this.unit);
    if (landmark && cleverapps.unitsLibrary.isOpened(landmark)) {
        var data = this.unit.getData();
        if (data.landmarkDonor.finishHint) {
            cleverapps.centerHint.createTextHint(data.landmarkDonor.finishHint);
            this.unit.squeeze();
            return true;
        }
    }
};

LandmarkDonor.prototype.getGreeting = function () {
    var landmark = Game.currentGame.landmarks.findDonorLandmark(this.unit);

    if (landmark && landmark.feature && this.unit.stage === 0) {
        return function (f) {
            return cleverapps.meta.compound(f, [
                function (f) {
                    new GuideWindow(Game.currentGame.landmarks.getGuideWindowOptions(landmark));
                    cleverapps.meta.onceNoWindowsListener = f;
                },
                function (f) {
                    new LandMarkDonorWindow();
                    cleverapps.meta.onceNoWindowsListener = f;
                }
            ]);
        };
    }

    return function (f) {
        Game.currentGame.landmarks.increasePendingProgress(this.unit);

        if (landmark && Game.currentGame.landmarks.canGiveLandmark(landmark)) {
            new LandMarkDonorWindow(landmark);
            cleverapps.meta.onceNoWindowsListener = f;
            return;
        }

        f();
    }.bind(this);
};
