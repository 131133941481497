/**
 * Created by vladislav on 31/10/2022
 */

var CustomerView = function (customer, unitView) {
    this.unitView = unitView;
    this.customer = customer;

    this.restoreState();
};

CustomerView.prototype.restoreState = function (animated) {
    if (cleverapps.config.demoMode || !this.customer.getCurrentRecipe() || (cleverapps.windows.currentWindow() instanceof CustomersWindow)) {
        this.removeInfoView(animated);
    } else {
        this.createInfoView(animated);
    }

    if (this.customer.getCookingRecipe() && this.customer.getCookingTimeLeft()) {
        this.createTimer(animated);
    } else {
        this.removeTimer(animated);
    }
};

CustomerView.prototype.createInfoView = function (animated) {
    var ingredientsView = this.unitView.getAdditionalView("ingredients");
    if (ingredientsView) {
        return;
    }

    var recipe = this.customer.getCurrentRecipe();

    var infoView = new InfoView({
        ingredients: recipe.getIngredients(),
        onClicked: this.unitView.createListener(this.unitView.handleClick.bind(this.unitView))
    });
    infoView.setPositionRound(this.unitView.width / 2, this.unitView.height + cleverapps.styles.InfoView.dy);
    infoView.setAnchorPoint(0.5, 0);
    this.unitView.createAdditionalView("ingredients", infoView);

    if (animated) {
        ingredientsView = this.unitView.getAdditionalView("ingredients");
        ingredientsView.animateAppear();
    }
};

CustomerView.prototype.removeInfoView = function (animated) {
    this.unitView.removeAdditionalView("ingredients", !animated);
};

CustomerView.prototype.consumeUnit = function (unitView) {
    if (!unitView) {
        return;
    }

    var parent = cleverapps.scenes.getMovingNode(this.unitView);

    unitView.stopAllActions();
    unitView.replaceParentSamePlace(parent, {
        keepScale: true
    });
    unitView.setLocalZOrder(1);

    var distance = cc.pDistance(unitView, parent.convertToNodeSpace(this.unitView.convertToWorldSpace(cc.p(this.unitView.width / 2, this.unitView.height / 2))));
    var easing = Math.max(1, distance / cleverapps.styles.CustomerView.distance);

    unitView.runAction(new cc.Sequence(
        AnimationsLibrary.animateCollect(unitView, this.unitView, {
            path: true,
            duration: 0.7,
            sound: bundles.merge.urls.exchange_effect,
            easing: cc.easeOut(easing)
        }),
        new cc.RemoveSelf()
    ));
};

CustomerView.prototype.createTimer = function (animated) {
    var timerView = this.unitView.getAdditionalView("timer");
    if (timerView) {
        return;
    }

    var styles = cleverapps.styles.CustomerView;

    var cookingRecipe = this.customer.getCookingRecipe();
    var cookingTimeLeft = this.customer.getCookingTimeLeft();

    var timer = new cleverapps.CountDownView(new cleverapps.CountDown(cookingTimeLeft), {
        font: cleverapps.styles.FONTS.COMPONENT_TIMER_TEXT,
        icon: bundles.timer_merge.frames.timer_png,
        bar: {
            type: ScaledProgressBar.Types.blue,
            width: styles.timer.width,
            fullValue: cookingRecipe.getCookingDuration(),
            reversed: false
        }
    });
    timer.setPositionRound(styles.timer);
    this.unitView.createAdditionalView("timer", timer);

    if (animated) {
        timerView = this.unitView.getAdditionalView("timer");
        timerView.animateAppear();
    }
};

CustomerView.prototype.removeTimer = function (animated) {
    this.unitView.removeAdditionalView("timer", !animated);
};

cleverapps.styles.CustomerView = {
    distance: 600,

    timer: {
        x: { align: "center" },
        y: { align: "bottom", dy: 30 },
        width: 140
    }
};